import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        access_token: localStorage.getItem('access_token') || '',
        userData: JSON.parse(localStorage.getItem('userData')) || '',
        search_data: '',
    },
    mutations: {
        USER_DATA(state, data) {
            state.userData = data
            localStorage.setItem('userData', JSON.stringify(data))
        },
        clearUserData(state) {
            state.access_token = '';
            state.userData = ''
            localStorage.removeItem('userData')
            localStorage.removeItem('access_token')
            // sessionStorage.clear();
        },
        SEARCH_DATA(state, data) {
            state.search_data = data
        },

    },
    actions: {
        getHomeData({state}) {
          return axios.get(`${state.baseURL}home-info-get`)
              .then(response => {
                  return response
              })
              .catch(error => {
                  return error
              })
        },
        registerUser({state, commit}, data) {
            if(data.role === 2) {
                return axios.post(`${state.baseURL}register`, {
                    name: data.d_fullName,
                    specialization: data.d_specialization,
                    qualification: data.d_qualification,
                    email: data.d_email,
                    password: data.d_password,
                    c_password: data.d_c_password,
                    role: data.role,

                })
                    .then(response => {
                        if (response) {
                            commit('USER_DATA', response.data.success.user)
                            let access_token = response.data.success.token
                            localStorage.setItem('access_token', access_token)
                            axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                            state.access_token = access_token
                            return response
                        }
                    })
                    .catch(error => {
                        return error
                    })
            } else {
                return axios.post(`${state.baseURL}register`, {
                    name: data.p_fullName,
                    email: data.p_email,
                    password: data.p_password,
                    c_password: data.p_c_password,
                    role: data.role,
                })
                    .then(response => {
                        if (response) {
                            commit('USER_DATA', response.data.success.user)
                            let access_token = response.data.success.token
                            localStorage.setItem('access_token', access_token)
                            axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                            state.access_token = access_token
                            return response
                        }
                    })
                    .catch(error => {
                        return error
                    })
            }

        },
        loginUser({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password
            }).then(response => {
                    if(response) {
                        commit('USER_DATA', response.data.success.user)
                        let access_token = response.data.success.token
                        localStorage.setItem('access_token', access_token)
                        axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                        state.access_token = access_token
                    }
                    return response
                })
                .catch(error => {return error})
        },
        getAuthUserData({state, commit}) {
            return axios.get(`${state.baseURL}get-doc-prof`)
                .then(response => {
                    commit('USER_DATA', response.data.success[0].user_relation)
                    return response
                })
                .catch(error => {return error})
        },
        editAuthUserData({state}, data) {
            return axios.post(`${state.baseURL}update-doc-prof`, {
                relevant_experience: data.relevant_experience,
                technical_qualification: data.technical_qualification,
                brief_profile: data.brief_profile,
                liability_insurance: data.Liability_insurance,
                about: data.about
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        avatarChange({state}, data) {
            let formData = new FormData()
            formData.append('avatar', data)
            return axios.post(`${state.baseURL}doctor-update`, formData)
                .then(response => { return response})
                .catch(error => { return error})
        },
        sendAvailability({state}, data) {
            return axios.post(`${state.baseURL}doctor-availability`, {
                availability: data
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        createCard({state}, data) {
            return axios.post(`${state.baseURL}doctor-add-card`, {
                cardName: data.cardName,
                cardNumber: data.cardNumber,
                cardDate: data.cardDate,
                cardCvv: data.cardCvv
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getCreditCards({state}) {
            return axios.get(`${state.baseURL}doctor-get-cards`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        cardDelete({state}, data) {
            return axios.delete(`${state.baseURL}doctor-delete-card/${data}`)
                .then(response => { return response})
                .catch(error => { return error})
        },
        updateCreditCard({state}, data) {
           return  axios.post(`${state.baseURL}doctor-update-card`, {
               cardName: data.cardName,
               cardNumber: data.cardNumber,
               cardDate: data.cardDate,
               cardCvv: data.cardCvv,
               cardId: data.cardId
           })
               .then(response => { return response})
               .catch(error => { return error})
        },
        patientPayment({state}, data) {
            return axios.post(`${state.baseURL}add-payment-information`, {
                patientId: data.patient_id,
                date: data.patient_date,
                price: data.patient_price,
                description: data.patient_description
            })
                .then(response => { return response})
                .catch(error => { return error})
        },
        createPatient({state}, data) {
            return axios.post(`${state.baseURL}add-patient`, {
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                email: data.email,
                phoneNumber1: data.phoneNumber1,
                codePhoneNumber1: data.codePhoneNumber1,
                phoneNumber2: data.phoneNumber2,
                codePhoneNumber2: data.codePhoneNumber2,
                country: data.country,
                city: data.city,
                fullName: data.fullName,
                phoneNumber: data.phoneNumber,
                codePhoneNumber: data.codePhoneNumber,
                diagnose: data.diagnose,
                height: data.height,
                weight: data.weight,
                bloodPressureMin: data.bloodPressureMin,
                bloodPressureMax: data.bloodPressureMax,
                RBS: data.RBS,
                temperature: data.temperature,
                heartRate: data.heartRate,
                waistCircumference: data.waistCircumference,
                hipCircumference: data.hipCircumference,
                oxygenLevel: data.oxygenLevel,
                physicalActivity: data.physicalActivity,
                sleep: data.sleep,
                alcoholConsumption: data.alcoholConsumption,
            })
                .then(response => { return response})
                .catch(error => {return error})

        },
        patientUpdate({state}, data) {
            return axios.post(`${state.baseURL}update-patient`, {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                email: data.email,
                phoneNumber1: data.phoneNumber1,
                codePhoneNumber1: data.codePhoneNumber1,
                phoneNumber2: data.phoneNumber2,
                codePhoneNumber2: data.codePhoneNumber2,
                country: data.country,
                city: data.city,
                fullName: data.fullName,
                phoneNumber: data.phoneNumber,
                codePhoneNumber: data.codePhoneNumber,
                diagnose: data.diagnose,
                height: data.height,
                weight: data.weight,
                bloodPressureMin: data.bloodPressureMin,
                bloodPressureMax: data.bloodPressureMax,
                RBS: data.RBS,
                temperature: data.temperature,
                heartRate: data.heartRate,
                waistCircumference: data.waistCircumference,
                hipCircumference: data.hipCircumference,
                oxygenLevel: data.oxygenLevel,
                physicalActivity: data.physicalActivity,
                sleep: data.sleep,
                alcoholConsumption: data.alcoholConsumption,
            })
                .then(response => { return response})
                .catch(error => {return error})

        },
        getAllPatients({state}) {
            return axios.get(`${state.baseURL}get-patients`)
                .then(response => { return response})
                .catch(error => {return error})
        },
        removePatient({state}, data) {
            return axios.delete(`${state.baseURL}doctor-delete-patient/${data}`)
                .then(response => { return response})
                .catch(error => {return error})
        },
        createNewConsultation({state}, data) {
            return axios.post(`${state.baseURL}add-consultation`, {
                patientId: data.patient_id,
                consultType: data.consultType,
                startDate: data.startDate,
                hourFrom: data.startHour,
                hourTo: data.endHour,
                onlineConsult: data.onlineConsult,
                address: data.address,
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        getConsultations({state}) {
            return axios.get(`${state.baseURL}get-consultation`)
                .then(response => {

                    return response})
                .catch(error => {return error})

        },
        removeConsultation({state}, data) {
            return axios.delete(`${state.baseURL}delete-consultation/${data}`)
                .then(response => { return response})
                .catch(error => {return error})
        },
        updateConsultation({state}, data) {
            return axios.post(`${state.baseURL}update-consultation`, {
                id: data.id,
                patientId: data.patient_id,
                consultType: data.consultType,
                startDate: data.startDate,
                hourFrom: data.startHour,
                hourTo: data.endHour,
                onlineConsult: data.onlineConsult,
                address: data.address,
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        getPatientDataById({state}, data) {
            return axios.get(`${state.baseURL}get-patient/${data}`)
                .then(response => { return response})
                .catch(error => {return error})
        },
        patientProfileGet({state}, data) {
            return axios.get(`${state.baseURL}patient-profile-get/${data}`)
                .then(response => { return response})
                .catch(error => {return error})
        },
        consultationEdit({state}, data) {
            return axios.post(`${state.baseURL}consultation-note-update`, {
                id: data.id,
                patientId: data.patientId,
                date: data.startDate,
                time: data.startHour,
                diagnosis: data.diagnose,
                diagnosisCode: data.diagnoseCode,
                description: data.description,
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        invoiceSummaryUpdate({state}, data) {
            return axios.post(`${state.baseURL}invoice-summary`, {
                id: data.id,
                patientId: data.patientId,
                names: data.name,
                membershipNo: data.membership,
                medicalAid: data.medicalAid,
                scheme: data.scheme,
                dob: data.dob,
                services: data.services,
                investigationList: data.investigationList,
                medicalList: data.medicalList,
                procedureList: data.procedureList,
                diagnosticList: data.diagnosticList,
                doctorSignature: data.invoiceDoctorSign,
                clientSignature: data.invoiceClientSign,
                doctorSignatureDate: data.doctorSignDate,
                clientSignatureDate: data.clientSignDate,
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        prescriptionUpdate({state}, data) {
            return axios.post(`${state.baseURL}prescription-form`, {
                id: data.id,
                description: data.text,
                doctorSignature: data.signature,
                date: data.date,
                officialStamp: data.stamp,
                patientId: data.patientId
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        referralUpdate({state}, data) {
            return axios.post(`${state.baseURL}refferal-form`, {
                patientId: data.patientId,
                service: data.service,
                refferedTo: data.referred_to,
                reasenForRefferal: data.reason_referral,
                diagnosis: data.diagnosis,
                treatmentGiven: data.treatment_given,
                doctorSignature: data.signature,
                date: data.date,
                officialStamp: data.stamp,
                id: data.id
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        fitnessUpdate({state}, data) {
            return axios.post(`${state.baseURL}fitness-form`, {
                patientId: data.patientId,
                occupation: data.occupation,
                employer: data.employer,
                fitnessOption: data.fitnessOption,
                workReturnDate: data.workReturnDate,
                reviewDate: data.reviewDate,
                numberOfDaysOff: data.numberOfDaysOff,
                description: data.description,
                doctorSignature: data.doctorSignature,
                officialStamp: data.officialStamp,
                id: data.id,
            })
                .then(response => { return response})
                .catch(error => {return error})
        },
        changePassword({state}, data) {
            return axios.post(`${state.baseURL}change-password `, {
                current_password: data.currentPassword,
                new_password: data.newPassword,
                new_confirm_password: data.confirmPassword,
            })
                .then(response => { return response})
                .catch(error => {return error})
        }



    },

    getters: {
        accessToken: state => state.access_token,
        userData: state => state.userData

    },
    modules: {},
})
