<template>
    <div>
        <div class="dashboard_container">
            <div class="dashboard_left-bar" :class="{'left_bar': leftTab}">
                <div class="d-flex">
                    <div class="dashboard_left-bar_logo"></div>
                    <div class="dashboard_x_btn-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828" @click="leftTab = !leftTab">
                            <g id="x_1_" data-name="x (1)" transform="translate(-4.586 -4.586)">
                                <line id="Line_318" data-name="Line 318" x1="12" y2="12" transform="translate(6 6)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <line id="Line_319" data-name="Line 319" x2="12" y2="12" transform="translate(6 6)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                        </svg>
                    </div>
                </div>


                <div class="dashboard_links">
                    <ul v-if="userData.role == 2">
                        <router-link to="/doctorOverview">
                            <li :class="{'active_tab': currentRouteName === 'DoctorOverview'}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.008" height="22.002" viewBox="0 0 22.008 22.002">
                                    <g id="pie-chart_1_" data-name="pie-chart (1)" transform="translate(-0.992 -1)">
                                        <path id="Path_928" class="svg_stroke" data-name="Path 928" d="M21.21,15.89A10,10,0,1,1,8,2.83" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_929" class="svg_stroke" data-name="Path 929" d="M22,12A10,10,0,0,0,12,2V12Z" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                                Overview
                            </li>
                        </router-link>
                        <router-link to="/patients">
                            <li :class="{'active_tab': currentRouteName === 'Patients'}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.087" viewBox="0 0 24 20.087">
                                    <g id="users_6_" data-name="users (6)" transform="translate(0 -1.913)">
                                        <path id="Path_922" class="svg_stroke" data-name="Path 922" d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <circle id="Ellipse_290" class="svg_stroke" data-name="Ellipse 290" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_923" class="svg_stroke" data-name="Path 923" d="M23,21V19a4,4,0,0,0-3-3.87" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_924" class="svg_stroke" data-name="Path 924" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                                Patients
                            </li>
                        </router-link>
                        <router-link to="/consultation">
                            <li :class="{'active_tab': currentRouteName === 'Consultation' || currentRouteName === 'PatientView'}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.434" height="22.996" viewBox="0 0 24.434 22.996">
                                    <g id="Group_1893" data-name="Group 1893" transform="translate(-312.98 -765.467)">
                                        <path id="Path_925" class="svg_fill" data-name="Path 925" d="M320.148,769.793c0-.611,0-1.164,0-1.718a2.444,2.444,0,0,1,2.557-2.6q2.48-.025,4.961,0a2.447,2.447,0,0,1,2.574,2.579c.005.555,0,1.11,0,1.735h.538c1.018,0,2.036-.013,3.053,0a3.6,3.6,0,0,1,3.572,3.6q.021,5.724,0,11.449a3.607,3.607,0,0,1-3.376,3.6c-.079.006-.159.014-.238.014-5.724,0-11.449.013-17.173-.006a3.576,3.576,0,0,1-3.59-3.068,3.88,3.88,0,0,1-.044-.664q0-5.581,0-11.163a3.616,3.616,0,0,1,3.749-3.763C317.842,769.789,318.953,769.793,320.148,769.793Zm-2.775,1.459v15.734h15.644V771.252Zm-1.494,15.614V771.41a2,2,0,0,0-1.457,1.916q-.021,5.789,0,11.577A2.088,2.088,0,0,0,315.879,786.866Zm18.639-15.455v15.453a2.126,2.126,0,0,0,1.455-2.1c.006-1.922,0-3.844,0-5.766,0-1.842.005-3.685,0-5.527A2.049,2.049,0,0,0,334.519,771.41Zm-5.713-1.641c0-.659.019-1.262-.005-1.864a.956.956,0,0,0-.966-.983c-1.764-.019-3.529-.016-5.294,0a.851.851,0,0,0-.9.763c-.068.684-.017,1.38-.017,2.083Z" fill="#5a5a5a"/>
                                        <path id="Path_926" class="svg_fill" data-name="Path 926" d="M391.048,858.787c0-.685-.005-1.317,0-1.949.007-.679.248-.917.92-.92q1.239-.005,2.478,0c.671,0,.909.241.916.925.007.631,0,1.262,0,1.944.682,0,1.313,0,1.945,0,.672,0,.926.249.931.915q.01,1.239,0,2.478c-.006.669-.251.9-.933.909-.618,0-1.236,0-1.943,0,0,.606,0,1.184,0,1.763,0,.94-.169,1.107-1.112,1.108-.778,0-1.557,0-2.335,0-.617,0-.858-.245-.867-.872-.01-.647,0-1.295,0-2-.7,0-1.349,0-2,0-.615-.005-.873-.253-.879-.865q-.014-1.287,0-2.574c.007-.6.274-.857.887-.862C389.707,858.783,390.354,858.787,391.048,858.787Zm2.884,5.717c0-.666,0-1.283,0-1.9.005-.708.241-.943.942-.946.63,0,1.259,0,1.9,0V860.22c-.655,0-1.273,0-1.892,0-.711,0-.943-.229-.948-.934,0-.631,0-1.263,0-1.9h-1.45c0,.619,0,1.2,0,1.789,0,.842-.2,1.045-1.028,1.046h-1.816v1.39a.549.549,0,0,0,.123.039q.81.006,1.62.007c.923,0,1.1.181,1.1,1.122,0,.568,0,1.136,0,1.725Z" transform="translate(-68.012 -81.818)" fill="#5a5a5a"/>
                                    </g>
                                </svg>

                                Consultation
                            </li>
                        </router-link>
                        <router-link to="/schedule">
                            <li :class="{'active_tab': currentRouteName === 'Schedule'}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                    <g id="calendar_14_" data-name="calendar (14)" transform="translate(-2 -1)">
                                        <rect id="Rectangle_89" class="svg_stroke" data-name="Rectangle 89" width="18" height="18" rx="2" transform="translate(3 4)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_32" class="svg_stroke" data-name="Line 32" y2="4" transform="translate(16 2)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_33" class="svg_stroke" data-name="Line 33" y2="4" transform="translate(8 2)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_34" class="svg_stroke" data-name="Line 34" x2="18" transform="translate(3 10)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                                My Schedule
                            </li>
                        </router-link>
                        <router-link to="/settings">
                            <li :class="{'active_tab': currentRouteName === 'Settings'}">
                                <svg id="settings_2_" data-name="settings (2)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <circle id="Ellipse_291" class="svg_stroke" data-name="Ellipse 291" cx="3" cy="3" r="3" transform="translate(9 9)" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_927" class="svg_stroke" data-name="Path 927" d="M19.4,15a1.65,1.65,0,0,0,.33,1.82l.06.06a2,2,0,1,1-2.83,2.83l-.06-.06a1.663,1.663,0,0,0-2.82,1.18V21a2,2,0,0,1-4,0v-.09A1.65,1.65,0,0,0,9,19.4a1.65,1.65,0,0,0-1.82.33l-.06.06a2,2,0,1,1-2.83-2.83l.06-.06a1.663,1.663,0,0,0-1.18-2.82H3a2,2,0,1,1,0-4h.09A1.65,1.65,0,0,0,4.6,9a1.65,1.65,0,0,0-.33-1.82l-.06-.06A2,2,0,1,1,7.04,4.29l.06.06a1.65,1.65,0,0,0,1.82.33H9a1.65,1.65,0,0,0,1-1.51V3a2,2,0,0,1,4,0v.09a1.663,1.663,0,0,0,2.82,1.18l.06-.06a2,2,0,1,1,2.83,2.83l-.06.06a1.65,1.65,0,0,0-.33,1.82V9a1.65,1.65,0,0,0,1.51,1H21a2,2,0,0,1,0,4h-.09A1.65,1.65,0,0,0,19.4,15Z" fill="none" stroke="#5a5a5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </svg>
                                Settings
                            </li>
                        </router-link>
                    </ul>
                    <ul v-if="userData.role == 3">
                        <li>Overview</li>
                        <li>Doctors</li>
                        <li>Consultation</li>
                        <li>My Schedule</li>
                        <li>Settings</li>
                    </ul>

                </div>
            </div>
            <div class="dashboard_content">
                <div class="dashboard_top-bar">
                    <div @click="leftTab = !leftTab" class="burger_menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="#000" class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </div>
                    <div class="search_container">
                        <b-form-input
                                id="example-input"
                                v-model="search"
                                @keyup="searchTerm"
                                type="text"
                                class="search_input"
                                placeholder="Search here..."
                                autocomplete="off"
                        ></b-form-input>
                        <b-icon icon="search" style="color: #888888; width: 18px; height: 18px; z-index: 10"
                                aria-hidden="true"></b-icon>
                    </div>
                    <div class="user-content">
                        <div class="bubble_container">
                            <div class="top_bubble search">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20.414 20.414">
                                    <g id="search_28_" data-name="search (28)" transform="translate(-2 -2)">
                                        <circle id="Ellipse_288" data-name="Ellipse 288" cx="8" cy="8" r="8" transform="translate(3 3)" fill="none" stroke="#888" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_31" data-name="Line 31" x1="4.35" y1="4.35" transform="translate(16.65 16.65)" fill="none" stroke="#888" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="top_bubble text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 20 24">
                                    <g id="Group_1892" data-name="Group 1892" transform="translate(-565.642 -242.481)">
                                        <path id="Path_914" data-name="Path 914" d="M565.646,254.448q0-3.958,0-7.916a3.78,3.78,0,0,1,4.031-4.049q5.5,0,11.008,0a3.744,3.744,0,0,1,3.979,3.947q.008,2.74,0,5.48a.917.917,0,0,1-1.067,1c-.51-.07-.8-.453-.8-1.087-.005-1.749,0-3.5,0-5.246a3.491,3.491,0,0,0-.056-.79,1.857,1.857,0,0,0-1.9-1.423c-1.577-.009-3.154,0-4.731,0q-3.209,0-6.417,0a1.923,1.923,0,0,0-2.156,2.129q-.005,7.987,0,15.973a1.924,1.924,0,0,0,2.15,2.135c.906,0,1.811,0,2.717,0a.947.947,0,1,1-.007,1.873q-1.429.007-2.857,0a3.753,3.753,0,0,1-3.877-3.872C565.635,259.881,565.646,257.164,565.646,254.448Z" transform="translate(0 0)"/>
                                        <path id="Path_915" data-name="Path 915" d="M683.048,378.457a2.73,2.73,0,0,1,2.421,1.558,2.7,2.7,0,0,1-.243,2.916,3.761,3.761,0,0,1-.433.49q-2.383,2.383-4.768,4.764a1.866,1.866,0,0,1-.871.512c-1.129.3-2.251.626-3.379.929-.927.249-1.518-.352-1.249-1.269.333-1.136.69-2.264,1.028-3.4a1.543,1.543,0,0,1,.408-.668q2.5-2.493,5-4.99A2.878,2.878,0,0,1,683.048,378.457Zm-6.237,8.937c.4-.112.742-.236,1.091-.3a2.309,2.309,0,0,0,1.339-.776c.585-.628,1.212-1.216,1.816-1.826.339-.342.918-.683.935-1.045.019-.418-.626-.706-.944-1.086-.18-.215-.3-.223-.5-.009-.77.8-1.519,1.624-2.36,2.339A4.42,4.42,0,0,0,676.811,387.394Zm7.1-6.089a.975.975,0,0,0-.543-.871.913.913,0,0,0-1.007.129c-.182.135-.233.257-.032.436.279.249.532.526.8.79.337.336.5.31.687-.13A2.213,2.213,0,0,0,683.906,381.3Z" transform="translate(-98.621 -123.235)"/>
                                        <path id="Path_916" data-name="Path 916" d="M611.457,302.472c1.59,0,3.181-.008,4.771,0a.932.932,0,0,1,.948,1.256.9.9,0,0,1-.906.613q-4.795,0-9.59,0a.933.933,0,1,1,0-1.865C608.276,302.465,609.867,302.472,611.457,302.472Z" transform="translate(-36.318 -54.367)"/>
                                        <path id="Path_917" data-name="Path 917" d="M611.439,344.36q-2.292,0-4.584,0c-.7,0-1.152-.379-1.141-.952.011-.557.448-.922,1.124-.922q4.631,0,9.262,0c.726,0,1.137.352,1.129.952-.008.58-.413.921-1.112.922Q613.778,344.364,611.439,344.36Z" transform="translate(-36.316 -90.632)"/>
                                        <path id="Path_918" data-name="Path 918" d="M609.606,382.477c.92,0,1.84,0,2.76,0a.947.947,0,1,1,0,1.873q-2.783.006-5.567,0a.947.947,0,1,1,0-1.873C607.735,382.474,608.67,382.477,609.606,382.477Z" transform="translate(-36.314 -126.877)"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="top_bubble notification">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 21.996">
                                    <g id="bell_8_" data-name="bell (8)" transform="translate(-2 -1)">
                                        <path id="Path_912" data-name="Path 912" d="M18,8A6,6,0,0,0,6,8c0,7-3,9-3,9H21s-3-2-3-9" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_913" data-name="Path 913" d="M13.73,21a2,2,0,0,1-3.46,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="top_bubble message">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                    <path id="message-circle" d="M21,11.5a8.38,8.38,0,0,1-.9,3.8A8.5,8.5,0,0,1,12.5,20a8.38,8.38,0,0,1-3.8-.9L3,21l1.9-5.7A8.38,8.38,0,0,1,4,11.5,8.5,8.5,0,0,1,8.7,3.9,8.38,8.38,0,0,1,12.5,3H13a8.48,8.48,0,0,1,8,8Z" transform="translate(-2 -2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </svg>
                            </div>
                        </div>
                        <div class="user-avatar">
                            <div
                                class="user-avatar_layer"
                                v-if="userData.avatar"
                                :style="{ backgroundImage: 'url(' + userData.avatar + ')' }"
                            ></div>
                            <!--                    <img :src="" alt="">-->
                        </div>

                        <div class="user-name">
                            <div class="user-dropdown">
                                <b-nav-item-dropdown :text="userData.name" >
                                    <b-dropdown-item @click="routeToProfile()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#19B198" class="bi bi-person-circle" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                        </svg>
                                        Profile
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#19B198" class="bi bi-clock-history" viewBox="0 0 16 16">
                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                        Payment History
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#19B198" class="bi bi-layer-forward" viewBox="0 0 16 16">
                                            <path d="M8.354.146a.5.5 0 0 0-.708 0l-3 3a.5.5 0 0 0 0 .708l1 1a.5.5 0 0 0 .708 0L7 4.207V12H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9V4.207l.646.647a.5.5 0 0 0 .708 0l1-1a.5.5 0 0 0 0-.708l-3-3z"/>
                                            <path d="M1 7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4.5a.5.5 0 0 0 0-1H1V8h4.5a.5.5 0 0 0 0-1H1zm9.5 0a.5.5 0 0 0 0 1H15v2h-4.5a.5.5 0 0 0 0 1H15a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-4.5z"/>
                                        </svg>
                                        Referrals
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#19B198" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                        </svg>
                                        Help/Support
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="logOut()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#19B198" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                        </svg>
                                        Log Out
                                    </b-dropdown-item>
                                </b-nav-item-dropdown>
                            </div>
                        </div>

                    </div>


                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Dashboard",
        data() {
            return {
                activeTab: 'overview',
                search: '',
                leftTab: false
            }
        },
        created() {

        },
        computed: {
          ...mapGetters(['userData']),
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            searchTerm(args) {
                alert.$emit("searchData", args.target.value);
                this.$store.commit('SEARCH_DATA', args.target.value)
            },
            logOut() {
                this.$store.commit('clearUserData')
                this.$router.push('/')
            },
            routeToProfile() {
                this.$router.push('/docProfile')
            }

        }
    }
</script>

<style scoped lang="scss">
.dashboard {
    &_container {
        display: flex;
        width: 100%;
        position: relative;
        @media screen and (max-width: 1200px) {
            justify-content: space-between;
        }
        & .left_bar {
            position: absolute;
            transform: translateX(-290px);
            /*width: 290px;*/
            padding: 0;
            /*transition: 0.8s;*/
            visibility: hidden;
            opacity: 0;
            @media screen and (max-width: 1024px) {
                position: sticky;
                opacity: 1 !important;
                visibility: visible !important;
                width: 295px;
                left: 0;
                transition: 0.8s;
                height: 100vh;
                padding: 20px 30px;
                transform: translateX(0);
            }
            @media screen and (max-width: 768px) {
                position: fixed;
            }
        }
    }
    &_x_btn {
      &-container {
          position: relative;
          display: none;
          @media screen and (max-width: 768px) {
              display: block;
          }

          & svg {
              position: absolute;
              top: 0;
              left: 40px;
          }
      }
    }
    &_left {
        &-bar {
            position: sticky;
            top: 0;
            left: 0;
            width: 295px;
            height: 100vh;
            box-shadow: 0 107px 12px #0000000F;
            background: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 30px;
            z-index: 15;
            transition: 0.8s;
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            @media screen and (max-width: 1024px) {
                position: fixed;
                top: 0;
                width: 290px;
                transition: 0.8s;
                height: 100vh;
            }
            @media screen and (max-width: 768px) {
                position: fixed;
                top: 0;
                left: 0;
                transition: 0.8s;
                height: 100vh;
                transform: translateX(-295px);
            }
            &_logo {
                width: 145px;
                height: 60px;
                background: url("~@/assets/images/logo.svg") no-repeat center;
                background-size: contain;
            }

        }
    }
    &_links {
        padding: 50px 0;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style-type: none;
                color: $grey;
                font-weight: 600;
                padding: 10px 0 10px 42px;
                width: 230px;
                height: 48px;
                display: flex;
                border-radius: 9px;
                cursor: pointer;
                /*justify-content: center;*/
                align-items: center;
                svg {
                    margin-right: 20px;
                }
                &.active_tab {
                    background: $mainGreen;
                    color: $white;
                    svg {
                        .svg_stroke {
                            stroke: $white !important;
                        }
                        .svg_fill {
                            fill: $white
                        }
                    }
                }
            }
            a {
                text-decoration: none;
            }
        }
    }
    &_top {
        &-bar {
            position: sticky;
            top: 0;
            z-index: 12;
            width: 100%;
            height: 80px;
            background: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            @media screen and (max-width: 768px) {
               padding: 0 15px;
            }
            @media screen and (max-width: 425px) {
                padding: 0 10px;
            }
            @media screen and (max-width: 375px) {
                padding: 0;
            }
            & .burger_menu {
                display: none;
                @media screen and (max-width: 768px) {
                    display: block;
                }
            }
            & .search_container {
                max-width: 416px;
                width: 100%;
                position: relative;
                @media screen and (max-width: 768px) {
                    display: none;
                }
                & .search_input {
                    width: 100%;
                    height: 48px;
                    padding: 0 20px 0 60px;
                    border: 1px solid #DBDBDB;
                    border-radius: 10px;
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
                & svg {
                    position: absolute;
                    top: 14px;
                    left: 20px;
                }
            }
            & .user {
                &-content {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 425px) {
                        margin-left: 0;
                    }
                    @media screen and (max-width: 375px) {
                        margin-right: 10px;
                        justify-content: space-around;
                    }
                    & .bubble_container {
                        width: 200px;
                        display: flex;
                        justify-content: space-evenly;
                        margin: 0 5px;
                        @media screen and (max-width: 375px) {
                            margin: 0;
                            width: 170px;
                        }
                        & .top_bubble {
                            width: 34px;
                            height: 34px;
                            background: #E9E9E9;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &.search {
                                display: none;
                                @media screen and (max-width: 768px) {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
                &-avatar {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    background: url("~@/assets/images/user_circle.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: relative;
                    &_layer {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: $white;
                        background-size: cover;
                    }
                }
                &-name {
                    margin-left: 10px;
                }
                &-dropdown {
                    height: 26px;
                    ::v-deep .dropdown-toggle:after {
                        width: 13px;
                        height: 10px;
                        content: '';
                        background: url("~@/assets/images/chevron_down.svg") no-repeat center;
                        background-size: contain;
                        border: none;
                    }
                    ::v-deep .dropdown-menu {
                        min-width: 12.5rem;
                        padding: 10px 0;
                        box-shadow: 0 5px 6px #0000000D;
                        top: 15px !important;
                        right: -40px !important;
                        left: initial !important;
                        /*padding: 10px 20px;*/
                    }
                    ::v-deep li {
                        /*height: 30px;*/
                        display: flex;
                        a {
                            padding: 0;
                            display: flex;
                            align-items: center;
                            &:focus-visible {
                                outline: none;
                            }
                            &:active {
                                background: transparent;
                            }
                            span {
                                padding: 0 10px;
                                color: $black;
                                font-weight: 600;
                                font-size: 16px;
                            }
                            svg {
                                margin-right: 10px;
                            }
                        }
                        .dropdown-item {
                            color: $grey !important;
                            font-size: 16px;
                            margin: 0;
                            padding: 5px 15px;
                            &:hover {
                                color: $grey !important;
                            }
                        }
                        &::marker {
                            display: none;
                        }
                    }
                }
            }

        }
    }
    &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1200px) {
            width: calc(100% - 295px);
        }
        @media screen and (max-width: 1024px) {
            max-width: 1022px;
            width: 100%;
            padding-left: 290px;
        }
        @media screen and (max-width: 768px) {
            padding-left: 0;
        }
    }
}
</style>