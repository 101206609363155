<template>
    <div class="main_container">
        <NavBar/>
        <div class="home_page-content">
            <div class="home_health-content">
                <div class="home_health-content-info" v-if="homeData">
                    <div class="home_health-title">HEALTH CARE</div>
                    <p class="home_health-description">{{homeData.healt_care}}</p>
                    <b-button class="home_health-btn">Read more</b-button>
                </div>
                <div class="home_health-img"></div>
            </div>

            <div class="home_cards-container">
                <div class="home_cards" v-if="sliderData.length > 0">
<!--                    :autoplayTimeout="15000"-->
                    <!--                    :responsive="{0:{items:1},1500: {items: 1}, 1550: {items: 2}, 1600:{items:2}}"-->
                    <carousel
                            :autoplay="true"
                            :nav="false"
                            :loop="true"
                            :responsive="{0: {items: 1}, 1024: {items: 1}, 1600: {items: 2}, 1900:{items:3}}"
                            class="home_carousel"

                    >
<!--                        <div class="carousel_item" v-for="item in sliderData">-->
<!--                            <div class="carousel_item-img">-->
<!--                                <img :src="item.icone ? item.icone: iconSvg" alt="">-->

<!--                            </div>-->
<!--                            <h4 class="carousel_item-title">{{item.title}}</h4>-->
<!--                            <div class="carousel_item-description">-->
<!--                                {{item.contents}}-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="carousel_item" v-for="(item, index) in sliderData" :key="index">
                            <div class="carousel_item-img">
                                <img :src="item.icone ? item.icone: iconSvg" alt="">

                            </div>
                            <h4 class="carousel_item-title">{{item.title}}</h4>
                            <div class="carousel_item-description">
                                {{item.contents}}
                            </div>
                        </div>








<!--                        <div class="carousel_item">-->
<!--                            <div class="carousel_item-img">-->
<!--                                <svg xmlns="http://www.w3.org/2000/svg" width="94.39" height="77.098"-->
<!--                                     viewBox="0 0 94.39 77.098">-->
<!--                                    <g id="Group_11114" data-name="Group 11114"-->
<!--                                       transform="translate(-915.103 -888.173)">-->
<!--                                        <path id="Path_759" data-name="Path 759"-->
<!--                                              d="M973.138,1011.11a23.067,23.067,0,0,0-16.988,7.5,23.067,23.067,0,0,0-16.988-7.5,22.733,22.733,0,0,0-2.419.132,31.5,31.5,0,0,0-7.133,20.058c0,14.206,18.233,33.98,33.2,47.818,11.465-9.684,33.716-30.243,33.716-44.014C996.523,1021.852,986.053,1011.11,973.138,1011.11Z"-->
<!--                                              transform="translate(1.123 -121.096)" fill="#fe7c7a"/>-->
<!--                                        <path id="Path_760" data-name="Path 760"-->
<!--                                              d="M990.165,1047.835a14.383,14.383,0,0,1,9.585-13.564,14.38,14.38,0,1,0,0,27.118A14.38,14.38,0,0,1,990.165,1047.835Z"-->
<!--                                              transform="translate(-2.331 -122.35)" fill="#fff"/>-->
<!--                                        <path id="Path_761" data-name="Path 761"-->
<!--                                              d="M1009.91,1047.884a10.168,10.168,0,1,0-9.59,13.555A14.381,14.381,0,0,0,1009.91,1047.884Z"-->
<!--                                              transform="translate(-3.137 -122.398)" fill="#c6ef9a"/>-->
<!--                                        <path id="Path_762" data-name="Path 762"-->
<!--                                              d="M979.15,1067.207l-.053.047a1.84,1.84,0,0,0,1.317,3.134,1.831,1.831,0,0,0,1.317-.547l.053-.057a1.842,1.842,0,1,0-2.633-2.577Z"-->
<!--                                              transform="translate(-1.976 -124.212)" fill="#24555e"/>-->
<!--                                        <path id="Path_763" data-name="Path 763"-->
<!--                                              d="M1002.049,1046.329h-4.9v-4.9a1.843,1.843,0,0,0-3.687,0v4.9h-4.9a1.845,1.845,0,0,0,0,3.691h4.9v4.9a1.843,1.843,0,0,0,3.687,0v-4.9h4.9a1.845,1.845,0,0,0,0-3.691Z"-->
<!--                                              transform="translate(-2.793 -122.694)" fill="#24555e"/>-->
<!--                                        <path id="Path_764" data-name="Path 764"-->
<!--                                              d="M997.2,1031.408a25.416,25.416,0,0,0-24.99-22.248,24.656,24.656,0,0,0-16.988,6.73,24.659,24.659,0,0,0-16.988-6.73c-13.912,0-25.23,11.591-25.23,25.835a22.616,22.616,0,0,0,2.454,9.448h-.611a1.841,1.841,0,1,0,0,3.681h2.574q5.165,8.75,16.313,19.841A237.6,237.6,0,0,0,954.1,1085.88a1.85,1.85,0,0,0,2.24,0c.077-.057,7.882-6.041,16.529-14.2a1.843,1.843,0,1,0-2.529-2.681c-6.6,6.211-12.684,11.147-15.12,13.073a237.355,237.355,0,0,1-18.914-16.745,109.814,109.814,0,0,1-14.555-17.207h16.505a1.837,1.837,0,0,0,1.772-1.331l3.221-11.233,8.92,28.581a1.844,1.844,0,0,0,3.52,0l4.939-15.829h8.23a1.845,1.845,0,0,0,0-3.691H959.27a1.849,1.849,0,0,0-1.76,1.293l-3.584,11.487-8.994-28.817a1.843,1.843,0,0,0-3.532.047l-4.535,15.81h-17.3c-1.906-3.6-2.881-6.768-2.881-9.448,0-12.214,9.664-22.144,21.543-22.144a21.079,21.079,0,0,1,15.633,6.909,1.841,1.841,0,0,0,2.71,0,21.081,21.081,0,0,1,15.634-6.909,21.67,21.67,0,0,1,21.076,17.538,15.673,15.673,0,0,0-2.116-.142,16.22,16.22,0,1,0,6.03,1.161Zm-6.029,27.6a12.535,12.535,0,1,1,12.532-12.535A12.552,12.552,0,0,1,991.167,1059.007Z"-->
<!--                                              transform="translate(2.103 -120.987)" fill="#24555e"/>-->
<!--                                    </g>-->
<!--                                </svg>-->
<!--                            </div>-->
<!--                            <h4 class="carousel_item-title">CONSECTETUR ADIPISCING</h4>-->
<!--                            <div class="carousel_item-description">-->
<!--                                Consectetur adipiscing elit. Nisl eget volutpat tristique aenean amet, vitae lectus non-->
<!--                                porttitor.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="carousel_item">-->
<!--                            <div class="carousel_item-img">-->
<!--                                <svg xmlns="http://www.w3.org/2000/svg" width="92.46" height="94.39"-->
<!--                                     viewBox="0 0 92.46 94.39">-->
<!--                                    <g id="Group_11115" data-name="Group 11115"-->
<!--                                       transform="translate(-1469.253 -878.583)">-->
<!--                                        <path id="Path_767" data-name="Path 767"-->
<!--                                              d="M1567.895,1091.343l-8.269-28.166a2.871,2.871,0,0,0-2.756-2.067h-45.222a2.883,2.883,0,0,0-2.766,2.067l-8.259,28.166a2.876,2.876,0,0,0,2.756,3.691h61.75A2.882,2.882,0,0,0,1567.895,1091.343Z"-->
<!--                                              transform="translate(-8.143 -123.901)" fill="#c6ef9a"/>-->
<!--                                        <path id="Path_768" data-name="Path 768"-->
<!--                                              d="M1509.965,1052.723a31.737,31.737,0,0,1,18.878-51.254,31.736,31.736,0,0,0-30.337,51.254h-.01l24.617,31.545,5.729-7.344-18.878-24.2Z"-->
<!--                                              transform="translate(-7.631 -120.526)" fill="#fff"/>-->
<!--                                        <path id="Path_769" data-name="Path 769"-->
<!--                                              d="M1548.27,1052.914a26.1,26.1,0,1,0-37.624-.16h0l18.878,24.2,18.765-24.041Z"-->
<!--                                              transform="translate(-8.312 -120.557)" fill="#fe7c7a"/>-->
<!--                                        <path id="Path_770" data-name="Path 770"-->
<!--                                              d="M1512.342,1033.343a20.65,20.65,0,0,1,16.037-20.124,20.648,20.648,0,1,0,0,40.258A20.652,20.652,0,0,1,1512.342,1033.343Z"-->
<!--                                              transform="translate(-8.29 -121.186)" fill="#fff"/>-->
<!--                                        <path id="Path_771" data-name="Path 771"-->
<!--                                              d="M1544.973,1033.374a16.457,16.457,0,1,0-16.046,20.134A20.658,20.658,0,0,0,1544.973,1033.374Z"-->
<!--                                              transform="translate(-8.838 -121.216)" fill="#ecf2ff"/>-->
<!--                                        <path id="Path_772" data-name="Path 772"-->
<!--                                              d="M1533.547,1024.711a6.273,6.273,0,0,0-8.863,0l-9.543,9.552a6.267,6.267,0,0,0,8.863,8.863l9.543-9.552A6.273,6.273,0,0,0,1533.547,1024.711Z"-->
<!--                                              transform="translate(-8.861 -121.757)" fill="#fff"/>-->
<!--                                        <path id="Path_773" data-name="Path 773"-->
<!--                                              d="M1535.661,1027.32a6.291,6.291,0,0,0-6.485,1.491l-4.767,4.776,4.993,4.993,4.767-4.776A6.249,6.249,0,0,0,1535.661,1027.32Z"-->
<!--                                              transform="translate(-9.484 -121.986)" fill="#c6ef9a"/>-->
<!--                                        <path id="Path_774" data-name="Path 774"-->
<!--                                              d="M1505.628,1087.808a1.845,1.845,0,1,0,2.615,0,1.857,1.857,0,0,0-2.615,0Z"-->
<!--                                              transform="translate(-8.4 -125.369)" fill="#24555e"/>-->
<!--                                        <path id="Path_775" data-name="Path 775"-->
<!--                                              d="M1568.287,1090.715l-8.259-28.166a4.743,4.743,0,0,0-4.531-3.389h-4.059a1.841,1.841,0,1,0,0,3.681h4.059a1.051,1.051,0,0,1,.991.746l8.269,28.166a1.018,1.018,0,0,1-.17.906,1,1,0,0,1-.831.415h-83.007a1,1,0,0,1-.831-.415,1.018,1.018,0,0,1-.17-.906l.7-2.369h17.132a1.845,1.845,0,0,0,0-3.691h-16.046l6.485-22.106a1.051,1.051,0,0,1,.991-.746h4.059a1.841,1.841,0,1,0,0-3.681h-4.059a4.743,4.743,0,0,0-4.531,3.389l-8.259,28.166a4.72,4.72,0,0,0,4.531,6.05h83.007a4.72,4.72,0,0,0,4.531-6.05Z"-->
<!--                                              transform="translate(-6.77 -123.792)" fill="#24555e"/>-->
<!--                                        <path id="Path_776" data-name="Path 776"-->
<!--                                              d="M1496.934,1053.746l24.617,31.545a1.838,1.838,0,0,0,1.454.717h0a1.838,1.838,0,0,0,1.454-.717l24.494-31.375c.038-.057.076-.1.1-.151a33.575,33.575,0,1,0-52.141-.047C1496.925,1053.727,1496.934,1053.737,1496.934,1053.746ZM1523,1002.691a29.891,29.891,0,0,1,23.06,48.913,1.367,1.367,0,0,0-.17.236L1523,1081.167l-23.107-29.62a.82.82,0,0,0-.076-.094A29.89,29.89,0,0,1,1523,1002.691Z"-->
<!--                                              transform="translate(-7.522 -120.417)" fill="#24555e"/>-->
<!--                                        <path id="Path_777" data-name="Path 777"-->
<!--                                              d="M1523.663,1055.727a22.488,22.488,0,1,0-22.493-22.493A22.518,22.518,0,0,0,1523.663,1055.727Zm0-41.3a18.807,18.807,0,1,1-18.8,18.8A18.829,18.829,0,0,1,1523.663,1014.431Z"-->
<!--                                              transform="translate(-8.18 -121.076)" fill="#24555e"/>-->
<!--                                        <path id="Path_778" data-name="Path 778"-->
<!--                                              d="M1519.448,1046.707a8.126,8.126,0,0,0,5.749-2.369l9.562-9.571a8.124,8.124,0,0,0,0-11.487,8.141,8.141,0,0,0-11.487,0l-9.562,9.571a8.124,8.124,0,0,0,0,11.487A8.121,8.121,0,0,0,1519.448,1046.707Zm6.428-20.813a4.433,4.433,0,0,1,6.277,0,4.421,4.421,0,0,1,0,6.268l-3.483,3.483-6.267-6.277Zm-9.562,9.562,3.483-3.483,6.267,6.277-3.473,3.474a4.432,4.432,0,0,1-6.277,0,4.421,4.421,0,0,1,0-6.268Z"-->
<!--                                              transform="translate(-8.75 -121.646)" fill="#24555e"/>-->
<!--                                    </g>-->
<!--                                </svg>-->
<!--                            </div>-->
<!--                            <h4 class="carousel_item-title">CONSECTETUR ADIPISCING</h4>-->
<!--                            <div class="carousel_item-description">-->
<!--                                Consectetur adipiscing elit. Nisl eget volutpat tristique aenean amet, vitae lectus non-->
<!--                                porttitor.-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="carousel_item">-->
<!--                            <div class="carousel_item-img">-->
<!--                                <svg xmlns="http://www.w3.org/2000/svg" width="94.39" height="77.098"-->
<!--                                     viewBox="0 0 94.39 77.098">-->
<!--                                    <g id="Group_11114" data-name="Group 11114"-->
<!--                                       transform="translate(-915.103 -888.173)">-->
<!--                                        <path id="Path_759" data-name="Path 759"-->
<!--                                              d="M973.138,1011.11a23.067,23.067,0,0,0-16.988,7.5,23.067,23.067,0,0,0-16.988-7.5,22.733,22.733,0,0,0-2.419.132,31.5,31.5,0,0,0-7.133,20.058c0,14.206,18.233,33.98,33.2,47.818,11.465-9.684,33.716-30.243,33.716-44.014C996.523,1021.852,986.053,1011.11,973.138,1011.11Z"-->
<!--                                              transform="translate(1.123 -121.096)" fill="#fe7c7a"/>-->
<!--                                        <path id="Path_760" data-name="Path 760"-->
<!--                                              d="M990.165,1047.835a14.383,14.383,0,0,1,9.585-13.564,14.38,14.38,0,1,0,0,27.118A14.38,14.38,0,0,1,990.165,1047.835Z"-->
<!--                                              transform="translate(-2.331 -122.35)" fill="#fff"/>-->
<!--                                        <path id="Path_761" data-name="Path 761"-->
<!--                                              d="M1009.91,1047.884a10.168,10.168,0,1,0-9.59,13.555A14.381,14.381,0,0,0,1009.91,1047.884Z"-->
<!--                                              transform="translate(-3.137 -122.398)" fill="#c6ef9a"/>-->
<!--                                        <path id="Path_762" data-name="Path 762"-->
<!--                                              d="M979.15,1067.207l-.053.047a1.84,1.84,0,0,0,1.317,3.134,1.831,1.831,0,0,0,1.317-.547l.053-.057a1.842,1.842,0,1,0-2.633-2.577Z"-->
<!--                                              transform="translate(-1.976 -124.212)" fill="#24555e"/>-->
<!--                                        <path id="Path_763" data-name="Path 763"-->
<!--                                              d="M1002.049,1046.329h-4.9v-4.9a1.843,1.843,0,0,0-3.687,0v4.9h-4.9a1.845,1.845,0,0,0,0,3.691h4.9v4.9a1.843,1.843,0,0,0,3.687,0v-4.9h4.9a1.845,1.845,0,0,0,0-3.691Z"-->
<!--                                              transform="translate(-2.793 -122.694)" fill="#24555e"/>-->
<!--                                        <path id="Path_764" data-name="Path 764"-->
<!--                                              d="M997.2,1031.408a25.416,25.416,0,0,0-24.99-22.248,24.656,24.656,0,0,0-16.988,6.73,24.659,24.659,0,0,0-16.988-6.73c-13.912,0-25.23,11.591-25.23,25.835a22.616,22.616,0,0,0,2.454,9.448h-.611a1.841,1.841,0,1,0,0,3.681h2.574q5.165,8.75,16.313,19.841A237.6,237.6,0,0,0,954.1,1085.88a1.85,1.85,0,0,0,2.24,0c.077-.057,7.882-6.041,16.529-14.2a1.843,1.843,0,1,0-2.529-2.681c-6.6,6.211-12.684,11.147-15.12,13.073a237.355,237.355,0,0,1-18.914-16.745,109.814,109.814,0,0,1-14.555-17.207h16.505a1.837,1.837,0,0,0,1.772-1.331l3.221-11.233,8.92,28.581a1.844,1.844,0,0,0,3.52,0l4.939-15.829h8.23a1.845,1.845,0,0,0,0-3.691H959.27a1.849,1.849,0,0,0-1.76,1.293l-3.584,11.487-8.994-28.817a1.843,1.843,0,0,0-3.532.047l-4.535,15.81h-17.3c-1.906-3.6-2.881-6.768-2.881-9.448,0-12.214,9.664-22.144,21.543-22.144a21.079,21.079,0,0,1,15.633,6.909,1.841,1.841,0,0,0,2.71,0,21.081,21.081,0,0,1,15.634-6.909,21.67,21.67,0,0,1,21.076,17.538,15.673,15.673,0,0,0-2.116-.142,16.22,16.22,0,1,0,6.03,1.161Zm-6.029,27.6a12.535,12.535,0,1,1,12.532-12.535A12.552,12.552,0,0,1,991.167,1059.007Z"-->
<!--                                              transform="translate(2.103 -120.987)" fill="#24555e"/>-->
<!--                                    </g>-->
<!--                                </svg>-->
<!--                            </div>-->
<!--                            <h4 class="carousel_item-title">CONSECTETUR ADIPISCING</h4>-->
<!--                            <div class="carousel_item-description">-->
<!--                                Consectetur adipiscing elit. Nisl eget volutpat tristique aenean amet, vitae lectus non-->
<!--                                porttitor.-->
<!--                            </div>-->
<!--                        </div>-->
                    </carousel>

                </div>
            </div>
            <div class="home_about-container">
                <div class="home_about-img"></div>
                <div class="home_about-info">
                    <div class="home_about-info-title">
                        ABOUT US
                    </div>
                    <div class="home_about-info-description" v-if="homeData">
                        <p>
                            {{homeData.about_us}}
                        </p>
                    </div>
                    <div class="home_about-info-content" v-if="homeData">
                        <p class="home_about-info-tel">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.224" height="29.219" viewBox="0 0 29.224 29.219">
                                <g id="Group_11116" data-name="Group 11116" transform="translate(-960 -1791.729)">
                                    <path id="Path_885" data-name="Path 885" d="M983.977,2079.04a1.695,1.695,0,0,0,0-2.39l-2.457-2.45a1.739,1.739,0,0,0-.547-.37,1.669,1.669,0,0,0-1.292,0,1.731,1.731,0,0,0-.548.37l-.972.97c-3.393,3.39-.314,9.09,3.131,12.54,3.436,3.43,9.146,6.52,12.539,3.13l.972-.97a1.79,1.79,0,0,0,.366-.55,1.731,1.731,0,0,0,.128-.65,1.642,1.642,0,0,0-.128-.64,1.676,1.676,0,0,0-.366-.55l-2.457-2.46a1.7,1.7,0,0,0-2.387,0l-.972.98c-.37.36-.947.4-1.716.1a7.66,7.66,0,0,1-2.441-1.72c-1.708-1.71-2.527-3.67-1.825-4.37Zm-4.225-4.22a.809.809,0,0,1,1.15,0l2.457,2.45a.832.832,0,0,1,.238.58.809.809,0,0,1-.238.57l-.663.66-3.607-3.6Zm10.825,10.82a.809.809,0,0,1,1.15,0l2.457,2.46a.826.826,0,0,1,.177.26.836.836,0,0,1,.062.31.849.849,0,0,1-.062.32.96.96,0,0,1-.177.26l-.663.66-3.606-3.61Zm-6.365-.64a8.517,8.517,0,0,0,2.739,1.91,2.473,2.473,0,0,0,2.31-.02l3.625,3.62c-2.748,2.22-7.53.03-10.975-3.42s-5.636-8.23-3.424-10.98l3.628,3.63C981.312,2081.11,982.571,2083.36,984.212,2085Z" transform="translate(-11.386 -276.661)" fill="#f36a57"/>
                                    <path id="Path_886" data-name="Path 886" d="M996.092,2093.06a.411.411,0,0,0-.164-.14.51.51,0,0,0-.211-.05l-3.745.1a.47.47,0,0,0-.167.03.625.625,0,0,0-.139.1.375.375,0,0,0-.091.15.411.411,0,0,0-.029.17.423.423,0,0,0,.037.16.485.485,0,0,0,.1.14.426.426,0,0,0,.144.09.485.485,0,0,0,.169.03l2.522-.06a13.74,13.74,0,0,1-19.78-18.65.415.415,0,0,0,.072-.15.5.5,0,0,0,.005-.18.505.505,0,0,0-.062-.16.37.37,0,0,0-.119-.12.453.453,0,0,0-.157-.07.5.5,0,0,0-.173,0,.444.444,0,0,0-.159.06.382.382,0,0,0-.123.12,14.611,14.611,0,0,0,20.766,20.04l-.709,2.03a.427.427,0,0,0,.028.32.425.425,0,0,0,.571.2.421.421,0,0,0,.226-.23l1.238-3.54,0-.01a.412.412,0,0,0,.019-.19A.464.464,0,0,0,996.092,2093.06Z" transform="translate(-11.386 -276.661)" fill="#f36a57"/>
                                    <path id="Path_887" data-name="Path 887" d="M986,2068.39a14.517,14.517,0,0,0-8.787,2.94l.709-2.03a.4.4,0,0,0,.031-.17.416.416,0,0,0-.039-.17.477.477,0,0,0-.1-.14.451.451,0,0,0-.15-.09.345.345,0,0,0-.174-.02.334.334,0,0,0-.169.04.6.6,0,0,0-.137.11.537.537,0,0,0-.084.15l-1.239,3.54,0,.01a.424.424,0,0,0-.012.23.376.376,0,0,0,.108.2.481.481,0,0,0,.151.11.541.541,0,0,0,.179.03l3.745-.1a.436.436,0,0,0,.306-.13.457.457,0,0,0,.12-.32.436.436,0,0,0-.136-.3.451.451,0,0,0-.313-.12l-2.523.06a13.741,13.741,0,0,1,19.782,18.65.4.4,0,0,0-.072.15.475.475,0,0,0-.005.18.505.505,0,0,0,.062.16.37.37,0,0,0,.119.12.453.453,0,0,0,.157.07.5.5,0,0,0,.172,0,.449.449,0,0,0,.16-.06.382.382,0,0,0,.123-.12A14.614,14.614,0,0,0,986,2068.39Z" transform="translate(-11.386 -276.661)" fill="#f36a57"/>
                                </g>
                            </svg>

                            {{homeData.phone_number}}
                        </p>
                        <p class="home_about-info-tel">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.222" height="29.22" viewBox="0 0 29.222 29.22">
                                <g id="Group_11117" data-name="Group 11117" transform="translate(-960.002 -1843.729)">
                                    <path id="Path_889" data-name="Path 889" d="M986,2120.39a14.618,14.618,0,1,0,10.327,4.28A14.654,14.654,0,0,0,986,2120.39Zm0,28.35a13.756,13.756,0,1,1,9.709-4.03A13.738,13.738,0,0,1,986,2148.74Z" transform="translate(-11.386 -276.661)" fill="#f36a57"/>
                                    <path id="Path_890" data-name="Path 890" d="M993.41,2128.63H978.59a1.682,1.682,0,0,0-1.688,1.69v9.36a1.7,1.7,0,0,0,1.688,1.69h14.82a1.7,1.7,0,0,0,1.687-1.69v-9.36a1.68,1.68,0,0,0-1.687-1.69Zm.781,11.27-4.632-4.33,4.663-3.63C994.209,2140.14,994.252,2139.67,994.191,2139.9ZM986,2137.24l-8.222-6.41v-.51a.833.833,0,0,1,.239-.58.814.814,0,0,1,.574-.23h14.818a.814.814,0,0,1,.574.23.828.828,0,0,1,.238.58v.51Zm-8.223-5.3,4.664,3.63-4.632,4.33c-.061-.23-.018.23-.032-7.96Zm.686,8.54,4.677-4.36,2.591,2.02a.447.447,0,0,0,.538,0l2.591-2.02,4.677,4.36c-.121.02-14.97.02-15.074,0Z" transform="translate(-11.386 -276.661)" fill="#f36a57"/>
                                </g>
                            </svg>
                            {{homeData.email}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import carousel from 'vue-owl-carousel'
    // @ is an alias to /src
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";
    import {mapActions} from "vuex";
    import iconSvg from "/src/assets/images/card_heart.svg"

    export default {
        name: 'Home',
        data() {
            return {
                iconSvg: iconSvg,
                slide: 0,
                sliding: null,
                homeData: null,
                sliderData: [],
            }
        },
        components: {
            NavBar,
            carousel,
            Footer
        },
        created() {
            this.getHomeData()
            .then(res =>{
                if(res.status === 200) {
                    this.homeData = res.data.info[0]
                    this.sliderData = res.data.slider
                }
            })
        },
        methods: {
            ...mapActions(['getHomeData']),
            onSlideStart(slide) {
                this.sliding = true
            },
            onSlideEnd(slide) {
                this.sliding = false
            }
        }
    }
</script>
<style scoped lang="scss">
    .main_container {
        width: 100%;

        .home {
            &_page-content {
                padding-top: 95px;
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0 auto;
                @media screen and (max-width: 1600px) {
                    max-width: 1500px;
                    padding: 95px 20px 0;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 1300px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 1020px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 900px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                }
                @media screen and (max-width: 425px) {
                    max-width: 400px;
                    padding: 85px 10px 0;
                }
            }

            &_health {
                &-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 40px 20px;
                    @media screen and (max-width: 1024px) {
                        flex-direction: column;
                    }


                    &-info {
                        max-width: 740px;
                        width: 100%;
                        @media screen and (max-width: 1440px) {
                            max-width: 640px;
                        }
                        @media screen and (max-width: 1200px) {
                            max-width: 470px;
                        }
                        @media screen and (max-width: 768px) {
                            max-width: 400px;
                        }
                        @media screen and (max-width: 425px) {
                            max-width: 320px;
                        }

                    }
                }

                &-title {
                    font-size: 42px;
                    font-weight: 800;
                    color: $mainGreen;
                    letter-spacing: 3.74px;
                    @media screen and (max-width: 1440px) {
                       font-size: 38px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 34px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 28px;
                    }
                }

                &-description {
                    font-size: 25px;
                    line-height: 44px;
                    color: $black;
                    letter-spacing: 0.97px;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 16px;
                        line-height: 24px;
                        padding: 10px 0;
                    }
                }

                &-btn {
                    border: 1px solid $mainGreen;
                    border-radius: 6px;
                    background: $white;
                    color: $mainGreen;
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.72px;
                    max-width: 262px;
                    width: 100%;
                    height: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 1200px) {
                        max-width: 220px;
                        height: 50px;
                    }
                    @media screen and (max-width: 425px) {
                        max-width: 180px;
                        height: 46px;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        background: $mainGreen;
                        color: $white;
                    }

                }

                &-img {
                    width: 700px;
                    height: 430px;
                    background: url("~@/assets/images/health_care_img.svg") no-repeat center;
                    background-size: contain;
                    @media screen and (max-width: 1440px) {
                        width: 500px;
                        height: 340px;
                    }
                    @media screen and (max-width: 1200px) {
                        width: 410px;
                        height: 300px;
                    }
                    @media screen and (max-width: 1024px) {
                        margin-top: 20px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 320px;
                        height: 250px;
                    }
                }
            }

            &_cards {
                margin-bottom: 50px;
                @media screen and (max-width: 1600px) {
                    max-width: 1300px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 1220px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 1000px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 500px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 440px;
                }
                @media screen and (max-width: 425px) {
                    max-width: 380px;
                    margin-bottom: 10px;
                }
                &-container {
                    /*width: 100%;*/
                }

                max-width: 1640px;
                width: 100%;
                overflow: hidden;
                display: flex;
                & .carousel_item {
                    width: 515px;
                    height: 448px;
                    margin: 0 20px;
                    padding: 60px 70px;
                    border: 1px solid #60D4C1;
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 1200px) {
                        width: 400px;
                        height: 360px;
                        padding: 25px 40px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 340px;
                        height: 280px;
                        padding: 15px 30px;
                    }

                    &-img {
                        margin: 10px 0 60px;
                        @media screen and (max-width: 1200px) {
                            margin: 0 0 40px;
                        }
                        @media screen and (max-width: 425px) {
                            margin: 0 0 20px;
                        }
                    }
                    &-title {
                        text-align: center;
                        letter-spacing: 0.51px;
                        font-size: 23px;
                        font-weight: bold;
                        color: $mainGreen;
                        margin-bottom: 22px;
                        @media screen and (max-width: 1200px) {
                            font-size: 20px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }

                    &-description {
                        text-align: center;
                    }
                }

                ::v-deep .owl-dots {
                    margin-top: 30px;
                    text-align: initial;
                    display: flex;
                    justify-content: flex-end;

                    & .owl-dot {
                        &.active {
                            span {
                                background: $mainGreen;

                            }
                        }
                    }

                }

                /*::v-deep .owl-carousel {*/
                /*    display: flex;*/
                /*    justify-content: space-evenly;*/
                /*}*/

                ::v-deep .owl-carousel .owl-item {
                    opacity: 1;
                }

                ::v-deep .owl-carousel .owl-item.active {
                    opacity: 1 !important;
                }

                ::v-deep .owl-carousel .owl-item.active.cloned {
                    opacity: 1 !important;
                }
            }

            &_carousel {
                width: 100%;
            }

            &_about {
                &-container {
                    margin: 60px 0;
                    padding: 30px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @media screen and (max-width: 1440px) {
                        margin: 40px 0;
                    }
                    @media screen and (max-width: 1200px) {
                        margin: 30px 0;
                        padding: 20px;
                    }
                    @media screen and (max-width: 1024px) {
                        flex-direction: column-reverse;
                    }
                    @media screen and (max-width: 425px) {
                        margin: 20px 0;
                    }
                }

                &-img {
                    width: 760px;
                    height: 400px;
                    background: url("~@/assets/images/about_img.svg") no-repeat center;
                    background-size: contain;
                    @media screen and (max-width: 1440px) {
                        width: 500px;
                        height: 340px;
                    }
                    @media screen and (max-width: 1200px) {
                        width: 370px;
                        height: 310px;
                    }
                    @media screen and (max-width: 1024px) {
                        margin-top: 20px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 320px;
                        height: 260px;
                    }
                }

                &-info {
                    max-width: 760px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    @media screen and (max-width: 1440px) {
                        max-width: 660px;
                    }
                    @media screen and (max-width: 1200px) {
                        max-width: 520px;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 400px;
                    }
                    @media screen and (max-width: 425px) {
                        max-width: 320px;
                    }
                    &-title {
                        color: $mainGreen;
                        font-weight: 800;
                        font-size: 42px;
                        letter-spacing: 3.74px;
                        @media screen and (max-width: 1440px) {
                            font-size: 38px;
                        }
                        @media screen and (max-width: 1200px) {
                            font-size: 38px;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 28px;
                        }

                    }
                    &-description {
                        padding: 20px 0 30px;
                        p {
                            color: $black;
                            letter-spacing: 0.7px;
                            font-size: 18px;
                            line-height: 30px;
                            padding: 15px 0;
                            margin: 0;
                            @media screen and (max-width: 425px) {
                                font-size: 16px;
                                padding: 0;
                                line-height: 28px;
                            }
                        }
                    }
                    &-content {

                    }
                    &-tel {
                        color: $mainOrange;
                        letter-spacing: 0.97px;
                        font-size: 25px;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 1440px) {
                            font-size: 22px;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 20px;
                        }
                        & svg {
                            margin-right: 30px;
                            @media screen and (max-width: 425px) {
                                margin-right: 20px;
                            }
                        }
                    }

                }

            }
        }

    }
</style>
