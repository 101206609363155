<template>
    <div class="page_content">
        <div class="calendar_container">
            <div>
              <v-app id="inspire">
                <v-row class="fill-height">
                  <v-col>
                    <v-sheet height="64">
                      <v-toolbar
                          flat
                      >
                        <v-btn
                            class="mr-4 today_btn"
                            @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                          <v-icon small>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                          <v-icon small>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>

                        <div>
                          <v-btn class="ml-3 toolbar_btn" :class="{'active': type ==='day'}" @click="type = 'day'">Day</v-btn>
                          <v-btn class="ml-3 toolbar_btn" :class="{'active': type ==='week'}" @click="type = 'week'">Week</v-btn>
                          <v-btn class="ml-3 toolbar_btn" :class="{'active': type ==='month'}" @click="type = 'month'">Month</v-btn>
                        </div>
<!--                        <v-menu-->
<!--                            bottom-->
<!--                            right-->
<!--                        >-->
<!--                          <template v-slot:activator="{ on, attrs }">-->
<!--                            <v-btn-->
<!--                                outlined-->
<!--                                color="grey darken-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                            >-->
<!--                              <span>{{ typeToLabel[type] }}</span>-->
<!--                              <v-icon right>-->
<!--                                mdi-menu-down-->
<!--                              </v-icon>-->
<!--                            </v-btn>-->
<!--                          </template>-->
<!--                          <v-list>-->
<!--                            <v-list-item @click="type = 'day'">-->
<!--                              <v-list-item-title>Day</v-list-item-title>-->
<!--                            </v-list-item>-->
<!--                            <v-list-item @click="type = 'week'">-->
<!--                              <v-list-item-title>Week</v-list-item-title>-->
<!--                            </v-list-item>-->
<!--                            <v-list-item @click="type = 'month'">-->
<!--                              <v-list-item-title>Month</v-list-item-title>-->
<!--                            </v-list-item>-->
<!--                          </v-list>-->
<!--                        </v-menu>-->
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet height="600" class="m-4 calendar_sheet">
                      <v-calendar
                          ref="calendar"
                          v-model="focus"
                          color="primary"
                          :events="events"
                          :event-color="getEventColor"
                          :type="type"
                          @click:event="showEvent"
                          @click:more="viewDay"
                          @click:date="viewDay"
                          @change="updateRange"
                      ></v-calendar>
                      <v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          offset-x
                      >
                        <v-card
                            color="grey lighten-4"
                            min-width="350px"
                            flat
                        >
                          <v-toolbar
                              :color="selectedEvent.color"
                              dark
                          >
                            <v-btn icon>
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon>mdi-heart</v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <span v-html="selectedEvent.details"></span>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                            >
                              Cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-app>

            </div>
<!--            <v-app id="dayspan" v-cloak>-->
<!--                <ds-calendar-app-->
<!--                        :calendar="calendar"-->
<!--                        :event-color="getEventColor"-->
<!--                        :events="events"-->
<!--                        -->
<!--                ></ds-calendar-app>-->
<!--            </v-app>-->

        </div>
    </div>
</template>

<script>
    import { Calendar } from 'dayspan';
    import {mapActions} from "vuex";
    export default {
        name: "Schedule",
        data() {
            return {
              focus: '',
              type: 'month',
              typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
              },
              selectedEvent: {},
              selectedElement: null,
              selectedOpen: false,
              events: [],
              colors: ['#19B198', '#F3C08D'],
              names: ['Consultation', 'Virtual', 'Office'],
              consultations: []

            }
        },
      created() {
        this.getConsultations()
            .then(res => {
              if (res.status === 200) {
                this.consultations = res.data
                this.updateEvents()
              }
            })


      },
      mounted() {
        this.$refs.calendar.checkChange()
      },
      methods: {
          ...mapActions(['getConsultations']),
            // getEventColor (event) {
            //     return event.color
            // },

        viewDay ({ date }) {
          this.focus = date
          this.type = 'day'
        },
        getEventColor (event) {
          return event.color
        },
        setToday () {
          this.focus = ''
        },
        prev () {
          this.$refs.calendar.prev()
        },
        next () {
          this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
          const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
          }

          if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
          } else {
            open()
          }

          nativeEvent.stopPropagation()
        },
        updateEvents() {
          const events = []

          for (let i = 0; i < this.consultations.length; i++) {
            let item = this.consultations[i]
            let st = new Date(item.hourFromTime * 1000)
            let en = new Date(item.hourToTime * 1000)
            events.push({
              name: item.consultType,
              start: st,
              end: en,
              color: item.consultType === 'office' ? '#19B198' : '#F3C08D',
              timed: true,
            })
          }
          this.events = events
        },
        updateRange ({ start, end }) {
          // const events = []
          //
          // const min = new Date(`${start.date}T00:00:00`)
          // const max = new Date(`${end.date}T23:59:59`)
          // const days = (max.getTime() - min.getTime()) / 86400000
          // const eventCount = this.rnd(days, days + 2)
          // for (let i = 0; i < eventCount; i++) {
          //   const allDay = this.rnd(0, 3) === 0
          //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          //   const second = new Date(first.getTime() + secondTimestamp)
          //   events.push({
          //     name: this.names[this.rnd(0, this.names.length - 1)],
          //     start: first,
          //     end: second,
          //     color: this.colors[this.rnd(0, this.colors.length - 1)],
          //     timed: true,
          //   })
          // }

          // for (let i = 0; i < this.consultations.length; i++) {
          //   let item = this.consultations[i]
          //   console.log(item, 'item')
          //   let first = new Date(item.hourFromTime - (item.hourFromTime % 900000))
          //   let second = new Date(item.hourToTime - (item.hourToTime % 900000))
          //   events.push({
          //     name: item.consultType,
          //     start: first,
          //     end: second,
          //     color: item.type === 'office' ? '#19B198' : '#F3C08D'
          //   })
          // }
          // this.events = events
        },
        rnd (a, b) {
          return Math.floor((b - a + 1) * Math.random()) + a
        },
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_content {
        background: #F8F8F8;
        padding: 30px;
        width: 100%;
        ::v-deep .calendar_container {
            width: 100%;
            max-height: 85vh;
            height: 100%;
          & .today_btn {
            background: #d6f1ed;
            border-radius: 23px;
            color: $black;
          }
          & .toolbar_btn {
            box-shadow: none;
            background: transparent;
            &:hover {
              background: transparent;
              border-radius: 23px;
            }
            &.active {
              background: #d6f1ed;
              border-radius: 23px;
            }
          }
          & .v-calendar-daily_head-day-label,
          & .v-calendar-weekly__day-label {
            & .v-btn {
              background: #d6f1ed;
              color: $black;
            }
          }
          & .v-calendar-weekly__head {
            border: 1px solid #e0e0e0;
            height: 60px;
            background: #EFF5F3;
            & .v-calendar-weekly__head-weekday {
              border-right: 1px solid #e0e0e0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          & .v-calendar-daily__head {
            border-top: 1px solid #e0e0e0;

          }
          & .v-calendar-daily_head-weekday {
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              background: #EFF5F3;
              margin-bottom: 10px;
            border-bottom: 1px solid #e0e0e0;
          }
          & .v-calendar-daily {
            border-top: none;
          }
          & .row.fill-height,
          & .v-sheet.v-toolbar {
            background: #F8F8F8;
          }


            /*
            & .v-content {
                padding: 60px 0 0 60px !important;
            }
            & .ds-app-calendar-toolbar {
                margin-top: 80px !important;
            }
            & .application--wrap {
                min-height: 85vh;
            }
            & .v-toolbar__content {
                padding-left: 380px;
            }
            & .ds-add-event-today {
                top: 100px;
                left: 300px;
                background: $mainOrange !important;
            }
            & .ds-calendar-event {
                width: 100px;
                position: unset;
            }
            & .ds-week {
                flex: unset;
            }
            & .ds-week-header-day {
                flex: unset;
                width: 120px;
                height: 40px;
                border-bottom: 1px solid #cccccc;
            }
            & .v-navigation-drawer {
                margin: 140px 0 0 -10px !important;
            }
            & .ds-day {
                flex: unset;
                width: 120px;
                height: 120px;
            }
            & .ds-dom.ds-today-dom {
                background-color: $mainGreen !important;
            }
            */
        }
    }
}
</style>