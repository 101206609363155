<template>
    <div class="page_content">
        <div class="page_dual-div reverse">
            <div class="page_reports">
                <div class="page_item-head">
                    <div class="page_item-head_title">Quick Reports</div>
                    <div class="select_arrow">
                        <b-form-select v-model="report_date">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                            </template>
                            <option
                                    v-for="(option, id) in getDate"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.title || null"
                            >
                                {{option.title}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                    </div>
                </div>
                <div class="reports_content">
                    <div class="reports_content-item">
                        <div class="info_item">
                            <img src="~@/assets/images/patient_icon.svg" alt="">
                            <div class="info_item-data">
                                <div class="info_item-title">New Patients</div>
                                <div class="info_item-count main_green">
                                    {{newPatients}}
                                    <img src="~@/assets/images/green_line.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="info_item">
                            <img src="~@/assets/images/consultate_icon.svg" alt="">
                            <div class="info_item-data">
                                <div class="info_item-title">Consultations</div>
                                <div class="info_item-count main_orange">
                                    {{consultations}}
                                    <img src="~@/assets/images/orange_line.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="reports_chart-item">
                        <div>
                            <apexchart :options="pieChartOptions" :series="pieSeries"></apexchart>
                        </div>
                    </div>
                    <div class="reports_chart-item">
                        <apexchart type="line"  :options="lineChartOptions" :series="lineSeries"></apexchart>
                    </div>
                </div>
            </div>
            <div class="page_user-info">
                <div class="page_user-info-img">
                    <img :src="image" alt="">
                </div>
                <div class="page_user-info-title">Dr Jessica Smith</div>
                <p class="page_user-info-spec">Medicine Specialist</p>
                <div class="page_user-info_content">
                    <div class="page_user-info_item green">
                        <img src="~@/assets/images/green_users_icon.svg" alt="">
                        <div>Worklood</div>
                        <p>50 patients</p>
                    </div>
                    <div class="page_user-info_item red">
                        <img src="~@/assets/images/red_plus__icon.svg" alt="">
                        <div>Services</div>
                        <p>3</p>
                    </div>
                    <div class="page_user-info_item orange">
                        <img src="~@/assets/images/orange_message_icon.svg" alt="">
                        <div>Consultations</div>
                        <p>10</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page_dual-div">
            <div class="page_booking-rate">
                <div class="page_item-head">
                    <div class="page_item-head_title">Booking Rate</div>
                    <div class="select_arrow">
                        <b-form-select v-model="report_date">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                            </template>
                            <option
                                    v-for="(option, id) in getDate"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.title || null"
                            >
                                {{option.title}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                    </div>
                </div>
                <div class="rate_content">
                    <div class="page_booking-rate-info">
                       <div class="page_booking-rate-percentage">60 %</div>
                        <div class="page_booking-rate-user">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.087" viewBox="0 0 24 20.087">
                                <g id="users_6_" data-name="users (6)" transform="translate(0 -1.913)">
                                    <path id="Path_922" data-name="Path 922" d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <circle id="Ellipse_290" data-name="Ellipse 290" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_923" data-name="Path 923" d="M23,21V19a4,4,0,0,0-3-3.87" fill="none" stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_924" data-name="Path 924" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                            Your total patient on friday
                        </div>
                    </div>
                    <apexchart :options="barChartOptions" :series="barSeries"></apexchart>
                </div>
            </div>
            <div class="page_seek-notes">
                <div class="page_item-head">
                    <div class="page_item-head_title">Sick Notes</div>
                    <div class="select_arrow">
                        <b-form-select v-model="report_date">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                            </template>
                            <option
                                    v-for="(option, id) in getDate"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.title || null"
                            >
                                {{option.title}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                    </div>
                </div>
                <div class="sick_notes-container">
                    <div v-for="item in sickNotes" class="sick_item">
                        <div class="sick_item-img">
                            <div class="sick_item-img_layer"></div>
                        </div>
                        <div class="sick_item-info">
                            <div>{{item.name}}</div>
                            <div>{{item.location}}</div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import image from '/src/assets/images/user_circle.svg'

    export default {
        name: "DoctorOverview",
        data() {
            return {
                image: image,
                report_date: 0,
                getDate: [
                    {
                        id: 0,
                        title: 'Month'
                    },
                    {
                        id: 1,
                        title: 'Week'
                    },
                    {
                        id: 2,
                        title: 'Day'
                    }
                ],
                newPatients: 560,
                consultations: 300,
                sickNotes: [
                    {
                        name: 'John',
                        location: 'New York, USA '
                    },
                    {
                        name: 'John',
                        location: 'New York, USA '
                    },
                    {
                        name: 'John',
                        location: 'New York, USA '
                    },
                    {
                        name: 'John',
                        location: 'New York, USA '
                    },
                    {
                        name: 'John',
                        location: 'New York, USA '
                    },
                    {
                        name: 'John',
                        location: 'New York, USA '
                    }
                ],

                pieSeries: [40, 20, 50, 43],
                lineSeries: [
                    {
                    name: "Lorem ipsum",
                    data: [300, 120, 500, 300, 500, 500, 1100, 300, 1300]
                    },
                    {
                        name: "lorem ipsum",
                        data: [500, 500, 1000, 100, 700, 700, 300, 500]
                    }
                ],
                barSeries: [{
                    name: 'Inflation',
                    data: [3, 7.1, 5.1, 8.8, 4.0, 5.3, 4.2, 2.3]
                }],

                pieChartOptions: {
                    chart: {
                        width: "100%",
                        // height: "200px",
                        type: 'donut',
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 270
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: [
                                '#EBCB7F',
                                '#53FFFF',
                                '#26E4BA',
                                '#8D12DF'
                            ], // optional, if not defined - uses the shades of same color in series
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [],
                            colorStops: []
                        }
                    },
                    colors: [
                        '#FF5489',
                        // '#EBCB7F',
                        '#C64ADB',
                        // '#53FFFF',
                        '#38A5EA',
                        // '#26E4BA',
                        '#3553D5',
                        // '#8D12DF'
                    ],
                    legend: {
                        formatter: function(val, opts) {
                            return val + " - " + opts.w.globals.series[opts.seriesIndex]
                        },
                        position: "right",
                        verticalAlign: "top"
                    },
                    title: {
                        text: ''
                    },
                    responsive: [{
                        breakpoint: 1450,
                        options: {
                            legend: {
                                // show: false,
                                position: 'bottom',

                            },
                            chart: {
                                height: "200px",
                            },
                        }
                    }]
                },
                lineChartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#16A18A', '#FF7B69'],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 2],
                        curve: 'straight'
                    },
                    markers: {
                        size: 4
                    },
                    // title: {
                    //     text: 'Product Trends by Month',
                    //     align: 'left'
                    // },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
                    }
                },
                barChartOptions: {
                    chart: {
                        width: "800px",
                        height: "350px",
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            dataLabels: {
                                position: 'bottom', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function (val) {
                            return val + "%";
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },

                    xaxis: {
                        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        labels: {
                          show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: false,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: true,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return val;
                            }
                        }

                    },
                    title: {
                        text: '',
                        floating: true,
                        offsetY: 330,
                        align: 'center',
                        style: {
                            color: '#444'
                        }
                    },
                    colors: [
                        function({ value, seriesIndex, w }) {
                            if (value <= 3) {
                                return '#D3E5E0'
                            } else if(value <= 5){
                                return '#16A18A'
                            } else {
                                return '#FF7B69'
                            }
                        }
                    ],
                    responsive: [
                        {
                            breakpoint: 2560,
                            options: {
                                chart: {
                                    width: "800px",
                                    height: "350px",
                                },
                                // plotOptions: {
                                //     bar: {
                                //         horizontal: false
                                //     }
                                // },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        },
                        {
                            breakpoint: 1441,
                            options: {
                                chart: {
                                    width: "600px",
                                    height: "350px",
                                },
                                // plotOptions: {
                                //     bar: {
                                //         horizontal: false
                                //     }
                                // },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        },
                        {
                            breakpoint: 1201,
                            options: {
                                chart: {
                                    width: "400px",
                                    height: "350px",
                                },
                                // plotOptions: {
                                //     bar: {
                                //         horizontal: false
                                //     }
                                // },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        },
                        {
                            breakpoint: 426,
                            options: {
                                chart: {
                                    width: "350px",
                                    height: "300px",
                                },
                                // plotOptions: {
                                //     bar: {
                                //         horizontal: false
                                //     }
                                // },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        },
                        {
                            breakpoint: 376,
                            options: {
                                chart: {
                                    width: "300px",
                                    height: "250px",
                                },
                                // plotOptions: {
                                //     bar: {
                                //         horizontal: false
                                //     }
                                // },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ]

                },

            }
        },

        methods: {

        }
    }
</script>

<style scoped lang="scss">
.page {
    &_content {
        display: flex;
        flex-direction: column;
        background: #F8F8F8 0 0 no-repeat padding-box;
        padding: 34px 30px 20px;
        @media screen and (max-width: 425px) {
            padding: 15px 10px 10px;
        }
    }
    &_dual-div {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 768px) {
            padding-left: 0;
        }
        &.reverse {
            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
            }

        }
    }
    &_item {
        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &_title {
                color: $black;
                font-size: 20px;
                font-weight: 600;
            }
            & .select_arrow {
                position: relative;
                & select {
                    width: 106px;
                    height: 28px;
                    border-radius: 5px;
                    border: none;
                    padding: 0 18px;
                    color: #fff;
                    -webkit-appearance: none;
                    background: #19B198 url("~@/assets/images/chevron_down_white.svg") right 1.2rem center/10px 15px no-repeat;
                    & option {
                        background: $white;
                        color: $black;
                    }
                    &:focus-visible {
                        outline: none;
                    }
                    &:before {
                        right: 10px;
                    }
                }
            }
        }
    }
    &_reports {
        max-width: 1110px;
        width: 100%;
        border: 1px solid #E6EEEC;
        border-radius: 20px;
        box-shadow: 0 5px 6px #00000008;
        margin-bottom: 30px;
        padding: 30px 40px 70px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1700px) {
            padding: 30px 25px 70px;
            max-width: 960px;
        }
        @media screen and (max-width: 1600px) {
            padding: 20px 20px 50px;
            max-width: 860px;
        }
        @media screen and (max-width: 1440px) {
            max-width: 680px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 460px;
        }

        & .reports {
            &_content {
                padding-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 1440px) {
                    flex-wrap: wrap;
                }
                &-item {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media screen and (max-width: 1440px) {
                        height: auto;
                        flex-direction: row;
                        width: 100%;
                    }
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    & .info_item {
                        width: 290px;
                        height: 94px;
                        border-radius: 10px;
                        border: 1px solid #E6EEEC;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 1700px) {
                           width: 240px;
                        }
                        @media screen and (max-width: 1200px) {
                            margin: 5px 0;
                        }
                        & img {
                            margin: 0 25px;
                            @media screen and (max-width: 1700px) {
                                margin: 0 15px;
                            }
                        }
                        &:hover {
                            box-shadow: 0 7px 18px #00000017;
                        }
                        &-data {
                            display: flex;
                            flex-direction: column;
                        }
                        &-title {
                            font-weight: 600;
                            padding-bottom: 5px;
                        }
                        &-count {
                            &.main_green {
                                color: $mainGreen;
                                font-weight: 600;
                            }
                            &.main_orange {
                                color: $mainOrange;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            &_chart{
                &-item {
                    width: 336px;
                    height: 218px;
                    border-radius: 10px;
                    border: 1px solid #E6EEEC;
                    @media screen and (max-width: 1700px) {
                        width: 300px;
                    }
                    @media screen and (max-width: 1600px) {
                        width: 270px;
                    }
                    @media screen and (max-width: 1440px) {
                        height: 180px;
                        margin-top: 15px;
                    }
                    @media screen and (max-width: 1200px) {
                        margin: 10px auto;
                    }
                    &:hover {
                        box-shadow: 0 7px 18px #00000017;
                    }
                }
            }
        }
    }
    &_user-info {
        max-width: 416px;
        width: 100%;
        border: 1px solid #E6EEEC;
        border-radius: 20px;
        box-shadow: 0 5px 6px #00000008;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 44px;
        @media screen and (max-width: 1700px) {
            padding: 20px;
            max-width: 360px;
        }
        @media screen and (max-width: 1200px) {
            justify-content: space-evenly;
        }

        &_content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            width: 98px;
            height: 110px;
            border-radius: 10px;
            padding: 12px 5px;
            & img {
                margin-bottom: 5px;
            }
            &.green {
                background: #19b19826;
                color: $mainGreen;
            }
            &.red {
                background: #FF7B6926;
                color: $mainOrange;
            }
            &.orange {
                background: #FFDA8A26;
                color: #FEBA4D;
            }
        }
        &-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px solid $mainGreen;
            margin: 20px 0;
            & img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                padding: 3px;
            }
        }
        &-title {
            color: $black;
            font-size: 18px;
            font-weight: 600;
        }
        &-spec {
            color: $mainGreen;
            font-weight: 500;
            font-size: 14px;

        }
    }
    &_booking-rate {
        max-width: 1110px;
        width: 100%;
        border: 1px solid #E6EEEC;
        border-radius: 20px;
        box-shadow: 0 5px 6px #00000008;
        padding: 30px 40px 50px;
        margin-bottom: 30px;
        @media screen and (max-width: 1700px) {
            max-width: 960px;
            padding: 30px 25px 50px;
        }
        @media screen and (max-width: 1600px) {
            max-width: 860px;
            padding: 20px;
        }
        @media screen and (max-width: 1440px) {
            max-width: 680px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 460px;
        }
        &-info {
            width: 180px;
            @media screen and (max-width: 1600px) {
                display: flex;
                width: 400px;
                justify-content: space-between;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 425px) {
                width: 320px;
                font-size: 14px;
            }
        }
        &-percentage {
            padding: 36px 0;
            color: $black;
            font-weight: 600;
            font-size: 30px;
            @media screen and (max-width: 1600px) {
                padding: 5px 0;
            }
        }
        &-user {
            display: flex;
            color: $black;
            @media screen and (max-width: 1700px) {
                flex-direction: column;
            }
            svg {
                margin-right: 15px;
                @media screen and (max-width: 1700px) {
                    margin-bottom: 10px;
                }
            }
        }
        & .rate_content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            @media screen and (max-width: 1600px) {
                flex-direction: column;
            }
        }
    }
    &_seek-notes {
        max-width: 416px;
        width: 100%;
        border: 1px solid #E6EEEC;
        border-radius: 20px;
        box-shadow: 0 5px 6px #00000008;
        padding: 30px 40px 10px;
        margin-bottom: 20px;
        @media screen and (max-width: 1700px) {
            max-width: 360px;
        }
        @media screen and (max-width: 1600px) {
            padding: 20px;
        }
        & .sick_notes-container {
            margin-top: 10px;
            padding: 10px 15px 10px 0;
            overflow-y: scroll;
            max-height: 420px;
            & .sick_item {
                width: 100%;
                height: 90px;
                box-shadow: 0 3px 13px #0000000F;
                border-radius: 20px;
                background: $white;
                margin: 10px 0;
                display: flex;
                align-items: center;
                padding: 12px 15px 12px 26px;
                &-img {
                    background: url("~@/assets/images/user_empty_img.svg") no-repeat center;
                    background-size: cover !important;
                    border-radius: 50%;
                    width: 67px;
                    height: 67px;
                    position: relative;
                    &_layer {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 67px;
                        height: 67px;
                        background-size: cover !important;
                    }
                }
                &-info {
                    padding: 0 10px;
                }

            }
        }

    }
}
</style>