<template>
    <div class="register_inputs-content">
        <form @submit.prevent="signUp(3)">
            <div class="input_item form-group">
                <b-form-input
                        id="name"
                        v-model="patientForm.p_fullName"
                        placeholder="Full Name"
                        type="text"
                        :class="{ 'is-invalid': isSubmitted && $v.patientForm.p_fullName.$error }"
                        trim
                ></b-form-input>
                <div v-if="isSubmitted && !$v.patientForm.p_fullName.required" class="invalid-feedback">Full
                    Name field is required
                </div>
            </div>

            <div class="input_item form-group">
                <b-form-input
                        v-model="patientForm.p_email"
                        placeholder="Email"
                        type="email"
                        :class="{ 'is-invalid': isSubmitted && $v.patientForm.p_email.$error }"
                        trim
                ></b-form-input>
                <div v-if="isSubmitted && $v.patientForm.p_email.$error" class="invalid-feedback">
                    <span v-if="!$v.patientForm.p_email.required">Email field is required</span>
                    <span v-if="!$v.patientForm.p_email.email">Please provide valid email</span>
                </div>
            </div>

            <div class="input_item form-group">
                <b-form-input
                        id="password"
                        v-model="patientForm.p_password"
                        placeholder="Password"
                        :type="r_pass"
                        :class="{ 'is-invalid': isSubmitted && $v.patientForm.p_password.$error }"
                        trim
                ></b-form-input>
                <div class="eye_close" @click="showPassword('r_pass')"></div>
                <div v-if="isSubmitted && $v.patientForm.p_password.$error" class="invalid-feedback">
                    <span v-if="!$v.patientForm.p_password.required">Password field is required</span>
                    <span v-if="!$v.patientForm.p_password.minLength">Password should be at least 6 characters long</span>
                </div>
            </div>

            <div class="input_item form-group">
                <b-form-input
                        id="confirm"
                        v-model="patientForm.p_c_password"
                        placeholder="Repeat Password"
                        :type="r_confirm"
                        :class="{ 'is-invalid': isSubmitted && $v.patientForm.p_c_password.$error }"
                        trim
                ></b-form-input>
                <div class="eye_close" @click="showPassword('r_confirm')"></div>
                <div v-if="isSubmitted && $v.patientForm.p_c_password.$error" class="invalid-feedback">
                    <span v-if="!$v.patientForm.p_c_password.required">Confirm Password field is required</span>
                    <span v-else-if="!$v.patientForm.p_c_password.sameAsPassword">Passwords should be matched</span>
                </div>
            </div>

            <div class="round">
                <input type="checkbox" id="checkbox1" v-model="patientForm.p_agree" @change="$v.patientForm.p_agree.$touch()" />
                <label for="checkbox1" :class="{ 'is-invalid': isSubmitted && $v.patientForm.p_agree.$error }"><span>I agree to <strong class="terms">Terms & Conditions</strong></span></label>
            </div>
            <div class="register_btn-container">
                <b-button variant="primary" class="register_btn" type="submit">Sign Up</b-button>
            </div>
            <div class="dont_have">Have an account? <router-link to="/login"><span class="dont_have-text">&nbsp; Log In</span></router-link></div>
        </form>
    </div>
</template>

<script>
    import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";
    export default {
        name: "SignPatient",
        data() {
            return {
                patientForm: {
                    p_fullName: '',
                    p_email: '',
                    p_password: '',
                    p_c_password: '',
                    role: 3,
                    p_agree: false,
                },
                isSubmitted: false,
                r_pass: 'password',
                r_confirm: 'password',
                registerError: null,

            }
        },
        validations: {
            patientForm: {
                    p_fullName: {
                        required
                    },
                    p_email: {
                        required,
                        email
                    },
                    p_password: {
                        required,
                        minLength: minLength(6)
                    },
                    p_c_password: {
                        required,
                        sameAsPassword: sameAs('p_password')
                    },
                    p_agree: {
                        required(val) {
                            return val
                        }
                    }
                }
        },
        methods: {
            ...mapActions(['registerUser']),
            signUp() {
                this.isSubmitted = true;
                this.registerError = null
                this.$v.$touch();
                if(this.$v.$invalid) {
                    console.log('invalid')
                    return
                } else {
                    this.registerUser(this.patientForm)
                        .then((res) => {
                            if(res.status === 200) {
                                console.log(res, 'sign up patient')
                                // this.$router.push('/patientOverview')
                            } else {
                                console.log(res.response.data)
                                this.errors = res.response.data.error
                            }
                        })
                }

            },
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === 'password') {
                        this.r_pass = 'text'
                    } else {
                        this.r_pass = 'password'
                    }
                } else {
                    if (this.r_confirm === 'password') {
                        this.r_confirm = 'text'
                    } else {
                        this.r_confirm = 'password'
                    }
                }
            },

        }
    }
</script>

<style scoped lang="scss">
    .register {
        &_inputs-content {
            width: 546px;
            & .input_item {
                width: 100%;
                margin-top: 20px;
                position: relative;
                /*@media screen and (max-width: 425px) {*/
                /*    margin-top: 15px;*/
                /*}*/

                & .eye_close {
                    position: absolute;
                    width: 30px;
                    height: 36px;
                    right: 15px;
                    top: 10px;
                    background-image: url("~@/assets/images/eye_close.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                }

                & input {
                    background: $white;
                    height: 53px;
                    border: 1px solid $lightBlue;
                    border-radius: 6px;
                    padding-left: 44px;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $lightBlue;
                        border-radius: 6px;
                    }
                }

            }
            & .dont_have {
                padding: 24px 0;
                display: flex;
                justify-content: center;
                font-size: 18px;
                letter-spacing: 0.18px;
                color: $black;
                & a {
                    text-decoration: none !important;
                }
                &-text {
                    color: $mainGreen;
                    font-weight: 600;
                    font-size: 18px;
                    letter-spacing: 0.18px;
                    cursor: pointer;
                }
            }
            .round {
                margin: 20px 0;
                position: relative;
            }

            .round label {
                background-color: #fff;
                border: 4px solid #EBEBEB;
                border-radius: 50%;
                cursor: pointer;
                height: 20px;
                left: 0;
                position: absolute;
                top: 0;
                width: 20px;
                &.is-invalid {
                    span {
                        color: #dc3545;
                        & .terms {
                            color: #dc3545;
                        }
                    }
                }
                & span {
                    position: absolute;
                    left: 30px;
                    top: -5px;
                    color: $greenBlack;
                    font-weight: 500;
                    width: 250px;
                    & .terms {
                        color: $mainGreen;
                        /*font-weight: 600;*/
                    }
                }
            }

            .round input[type="checkbox"] {
                visibility: hidden;
            }

            .round input[type="checkbox"]:checked + label {
                background-color: $mainGreen;
                border-color: #EBEBEB;
            }

            .round input[type="checkbox"]:checked + label:after {
                opacity: 1;
            }

        }
        &_btn {
            width: 260px;
            height: 54px;
            background: $mainGreen;
            border-radius: 6px;
            color: $white;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.72px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            /*@media screen and (max-width: 1440px) {*/
            /*    height: 50px;*/
            /*}*/
            /*@media screen and (max-width: 1024px) {*/
            /*    height: 45px;*/
            /*}*/
            &-container {
                display: flex;
                justify-content: center;
                margin-top: 30px;
            }
        }
    }
</style>