<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_title">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
          <g id="lock" transform="translate(-2 -1)">
            <rect id="Rectangle_4199" data-name="Rectangle 4199" width="18" height="11" rx="2"
                  transform="translate(3 11)" fill="none" stroke="#19b198" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="2"/>
            <path id="Path_7051" data-name="Path 7051" d="M7,11V7A5,5,0,0,1,17,7v4" fill="none"
                  stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </g>
        </svg>
        Security and Login
      </div>
    </div>
    <div class="page_settings-content">
      <div class="settings_item">
        <div class="settings_item-head">Change password</div>
        <div class="settings_item-content">
          <div class="settings_item-subtitle">It's a good idea to use a strong password that you're not using
            elsewhere
          </div>
          <div class="settings_item-inputs">
            <div class="input_item">
              <label>Current password</label>
              <b-form-input
                  v-model="changePasswordData.currentPassword"
                  type="password"
                  trim
              ></b-form-input>
              <span v-if="changePasswordErrors['current_password']" class="text_danger mt-2">{{changePasswordErrors['current_password'][0]}}</span>
            </div>
            <div class="input_item">
              <label>New password</label>
              <b-form-input
                  v-model="changePasswordData.newPassword"
                  type="password"
                  trim
              ></b-form-input>
              <span v-if="changePasswordErrors['new_password']" class="text_danger mt-2">{{changePasswordErrors['new_password'][0]}}</span>
            </div>
            <div class="input_item">
              <label>Confirm New password</label>
              <b-form-input
                  v-model="changePasswordData.confirmPassword"
                  type="password"
                  trim
              ></b-form-input>
              <span v-if="changePasswordErrors['new_confirm_password']" class="text_danger mt-2">{{changePasswordErrors['new_confirm_password'][0]}}</span>
            </div>
            <div class="change_btn-container">
              <span v-if="successMessage" class="success_msg mt-2">{{successMessage}}</span>
              <b-button class="change_btn" @click="passwordChange()">Change Password</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="settings_item">
        <div class="settings_item-head">Notifications Settings</div>
        <div class="settings_item-content">
          <div class="settings_item-subtitle">How You Get Notifications
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
              <path id="Polygon_10" data-name="Polygon 10" d="M5,0l5,6H0Z" transform="translate(10 6) rotate(180)"
                    fill="#ff7b69"/>
            </svg>
          </div>
          <div class="notification-content">
            <div class="notification_email">
              <svg xmlns="http://www.w3.org/2000/svg" width="22.785" height="18" viewBox="0 0 22.785 18">
                <g id="mail_10_" data-name="mail (10)" transform="translate(-0.607 -3)">
                  <path id="Path_7008" data-name="Path 7008"
                        d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z"
                        fill="none" stroke="#19b198" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Path_7009" data-name="Path 7009" d="M22,6,12,13,2,6" fill="none" stroke="#19b198"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>
              <span>email@gmail.com</span>
            </div>
            <div class="notification_item">
              <div class="notification_item-content">
                <div class="notification_item-content_title">Email Notification</div>
                <div class="notification_item-content_text">Receive weekly email notifications.</div>
              </div>
              <div class="notification_item-switch">
                <b-form-checkbox
                    v-model="emailNotification"
                    name="check-button"
                    switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="notification_item">
              <div class="notification_item-content">
                <div class="notification_item-content_title">Chat Notification</div>
                <div class="notification_item-content_text">Receive chat notifications.</div>
              </div>
              <div class="notification_item-switch">
                <b-form-checkbox
                    v-model="chatNotification"
                    name="check-button"
                    switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Settings",
  data() {
    return {
      changePasswordData: {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      emailNotification: false,
      chatNotification: false,
      changePasswordErrors: [],
      successMessage: ''
    }
  },
  methods: {
    ...mapActions(['changePassword']),
    passwordChange() {
      this.changePassword(this.changePasswordData)
      .then(res => {
        if(res.status === 200) {
          if(res.data.message) {
            this.changePasswordErrors = res.data.message
          }
          if(this.changePasswordErrors === 'Password change successfully.') {
            this.successMessage = 'Password change successfully.'
            setTimeout(() => {
              this.successMessage = ''
              this.changePasswordData.currentPassword = null
              this.changePasswordData.newPassword = null
              this.changePasswordData.confirmPassword= null
            }, 3000)
          }
          console.log(res, 'Password change successfully.')
        }
      })

    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    background: #F8F8F8;
    padding: 30px;
    width: 100%;
    min-height: calc(100vh - 80px);
  }

  &_head {
    display: flex;
    align-items: center;
  }

  &_title {
    color: $black;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 10px;

    & svg {
      margin-right: 20px;
    }
  }

  &_settings {
    &-content {
      padding: 20px 0;
      width: 100%;
      justify-content: space-between;
      display: flex;

      & .settings_item {
        max-width: 740px;
        width: 100%;
        border-radius: 12px;
        background: $white;

        &-head {
          width: 100%;
          height: 78px;
          border-radius: 12px;
          background: #E6EEEC;
          padding: 28px 44px;
          color: $black;
          font-size: 18px;
          font-weight: 600;
        }

        &-content {
          padding: 30px 50px;

          & .notification {
            &-content {
              display: flex;
              flex-direction: column;
              padding: 15px 0;
            }

            &_email {
              display: flex;
              align-items: center;

              svg {
                margin-right: 15px;
              }
            }

            &_item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 24px 0;

              &-content {
                display: flex;
                flex-direction: column;

                &_title {
                  padding-bottom: 10px;
                  font-weight: 500;
                  font-size: 18px;
                  color: $grey;
                }

                &_text {
                  color: $grey;
                  font-weight: 500;
                }
              }

              &-switch {
                width: 60px;

                ::v-deep label {
                  padding-left: 40px;
                  padding-top: 4px;

                  &:before {
                    width: 70px;
                    height: 40px;
                    border-radius: 23px;
                    background: #B4BEC7;
                    border: none;
                    box-shadow: none;
                  }

                  &:after {
                    width: 32px;
                    height: 32px;
                    background: $grey;
                    border-radius: 50%;
                    top: 8px;
                    left: -32px;
                    transition: .4s;
                  }
                }

                ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                  background: #E6EEEC !important;
                }

                ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
                  left: -15px;
                  background: $mainGreen !important;
                  transition: .4s;
                }
              }
            }
          }

        }

        &-subtitle {
          color: $mainOrange;
          font-weight: 500;
        }

        &-inputs {
          padding: 20px 0 40px;
          display: flex;
          flex-direction: column;

          & .input_item {
            padding: 10px 0;

            & label {
              color: $grey;
              margin-bottom: 2px;

            }

            & input {
              width: 410px;
              height: 44px;
              border: 1px solid #9E9E9E;
              border-radius: 10px;

            }
          }

          & .change_btn {
            width: 200px;
            height: 40px;
            background: #FF7B69;
            color: #fff;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 5px;

            &-container {
              padding-top: 20px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              & .success_msg {
                margin-right: 30px;
                font-size: 20px;
                font-weight: 600;
                color: $mainGreen;
              }
            }
          }

        }
      }
    }

  }

}
</style>