<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
    computed: {
        layout() {
            return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'default-layout'
        }
    }
};
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap');

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }
    .text_danger {
        color: red;
        font-size: 13px;
    }

</style>
