<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_title">
                Consultation
            </div>
            <div class="page_head_buttons">
                <div class="filter_text">Filter by date</div>
                <div class="filter_date ml-2">
                    <b-form-input
                            v-model="filterByDate"
                            @change="dateFilterChange"
                            type="date"
                            trim
                    ></b-form-input>
                </div>
                <div class="select_arrow">
                    <b-form-select v-model="selectedFilter" :options="filterOptions" @change="filterChange"></b-form-select>
                </div>

<!--                <b-dropdown >-->
<!--                    <b-dropdown-item @click="selectedFilter = null">All</b-dropdown-item>-->
<!--                    <b-dropdown-item @click="selectedFilter = 'upcoming'">Upcoming</b-dropdown-item>-->
<!--                    <b-dropdown-item @click="selectedFilter = 'past'">Past</b-dropdown-item>-->
<!--                </b-dropdown>-->

<!--                <b-button class="upcoming_btn ml-2" @click="selectedFilter = 'upcoming'">Upcoming</b-button>-->
<!--                <b-button class="past_btn ml-2" @click="selectedFilter = 'past'">Past</b-button>-->
<!--                <b-button class="all_btn" @click="selectedFilter = null">All</b-button>-->
                <b-button class="create_btn ml-2" @click="createConsultation()" id="consultation_btn">+ Add new consultation</b-button>
            </div>

        </div>
        <div class="page_content">
            <div class="table_container">
                <b-table hover :items="filteredconsultations" :fields="fields"  responsive>
                    <template v-slot:cell(patient)="data">
                        <div class="patient_info">
                            <div class="patient_img-empty">
                                <div class="patient_img-layer"></div>
                            </div>
                            <div class="patient_name-container">
                                <div class="patient_name">
                                    {{data.item.patient[0].firstName}} {{data.item.patient[0].lastName}}
                                </div>
                                <div class="patient_location">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.315" height="14.83" viewBox="0 0 12.315 14.83">
                                        <g id="map-pin_13_" data-name="map-pin (13)" transform="translate(0.5 0.5)">
                                            <path id="Path_6985" data-name="Path 6985" d="M14.315,6.658c0,4.4-5.658,8.172-5.658,8.172S3,11.058,3,6.658a5.658,5.658,0,0,1,11.315,0Z" transform="translate(-3 -1)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            <circle id="Ellipse_455" data-name="Ellipse 455" cx="2.458" cy="2.458" r="2.458" transform="translate(3.2 3.228)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                    {{data.item.patient[0].country}}, {{data.item.patient[0].city}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:cell(date)="data">
                        <div class="">
                            {{getDate(data.item.startDate)}} at {{data.item.hourFrom}}
                        </div>
                    </template>

                    <template v-slot:cell(id)="data">
                        <div class="">
                            {{data.item.patient[0].id}}
                        </div>
                    </template>

<!--                    <template v-slot:cell(diagnose)="data">-->
<!--                        <div class="">-->
<!--                            {{data.item.patient[0].diagnose || '_'}}-->
<!--                        </div>-->
<!--                    </template>-->

                    <template v-slot:cell(phone)="data">
                        <div class="patient_phone">
                            <span>{{data.item.patient[0].codePhoneNumber1}} &nbsp;{{data.item.patient[0].phoneNumber1}}</span>
                            <span>{{data.item.patient[0].codePhoneNumber2}} &nbsp;{{data.item.patient[0].phoneNumber2}}</span>
                        </div>
                    </template>
                    <template v-slot:cell(age)="data">
                        <div class="">
                            {{getAge(data.item.patient[0].dateOfBirth)}}
                        </div>
                    </template>
                    <template v-slot:cell(additional_contacts)="data">
                        <div class="patient_additional-info">
                            <span>{{data.item.patient[0].fullName}}</span>
                            <span>{{data.item.patient[0].codePhoneNumber}} &nbsp;{{data.item.patient[0].phoneNumber}}</span>
                        </div>
                    </template>

                    <template v-slot:cell(action)="data">
                        <div class="d-flex justify-content-center">
<!--                            <div class="view_button mx-2" id="consultation_view-btn" @click="consultationViewModal()"></div>-->
                            <div class="edit_button mx-2" @click="editConsultation(data.item)"></div>
                            <div class="trash_button mx-2" id="remove_consultation-btn" @click="deleteConsultation(data.item)"></div>
                        </div>
                    </template>


                </b-table>
            </div>

        </div>

<!--Create consultation modal start -->
        <b-modal ref="create_consultation-modal" centered hide-header hide-footer hide-header-close>
            <div class="create_consultation_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="consultationModal()"></b-icon>
                <div class="d-flex">
                    <div class="create_consultation-main_container">
                        <div class="create_consultation_title">
                            {{consultationModalTitle}}
                        </div>
                        <div class="create_consultation_content">
                            <div class="create_consultation-items">
                                <div class="create_consultation-item type"><p></p>Consult Type</div>
                                <div class="create_consultation-item patient"><p></p>Patient</div>
                                <div class="create_consultation-item date"><p></p>Start Date</div>
                                <div class="create_consultation-item hour"><p></p>Hour</div>
                                <div class="create_consultation-item online" v-if="tabType === 'virtual'"><p></p>Online
                                    Consult
                                </div>
                                <div class="create_consultation-item address" v-if="tabType === 'office'"><p></p>Address
                                </div>
                            </div>

                            <div class="create_consultation-tabs">
                                <b-card no-body>
                                    <b-tabs pills>

                                        <b-tab title="Virtual" :active="tabType === 'virtual'" :class="{'active': tabType ==='virtual'}" @click="changeType('virtual')">
                                            <b-card-text>
                                                <div class="create_consultation-tab virtual">
                                                    <div class="input_item">
                                                        <div class="select_arrow">
                                                            <b-form-select v-model="consultation.patient_id">
                                                                <template #first>
                                                                    <b-form-select-option :value="null" disabled>
                                                                        Select
                                                                    </b-form-select-option>
                                                                </template>
                                                                <option
                                                                        v-for="(option, id) in all_patients"
                                                                        :key="id"
                                                                        :value="option.id"
                                                                        :title="option.firstName || null"
                                                                >
                                                                    {{option.firstName}} {{option.lastName}}
                                                                </option>
                                                            </b-form-select>
                                                            <div class="arrow_down"></div>
                                                        </div>
                                                        <span v-if="virtualConsultatinErrors['patientId']" class="text_danger">The patient field is required.</span>
                                                    </div>
                                                    <div class="input_item">
                                                        <b-form-input
                                                                v-model="consultation.startDate"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>
                                                        <span v-if="virtualConsultatinErrors['startDate']" class="text_danger">{{virtualConsultatinErrors['startDate'][0]}}</span>

                                                    </div>
                                                    <div class="dual_inputs">
                                                        <div class="input_item">
                                                            <b-form-timepicker v-model="consultation.startHour"
                                                                               locale="en"></b-form-timepicker>
                                                            <span v-if="virtualConsultatinErrors['hourFrom']" class="text_danger">{{virtualConsultatinErrors['hourFrom'][0]}}</span>

                                                        </div>
                                                        <div class="input_item">
                                                            <b-form-timepicker v-model="consultation.endHour"
                                                                               locale="en"></b-form-timepicker>
                                                            <span v-if="virtualConsultatinErrors['hourTo']" class="text_danger">{{virtualConsultatinErrors['hourTo'][0]}}</span>

                                                        </div>
                                                    </div>
                                                    <div class="input_item radio_group">

                                                        <b-form-group>
                                                            <b-form-radio-group v-model="consultation.onlineConsult"
                                                                                buttons>
                                                                <template v-for="option in consultationOptions">
                                                                    <b-form-radio :value="option.value">
                                                                        <b-badge></b-badge>
                                                                    </b-form-radio>
                                                                </template>
                                                            </b-form-radio-group>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="create_consultation-btn_container">
                                                        <b-button v-if="consultModalType === 'add'" class="create_consultation-btn"
                                                                  @click="addConsultation()">Save
                                                        </b-button>
                                                        <b-button v-if="consultModalType === 'edit'" class="create_consultation-btn"
                                                                  @click="updateVirtualConsultation()">Update
                                                        </b-button>

                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>

                                        <b-tab title="Office" :active="tabType === 'office'" :class="{'active': tabType==='office'}" @click="changeType('office')">
                                            <b-card-text>
                                                <div class="create_consultation-tab office">
                                                    <div class="input_item">
                                                        <div class="select_arrow">
                                                            <b-form-select v-model="officeConsultation.patient_id">
                                                                <template #first>
                                                                    <b-form-select-option :value="null" disabled>
                                                                        Select
                                                                    </b-form-select-option>
                                                                </template>
                                                                <option
                                                                        v-for="(option, id) in all_patients"
                                                                        :key="id"
                                                                        :value="option.id"
                                                                        :title="option.firstName || null"
                                                                >
                                                                    {{option.firstName}} {{option.lastName}}
                                                                </option>
                                                            </b-form-select>
                                                            <div class="arrow_down"></div>
                                                        </div>
                                                        <span v-if="officeConsultatinErrors['patientId']" class="text_danger">The patient field is required.</span>
                                                    </div>
                                                    <div class="input_item">
                                                        <b-form-input
                                                                v-model="officeConsultation.startDate"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>
                                                         <span v-if="officeConsultatinErrors['startDate']" class="text_danger">{{officeConsultatinErrors['startDate'][0]}}</span>
                                                    </div>
                                                    <div class="dual_inputs">
                                                        <div class="input_item">
                                                            <b-form-timepicker v-model="officeConsultation.startHour"
                                                                               locale="en"></b-form-timepicker>
                                                            <span v-if="officeConsultatinErrors['hourFrom']" class="text_danger">{{officeConsultatinErrors['hourFrom'][0]}}</span>

                                                        </div>
                                                        <div class="input_item">
                                                            <b-form-timepicker v-model="officeConsultation.endHour"
                                                                               locale="en"></b-form-timepicker>
                                                            <span v-if="officeConsultatinErrors['hourTo']" class="text_danger">{{officeConsultatinErrors['hourTo'][0]}}</span>

                                                        </div>
                                                    </div>
                                                    <div class="input_item">
                                                        <b-form-input
                                                                v-model="officeConsultation.address"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                    <div class="create_consultation-btn_container">
                                                        <b-button v-if="consultModalType === 'add'" class="create_consultation-btn"
                                                                  @click="addOfficeConsultation()">Save
                                                        </b-button>
                                                        <b-button v-if="consultModalType === 'edit'" class="create_consultation-btn"
                                                                  @click="updateOfficeConsultation()">Update
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>


                                        <!--                                        <b-tab title="Out Of Office" @click="changeType('out')">-->
                                        <!--                                            <b-card-text>-->
                                        <!--                                                <div class="create_consultation-tab">-->
                                        <!--                                                    Out Of Office-->
                                        <!--                                                </div>-->
                                        <!--                                            </b-card-text>-->
                                        <!--                                        </b-tab>-->

                                    </b-tabs>
                                </b-card>

                            </div>

                        </div>
                    </div>
                </div>


                <!--                <div class="done_btn-container">-->
                <!--                    <b-button class="done_btn" @click="savePatient()">Save</b-button>-->
                <!--                </div>-->
            </div>
        </b-modal>
<!--Create consultation modal  end  -->

<!--Delete patient modal start-->
        <b-modal ref="delete_consultation_modal" centered hide-header hide-footer hide-header-close>
            <div class="delete-modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="removeConsultationModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        You are about to delete this consultation
                    </div>
                    <div class="modal_text">
                        Do you want to proceed?
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="removeConsultationModal()">Cancel</div>
                        <b-button class="done_btn ml-4" @click="consultationDelete()">Delete</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
<!--Delete card modal end-->

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Consultation",
        data() {
            return {
                filterByDate: null,
                selectedFilter: null,
                filterOptions: [
                    { value: null, text: 'All' },
                    { value: 'upcoming', text: 'Upcoming' },
                    { value: 'past', text: 'Past' },
                    { value: null, text: 'Reset', },
                ],
                all_patients: [],
                tabType: null,
                consultModalType: null,
                consultation: {
                    patient_id: null,
                    startDate: null,
                    startHour: null,
                    endHour: null,
                    onlineConsult: null,
                    consultType: 'virtual',
                    id: null
                },
                officeConsultation: {
                    patient_id: null,
                    startDate: null,
                    startHour: null,
                    endHour: null,
                    address: null,
                    consultType: 'office',
                    id: null
                },
                consultationOptions: [
                    {text: '', value: 'zoom'},
                    {text: '', value: 'teams'},
                ],
                fields: [
                    {
                        key: 'patient',
                        label: 'Patient',
                    },
                    {
                        key: 'date',
                        label: 'Date',
                    },
                    {
                        key: 'id',
                        label: 'Id',
                    },
                    // {
                    //     key: 'diagnose',
                    //     label: 'Diagnose'
                    // },
                    {
                        key: 'phone',
                        label: 'Phone',
                    },
                    {
                        key: 'age',
                        label: 'Age',
                    },
                    {
                        key: 'additional_contacts',
                        label: 'Additional contacts',
                    },
                    {
                        key: 'action',
                        label: 'Action',
                    },

                ],
                consultation_patients: [],
                virtualConsultatinErrors: [],
                officeConsultatinErrors: [],
                removeConsultationId: null,
                consultationModalTitle: null

            }
        },
        created() {
            this.getAllPatients()
                .then(res => {
                    if (res.status === 200) {
                        this.all_patients = res.data
                    }
                })
            this.getConsultations()
                .then(res => {
                    if (res.status === 200) {
                        this.consultation_patients= res.data
                    }
                })
        },
        computed: {
            filteredconsultations() {
                let today = Date.now()
                let filteredByType = this.consultation_patients.filter((user) => {
                    if(this.selectedFilter === 'past') {
                        return new Date(user.startDate).getTime() < today
                    } else if(this.selectedFilter === 'upcoming') {
                        return new Date(user.startDate).getTime() > today
                    }
                    if(this.filterByDate) {
                        return new Date(user.startDate).toDateString() === new Date(this.filterByDate).toDateString()
                    }
                    return user
                    // return new Date(user.startDate).getTime() > today
                    // return user.startDate.includes(this.selectedFilter)
                })
                return filteredByType
                // return filteredByType.sort((a, b) => {
                //     return b.consultType - a.consultType;
                // });
            },

        },
        methods: {
            ...mapActions([
                'getAllPatients',
                'createNewConsultation',
                'getConsultations',
                'removeConsultation',
                'updateConsultation'
            ]),
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            },
            getAge(birthday) {
                const millis = Date.now() - Date.parse(birthday);
                return new Date(millis).getFullYear() - 1970;
            },
            filterChange(args) {
                if(args === null) {
                    this.filterByDate = null
                }
            },
            dateFilterChange() {
                this.selectedFilter = null
            },
            createConsultation() {
                this.tabType = 'virtual'
                this.consultationModalTitle = 'Add Consultation'
                this.consultModalType = 'add'
                this.virtualConsultatinErrors = []
                this.officeConsultatinErrors = []
                this.consultation.patient_id = null
                this.consultation.startDate = null
                this.consultation.startHour = null
                this.consultation.endHour = null
                this.consultation.onlineConsult = null
                this.officeConsultation.patient_id = null
                this.officeConsultation.startDate = null
                this.officeConsultation.startHour = null
                this.officeConsultation.endHour = null
                this.officeConsultation.address = null
                this.consultationModal()
            },
            consultationModal() {
                this.$refs['create_consultation-modal'].toggle('#consultation_btn')
            },
            addConsultation() {
                this.virtualConsultatinErrors = []
                // this.consultation.consultType = this.tabType
                this.createNewConsultation(this.consultation)
                    .then(res => {
                        if (res.status === 200) {
                            this.consultationModal()
                            this.getConsultations()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.consultation_patients= res.data
                                    }
                                })
                        } else {
                            this.virtualConsultatinErrors = res.response.data.error
                        }
                    })
            },
            addOfficeConsultation() {
                this.officeConsultatinErrors = []
                // this.officeConsultation.consultType = this.tabType
                this.createNewConsultation(this.officeConsultation)
                    .then(res => {
                        if (res.status === 200) {
                            this.consultationModal()
                            this.getConsultations()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.consultation_patients= res.data
                                    }
                                })
                        } else {
                            this.officeConsultatinErrors = res.response.data.error
                        }
                    })
            },
            changeType(type) {
                this.tabType = type
            },
            editConsultation(item) {
                this.consultModalType = 'edit'
                this.consultationModalTitle = 'Update consultation'
                if(item.consultType === 'virtual') {
                    this.consultation.patient_id = item.patient[0].id
                    this.consultation.startDate = new Date(item.startDate).toISOString("en-US", {timeZoneName: "short"}).substr(0, 10)
                    this.consultation.startHour = item.hourFrom
                    this.consultation.endHour =  item.hourTo
                    this.consultation.onlineConsult = item.onlineConsult
                    this.consultation.consultType = item.consultType
                    this.consultation.id = item.id
                    this.officeConsultation.id = null
                    this.officeConsultation.consultType = null
                    this.officeConsultation.patient_id = null
                    this.officeConsultation.startDate = null
                    this.officeConsultation.startHour = null
                    this.officeConsultation.endHour = null
                    this.officeConsultation.address = null
                } else {
                    this.consultation.patient_id = null
                    this.consultation.startDate = null
                    this.consultation.endHour = null
                    this.consultation.onlineConsult = null
                    this.consultation.consultType = null
                    this.consultation.id = null
                    this.officeConsultation.id = item.id
                    this.officeConsultation.consultType = item.consultType
                    this.officeConsultation.patient_id = item.patient[0].id
                    this.officeConsultation.startDate = new Date(item.startDate).toISOString("en-US", {timeZoneName: "short"}).substr(0, 10)
                    this.officeConsultation.startHour = item.hourFrom
                    this.officeConsultation.endHour = item.hourTo
                    this.officeConsultation.address = item.address
                }
                this.consultationModal()
                this.changeType(item.consultType)
            },
            updateVirtualConsultation() {
                this.updateConsultation(this.consultation)
                .then(res => {
                    if(res.status === 200) {
                        this.consultationModal()
                        this.getConsultations()
                            .then(res => {
                                if (res.status === 200) {
                                    this.consultation_patients= res.data
                                }
                            })
                    }
                })

            },
            updateOfficeConsultation() {
                this.updateConsultation(this.officeConsultation)
                    .then(res => {
                        if(res.status === 200) {
                            this.consultationModal()
                            this.getConsultations()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.consultation_patients= res.data
                                    }
                                })
                        }
                    })
            },
            deleteConsultation(item) {
                this.removeConsultationId = item.id
                this.removeConsultationModal()
            },
            removeConsultationModal() {
              this.$refs['delete_consultation_modal'].toggle('#remove_consultation-btn')
            },
            consultationDelete() {
                this.removeConsultation(this.removeConsultationId)
                .then(res => {
                    if(res.status === 200) {
                        this.removeConsultationModal()
                        this.getConsultations()
                            .then(res => {
                                if (res.status === 200) {
                                    this.consultation_patients= res.data
                                }
                            })
                    }
                })

            },
            // consultationViewModal() {
            //     console.log('view')
            // }
        }
    }
</script>

<style scoped lang="scss">
    .text_danger {
        font-size: 11px;
    }
    .page {
        &_container {
            background: #F8F8F8;
            padding: 30px;
            width: 100%;
            height: calc(100vh - 80px);
            @media screen and (max-width: 1024px) {
                padding: 15px 30px 30px;
            }
        }

        &_head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
            }
            @media screen and (max-width: 768px) {
                flex-direction: row;
            }
            @media screen and (max-width: 425px) {
                flex-direction: column;
            }
            &_buttons {
                display: flex;
                align-items: center;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    justify-content: space-between;
                }
                @media screen and (max-width: 768px) {
                    flex-direction: column-reverse;
                    align-items: flex-end;
                }
                @media screen and (max-width: 425px) {
                    align-items: flex-start;
                }
                & .filter_text {
                    color: #9E9E9E;
                    font-size: 14px;
                    @media screen and (max-width: 768px) {
                        margin-top: 4px;
                    }
                }
                & .filter_date {
                    width: 210px;
                    @media screen and (max-width: 1200px) {
                        width: 180px;
                    }
                    @media screen and (max-width: 425px) {
                        margin-left: 0 !important;
                    }

                    & input {
                        height: 46px;
                        border: 1px solid #9E9E9E;
                        border-radius: 6px;
                        @media screen and (max-width: 1200px) {
                            height: 44px;
                        }
                        @media screen and (max-width: 768px) {
                            height: 40px;
                        }
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
                        cursor: pointer;
                        opacity: 0.5;
                        filter: invert(0.4);
                    }

                    input[type="date"]::-webkit-calendar-picker-indicator:hover {
                        opacity: 1;
                        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
                    }
                }
                & .select_arrow {
                    position: relative;
                    padding-left: 10px;
                    @media screen and (max-width: 768px) {
                        margin: 8px 0;
                    }
                    @media screen and (max-width: 425px) {
                        padding-left: 0;
                    }
                    & select {
                        width: 150px;
                        height: 48px;
                        border-radius: 5px;
                        border: none;
                        padding: 0 18px;
                        color: #fff;
                        -webkit-appearance: none;
                        background: #19B198 url("~@/assets/images/chevron_down_white.svg") right 1.2rem center/10px 15px no-repeat;
                        @media screen and (max-width: 1200px) {
                            font-size: 14px;
                            width: 140px;
                            height: 44px;
                        }
                        @media screen and (max-width: 768px) {
                            height: 40px;
                        }
                        ::v-deep option {
                            background: $white !important;
                            color: $black;
                        }
                        &:focus-visible {
                            outline: none;
                        }
                        &:before {
                            right: 10px;
                        }
                    }
                }


            }

            & .create_btn {
                height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                background: $mainOrange;
                color: $white;
                font-size: 15px;
                padding: 0 20px;
                border: none;
                @media screen and (max-width: 1200px) {
                    height: 44px;
                    padding: 0 15px;
                }
                @media screen and (max-width: 768px) {
                    height: 40px;
                }
                @media screen and (max-width: 425px) {
                    margin-left: 0 !important;
                }
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
            & .upcoming_btn {
                width: 108px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $mainGreen;
                color: $white;
                border-radius: 6px;
                border: none;
                &:focus {
                    box-shadow: none;
                    outline: none;
                }

            }
            & .past_btn {
                width: 108px;
                height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;
                color: $mainGreen;
                border: 1px solid $mainGreen;
                border-radius: 6px;
                &:focus {
                    box-shadow: none;
                    outline: none;
                }

            }
        }

        &_title {
            font-size: 20px;
            font-weight: 600;
            color: $black;
            @media screen and (max-width: 1024px) {
                padding-bottom: 10px;
            }
        }

        &_content {
            padding: 30px 0;
            & .table_container {
                font-size: 16px;
                font-weight: 600;
                ::v-deep .table {
                    width: 100%;
                }
                ::v-deep thead {
                    height: 62px;
                    color: $mainGreen;
                    font-weight: 500;
                    background: transparent;
                    tr {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        & th:first-child {
                            width: 300px;
                        }

                        & th:nth-child(2) {
                            width: 140px;
                        }

                        & th:nth-child(3) {
                            width: 100px;
                        }

                        /*& th:nth-child(4) {*/
                        /*    width: 120px;*/
                        /*}*/

                        & th:nth-child(4) {
                            width: 170px;
                        }

                        & th:nth-child(5) {
                            width: 60px;
                        }

                        & th:nth-child(6) {
                            width: 200px;
                        }

                        & th:last-child {
                            width: 120px;
                        }
                    }

                    th {
                        text-align: center;
                        border-bottom: none;
                        border-top: none;
                    }
                }

                ::v-deep tbody {
                    width: 100%;

                    tr {
                        display: flex;
                        justify-content: space-between;
                        background: $white;
                        margin: 10px 0 !important;
                        box-shadow: 0 3px 13px #0000000F;
                        border-radius: 20px;
                        width: 100%;
                        height: 120px;

                        td {
                            border-top: none;
                            display: flex;
                            align-items: center;
                            @media screen and (max-width: 768px) {
                                padding: 5px 10px;
                            }
                        }

                        & td:nth-child(2) {
                            width: 130px;
                        }

                        & td:nth-child(3) {
                            width: 60px;
                        }

                        /*& td:nth-child(4) {*/
                        /*    width: 80px;*/
                        /*}*/

                        & td:nth-child(4) {
                            width: 170px;
                        }

                        & td:nth-child(5) {
                            width: 60px;
                        }

                        & td:nth-child(6) {
                            width: 200px;
                        }
                    }
                }

                & .patient {
                    &_info {
                        width: 300px;
                        display: flex;
                        align-items: center;
                    }

                    &_img {
                        &-empty {
                            background: url("~@/assets/images/user_empty_img.svg") no-repeat center;
                            background-size: cover !important;
                            border-radius: 50%;
                            width: 67px;
                            height: 67px;
                            position: relative;
                            @media screen and (max-width: 768px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        &-layer {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 67px;
                            height: 67px;
                            background-size: cover !important;
                            @media screen and (max-width: 768px) {
                                width: 60px;
                                height: 60px;
                            }
                        }
                    }

                    &_name {
                        color: $black;
                        font-weight: 600;

                        &-container {
                            width: 230px;
                            height: 55px;
                            padding-left: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                    }

                    &_location {
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        line-height: 18px;

                        & svg {
                            margin-right: 5px;
                        }
                    }

                    &_phone {
                        display: flex;
                        flex-direction: column;
                    }

                    &_additional-info {
                        display: flex;
                        flex-direction: column;
                    }
                }

                & .view_button {
                    width: 22px;
                    height: 16px;
                    background: url("~@/assets/images/eye.svg") no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .edit_button {
                    width: 20px;
                    height: 20px;
                    background: url("~@/assets/images/edit.svg") no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .trash_button {
                    width: 16px;
                    height: 20px;
                    background: url("~@/assets/images/trash.svg") no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                }
            }
        }

    }

/*create consultation modal */
.create_consultation {
    &_modal_content {
        width: 600px;
        position: relative;
        padding: 0 10px;
        @media screen and (max-width: 768px) {
            width: 340px;
            padding: 0;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #9E9E9E;
        }
    }

    &-main_container {
        padding: 10px 5px;

    }

    &_title {
        color: #0F2D50;
        font-size: 20px;
        font-weight: 600;
    }

    &_content {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
    }

    &-items {
        width: 180px;
        @media screen and (max-width: 768px) {
            width: 140px;
        }
    }

    &-item {
        color: $mainGreen;
        font-size: 14px;
        font-weight: 600;
        padding: 11px 0;
        display: flex;
        align-items: center;

        & p {
            margin-right: 16px;
            margin-bottom: 0;
            width: 18px;
            height: 18px;
        }

        &.type {
            & p {
                background: url("~@/assets/images/grid_icon.png") no-repeat center;
                background-size: contain;
            }
        }

        &.patient {
            & p {
                background: url("~@/assets/images/user_icon.png") no-repeat center;
                background-size: contain;
            }
        }

        &.date {
            & p {
                background: url("~@/assets/images/calendar_icon.png") no-repeat center;
                background-size: contain;
            }
        }

        &.hour {
            & p {
                background: url("~@/assets/images/clock_icon.png") no-repeat center;
                background-size: contain;
            }
        }

        &.online {
            margin-top: 40px;
            @media screen and (max-width: 768px) {
                margin-top: 65px;
            }

            & p {
                background: url("~@/assets/images/airplay_icon.png") no-repeat center;
                background-size: contain;
            }
        }

        &.address {
            @media screen and (max-width: 768px) {
                margin-top: 55px;
            }
            & p {
                background: url("~@/assets/images/map_pin_icon.svg") no-repeat center;
                background-size: contain;
            }
        }

    }

    &-tabs {
        padding: 4px 0;
        width: 380px;
        @media screen and (max-width: 768px) {
            width: 185px;
        }

        ::v-deep .card {
            border: none;

            & .tabs {
                & .nav-pills {
                    & li {
                        margin-right: 15px;

                        & .nav-link {
                            border: 1px solid #E6EEEC;
                            border-radius: 5px;
                            height: 30px;
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 6px 15px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.active {
                                background: #8DD4F3;
                            }
                        }
                    }
                }
            }
        }
    }

    &-tab {
        & .input_item {
            margin: 10px 0;

            & .select_arrow {
                position: relative;

                & select {
                    width: 100%;
                    height: 35px;
                    border-radius: 5px;
                    border: 1px solid #D1D1D1;
                    padding: 0 15px;
                    -webkit-appearance: none;
                    background: #fff url("~@/assets/images/chevron_down.svg") right 1rem center/8px 12px no-repeat;

                    & option {
                        background: $white;
                        color: $black;
                    }

                    &:focus-visible {
                        outline: none;
                    }

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $lightBlue;
                        border-radius: 5px;
                    }

                    &:before {
                        right: 10px;
                    }
                }
            }

            & input {
                background: $white;
                width: 100%;
                height: 35px;
                border-radius: 5px;
                border: 1px solid #D1D1D1;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #D1D1D1;
                    border-radius: 5px;
                }
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                background: url("~@/assets/images/calendar_icon.svg") center/80% no-repeat;
                cursor: pointer;
            }

            input[type="date"]::-webkit-calendar-picker-indicator:hover {
                background: url("~@/assets/images/calendar_icon.svg") center/80% no-repeat;
            }

            &.radio_group {
                padding-top: 20px;
                @media screen and (max-width: 768px) {
                    padding-top: 10px;
                }

                & .btn {
                    border: none;
                    margin-right: 20px;

                    &.active {
                        box-shadow: none;
                        outline: none;
                        border: 1px solid $mainGreen;
                        border-radius: 12px;

                    }
                }

                & .btn:first-child {
                    width: 74px;
                    height: 74px;
                    background: url("~@/assets/images/zoom_logo.png");
                }

                & .btn:last-child {
                    width: 74px;
                    height: 74px;
                    background: url("~@/assets/images/microsoft_teams_logo.png");
                }

            }

        }

        & .dual_inputs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            & .input_item {
                width: 180px;
                margin: 0;
                @media screen and (max-width: 768px) {
                    margin-bottom: 8px;
                }

                & .b-form-btn-label-control.form-control {
                    height: 35px;
                    font-size: 14px;

                    ::v-deep .btn {
                        svg {
                            fill: $mainOrange;
                        }
                    }

                    ::v-deep label {
                        padding: 6px 8px 6px 4px;
                    }

                }
            }

        }
    }

    &-btn {
        width: 125px;
        height: 40px;
        background: $mainOrange;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 5px;

        &_container {
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

::v-deep .modal-content {
    border: none !important;
    width: 630px !important;
    @media screen and (max-width: 768px) {
        width: 365px !important;
    }

    & .modal-body {
        width: 630px !important;
        @media screen and (max-width: 768px) {
            width: 370px !important;
            /*padding: 16px 10px;*/
        }
    }
}
::v-deep .modal-dialog.modal-dialog-centered {
    @media screen and (max-width: 768px) {
       justify-content: center;
    }
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
}

/*modal start*/
::v-deep .delete-modal_content {
    border: none !important;
    width: 600px;
    padding: 0 10px;
    position: relative;
    @media screen and (max-width: 768px) {
        width: 340px;
    }
    ::v-deep .modal-body {
        width: 600px !important;
        @media screen and (max-width: 768px) {
            width: 370px;
        }
    }
    & .x_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $grey;
    }
    & .modal_title {
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        color: $mainGreen;
        border-bottom: 1px solid $lightBlue;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 600;
        }
        &.attachment {
            padding-left: 10px;
            color: $mainOrange;
        }
    }
    & .modal_text {
        font-size: 16px;
        color: $mainOrange;
        padding: 25px 0;
    }
    & .modal_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 0 30px;
        @media screen and (max-width: 768px) {
            padding: 15px 0;
        }
        & .cancel_btn {
            padding: 10px 0;
            cursor: pointer;
            background: $mainOrange;
            border-radius: 8px;
            width: 140px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 600;
            border: none;
            @media screen and (max-width: 768px) {
                width: 100px;
                height: 40px;
            }
        }
        & .done_btn {
            width: 140px;
            height: 48px;
            background: $mainGreen;
            border-radius: 8px;
            color: $white;
            font-weight: 600;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                width: 100px;
                height: 40px;
            }
        }
    }
    & .description_content {
        padding: 10px 0 5px;
        & .description_subtitle {
            font-weight: 500;
            font-size: 18px;
            padding: 0 15px;
            color: $mainOrange;

        }
        & .description_text {
            padding: 10px 20px 5px;
            border-bottom: 1px solid #e9e9e9;
        }

    }
}
/* modal end*/
</style>