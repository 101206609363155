<template>
    <div class="login_container">
        <router-link to="/">
            <div class="login_logo"></div>
        </router-link>

        <div class="login_content">
            <div class="login_left-side">
                <div class="login_title">LOG IN</div>
                <div class="login_inputs-content">
                    <form @submit.prevent="login()">
                        <div class="input_item form-group">
                            <b-form-input
                                    id="email"
                                    v-model="userForm.email"
                                    placeholder="Email"
                                    type="email"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                    trim
                            ></b-form-input>
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.email.required">Email field is required</span>
                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                            </div>
                        </div>
                        <div class="input_item form-group">
                            <b-form-input
                                    id="password"
                                    :type="l_password"
                                    placeholder="Password"
                                    v-model="userForm.password"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                    trim
                            ></b-form-input>
                            <div class="eye_close" @click="showPassword()"></div>
                            <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.password.required">Password field is required</span>
                                <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                            </div>
                        </div>

                        <div class="remember_forgot">
                            <div class="remember">
                                <input type="checkbox" id="check_remember">
                                <label for="check_remember">Remember me</label>

                            </div>
                            <div class="forget" id="forgot-btn" @click="forgotPassword()">
                                Forgot your password?
                            </div>
                        </div>
                        <div class="login_error" v-if="error_message">
                            {{error_message}}
                        </div>

                        <div class="login_btn-container">
                            <b-button variant="primary" class="login_btn" type="submit">LOG IN</b-button>
                        </div>

                        <div class="dont_have">Don't have an account? <router-link to="/register"><span class="dont_have-text">&nbsp; Sign up</span></router-link></div>
                    </form>
                </div>

            </div>

            <div class="login_right-side"></div>
        </div>
<!--forgot modal start-->
        <b-modal ref="forgot-modal" centered size="md" hide-header hide-footer>
            <div class="close_btn_content" @click="forgotPassword()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00457B" class="bi bi-x-lg"
                     viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                </svg>
            </div>
            <div class="modal_content">
                <div class="modal_name">
                    Forgot password
                </div>
                <div class="forgot_text">
                    Enter your email address and we'll send you a link to reset your password.
                </div>
                <div class="modal_input">
                    <div class="input_item form-group">
                        <b-form-input
                                id="forgot_email"
                                placeholder="Email"
                                v-model="forgot_email"
                                type="email"
                                trim
                        ></b-form-input>
                        <div class="mt-1">
                            <span v-if="forgotError" class="text_danger">{{forgotError}}</span>
                        </div>

                        <!--                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">-->
                        <!--                                <span v-if="!$v.userForm.email.required">Email field is required</span>-->
                        <!--                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>-->
                        <!--                            </div>-->
                    </div>
                </div>
                <div class="modal_btn">
                    <b-button class="send_btn" @click="sendForgotPassword()">Send</b-button>
                </div>

            </div>

        </b-modal>
<!--forgot modal end-->
    </div>
</template>

<script>
    import {required, email, minLength} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";
    export default {
        name: "Login",
        data() {
            return {
                userForm: {
                    email: "",
                    password: "",
                },
                isSubmitted: false,
                l_password: 'password',
                loginError: null,
                forgot_email: '',
                forgotError: null,
                error_message: ''
            }
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        methods: {
            ...mapActions(['loginUser']),
            login() {
                this.isSubmitted = true;
                this.loginError = null
                this.error_message = ''
                this.$v.$touch();
                if(this.$v.$invalid) {
                    return
                } else {

                    this.loginUser(this.userForm)
                    .then(res => {
                        if(res.status === 200) {
                            if(res.data.success.user.role == 2) {
                                this.$router.push('/doctorOverview')
                            } else if(res.data.success.user.role == 3){
                                this.$router.push('/patientOverview')
                            }
                        } else {
                            this.error_message = res.response.data.error_message
                        }
                    })
                }
            },
            showPassword() {
                if (this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            },
            forgotPassword() {
                this.forgot_email = ''
                this.$refs['forgot-modal'].toggle('#forgot-btn')
                this.forgotError = null
            },
            sendForgotPassword() {
                // this.sendForgot(this.forgot_email)
                //     .then((res) => {
                //         if(res.status === 200) {
                //             this.$refs['edit-modal'].toggle('#edit-btn')
                //         } else {
                //             this.forgotError = res.response.data.errors.email[0]
                //         }
                //     })

            }
        }
    }
</script>

<style scoped lang="scss">
.login {
    &_container {
        max-width: 1640px;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 1700px) {
            padding: 0 20px;
        }
        @media screen and (max-width: 1600px) {
            max-width: 1540px;
            padding: 0 30px;
        }
        @media screen and (max-width: 1440px) {
            max-width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 1150px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 990px;
            padding: 0 15px;
        }
        @media screen and (max-width: 768px) {
           max-width: 700px;
        }
        @media screen and (max-width: 425px) {
            max-width: 400px;
            padding: 0 10px;
        }
        @media screen and (max-width: 375px) {
            max-width: 360px;
        }
    }
    &_logo {
        width: 140px;
        height: 60px;
        background: url("~@/assets/images/logo.svg") no-repeat center;
        background-size: contain;
        margin: 50px 0 0;
        @media screen and (max-width: 425px) {
            margin: 20px auto 0;
        }
        @media screen and (max-width: 375px) {
            margin: 10px auto 0;
        }
    }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &_left-side {
        max-width: 820px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1700px) {
            max-width: 720px;
        }
        @media screen and (max-width: 1440px) {
            max-width: 600px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 530px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 480px;
        }
        @media screen and (max-width: 425px) {
            max-width: 400px;
        }

    }
    &_title {
        font-size: 62px;
        font-weight: bold;
        line-height: 76px;
        letter-spacing: 18.72px;
        color: $greenBlack;
        margin-bottom: 30px;
        @media screen and (max-width: 1024px) {
            font-size: 56px;
            letter-spacing: 14px;
            line-height: 58px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
        @media screen and (max-width: 425px) {
            font-size: 30px;
            letter-spacing: 5px;
            line-height: 34px;
            margin-bottom: 0;
        }
    }
    &_inputs-content {
        width: 546px;
        @media screen and (max-width: 1200px) {
            width: 500px;
        }
        @media screen and (max-width: 1024px) {
            width: 460px;
        }
        @media screen and (max-width: 425px) {
            width: 370px;
        }
        @media screen and (max-width: 375px) {
            width: 350px;
        }
        & .input_item {
            width: 100%;
            margin-top: 20px;
            position: relative;
            /*@media screen and (max-width: 425px) {*/
            /*    margin-top: 15px;*/
            /*}*/

            & .eye_close {
                position: absolute;
                width: 30px;
                height: 36px;
                right: 15px;
                top: 10px;
                background-image: url("~@/assets/images/eye_close.svg");
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
                @media screen and (max-width: 425px) {
                    top: 6px;
                }

            }

            & input {
                background: $white;
                height: 53px;
                border: 1px solid $lightBlue;
                border-radius: 6px;
                padding-left: 44px;
                @media screen and (max-width: 425px) {
                    padding-left: 20px;
                    height: 44px;
                }

                &:focus {
                    box-shadow: none;
                    border: 1px solid $lightBlue;
                    border-radius: 6px;
                }
            }

        }
        & .remember_forgot {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 60px;
            @media screen and (max-width: 768px) {
                margin-bottom: 40px;
            }
        }
        & .remember {
            display: flex;
            align-items: center;
            input {
                accent-color: #19a298;
            }
            input[type=checkbox]:checked:before {
                border: 1px solid;
            }
            & label {
                margin-left: 15px !important;
                color: $black;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
                margin-bottom: 0;
                @media screen and (max-width: 375px) {
                    font-size: 14px;
                }
            }
        }

        & .forget {
            color: $black;
            font-size: 16px;
            letter-spacing: 0.16px;
            font-weight: 400;
            cursor: pointer;
            @media screen and (max-width: 375px) {
                font-size: 14px;
            }
        }
        & .login_error {
            display: flex;
            justify-content: center;
            color: red;
            font-size: 20px;
            font-weight: 600;
            padding: 0 0 15px;
        }

        & .login_btn {
            width: 260px;
            height: 54px;
            background: $mainGreen;
            border-radius: 6px;
            color: $white;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.72px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            /*@media screen and (max-width: 1440px) {*/
            /*    height: 50px;*/
            /*}*/
            /*@media screen and (max-width: 1024px) {*/
            /*    height: 45px;*/
            /*}*/
            &-container {
                display: flex;
                justify-content: center;
            }
        }
        & .dont_have {
            padding: 24px 0;
            display: flex;
            justify-content: center;
            font-size: 18px;
            letter-spacing: 0.18px;
            color: $black;
            & a {
                text-decoration: none !important;
            }
            &-text {
                color: $mainGreen;
                font-weight: 600;
                font-size: 18px;
                letter-spacing: 0.18px;
                cursor: pointer;
            }
        }
    }
    &_right-side {
        width: 820px;
        height: 495px;
        background: url("~@/assets/images/login.svg") no-repeat center;
        background-size: contain;
        @media screen and (max-width: 1700px) {
            max-width: 700px;
            width: 100%;
        }
        @media screen and (max-width: 1440px) {
            max-width: 650px;
        }
        @media screen and (max-width: 1200px) {
            max-width: 500px;
            height: 400px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 420px;
        }
        @media screen and (max-width: 768px) {
            max-width: 400px;
            height: 350px;
        }
        @media screen and (max-width: 425px) {
            max-width: 340px;
            height: 270px;
        }
        @media screen and (max-width: 375px) {
            max-width: 330px;
        }
    }

}

/* modal */
::v-deep .modal-dialog {
    min-width: 550px;
    @media screen and (max-width: 425px) {
        max-width: 410px;
        min-width: unset;
    }
    /*@media screen and (max-width: 425px) {*/
    /*    min-width: unset;*/
    /*}*/

}

::v-deep .modal-content {
    border: none;
}

.close_btn_content {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 20px;
    right: 25px;
    cursor: pointer;
}

.modal_content {
    padding: 5px 15px 15px;
    border: none;
    @media screen and (max-width: 425px) {
        padding: 5px 10px 10px;
    }

    & .modal_name {
        font-size: 25px;
        font-weight: 600;
        color: $grey;
        @media screen and (max-width: 425px) {
            font-size: 22px;
        }
    }

    & .forgot_text {
        padding: 10px 0 25px;
        font-size: 13px;
        color: $mainGreen;
        @media screen and (max-width: 425px) {
            font-size: 12px;
            padding: 10px 0 20px;
        }
    }

    & .modal_input {
        padding-bottom: 20px;
        @media screen and (max-width: 425px) {
            padding-bottom: 10px;
        }

        & .input_item {
            & input {
                background: $white;
                width: 100%;
                height: 53px;
                border: 1px solid $lightBlue;
                border-radius: 6px;
                padding-left: 44px;
                @media screen and (max-width: 425px) {
                    padding-left: 20px;
                }

                &:focus {
                    box-shadow: none;
                    border: 1px solid $lightBlue;
                    border-radius: 6px;
                }
            }
        }
    }

    & .modal_btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & .send_btn {
            background: $mainGreen;
            border-radius: 10px;
            width: 110px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 600;
            border: none;
        }
    }
}
</style>