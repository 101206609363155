<template>
    <div class="w-100">
        <div class="navigation_container">
            <div class="navigation_content">
                <router-link to="/">
                    <div class="navigation_logo"></div>
                </router-link>
                <div class="navigation_links">
                    <ul>
                        <li>The Atlega Difference</li>
                        <li>Services</li>
                        <li>Contact Us</li>
                        <li>Doctor Profiles</li>
                    </ul>
                </div>
                <div class="navigation_user-profile">
                    <b-nav-item-dropdown text="" center>
                        <b-dropdown-item class="log_in" @click="login()">LOG IN</b-dropdown-item>
                        <b-dropdown-item class="register" @click="register()">SIGN UP</b-dropdown-item>
                    </b-nav-item-dropdown>
                </div>
            </div>
<!--            Burger menu-->
            <div>
                <div class="burger-menu" :class="{'active': activeMenu}" @click="onBurgerClicked()">
                    <div class="burger-container">
                        <div class="burger-inner"></div>

                        <div class="menu_content" :class="{'show': activeMenu}">
                            <ul>
                                <li>The Atlega Difference</li>
                                <li>Services</li>
                                <li>Contact Us</li>
                                <li>Doctor Profiles</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--            -->

    </div>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                activeMenu: false

            }
        },
        methods: {
            login() {

                this.$router.push('/login')
            },
            register() {
                this.$router.push('/register')
            },
            onBurgerClicked() {
                this.activeMenu = !this.activeMenu;
            }
        }
    }
</script>

<style scoped lang="scss">
.navigation {
    &_container {
        width: 100%;
        height: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        position: fixed;
        top: 0;
        z-index: 3;
        background: $white;
        @media screen and (max-width: 1700px) {
            padding: 0 60px;
        }
        @media screen and (max-width: 1024px) {
            padding: 0 40px;
        }
    }
    &_content {
        max-width: 1640px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    &_logo {
        width: 146px;
        height: 60px;
        background-size: contain;
        background: url("~@/assets/images/logo.svg") no-repeat center;
        @media screen and (max-width: 1024px) {
            width: 120px;
            height: 50px;
            background-size: cover !important;
        }
    }
    &_links {
        @media screen and (max-width: 768px) {
            display: none;
        }
        & ul {
            list-style-type: none;
            display: flex;
            margin: 0;
            padding: 0;
            align-items: center;
            @media screen and (max-width: 1024px) {
                font-size: 13px;
            }
            & li {
                padding: 0 20px;
                cursor: pointer;
                color: $greenBlack;
                /*@media screen and (max-width: 1200px) {*/
                /*    padding: 0 12px;*/
                /*}*/
                /*@media screen and (max-width: 1024px) {*/
                /*    font-size: 14px;*/
                /*    padding: 0 10px;*/
                /*}*/
                &:hover {
                    color: $mainGreen;
                    font-weight: 500;
                }
            }
        }
    }
    &_user-profile {
        width: 18px;
        height: 18px;
        background: url("~@/assets/images/profile_icon.svg") no-repeat center;
        background-size: contain;
        cursor: pointer;
        ::v-deep .dropdown-menu {
            min-width: 8rem;
            left: -120px !important;
            @media screen and (max-width: 1600px) {
                left: -50px !important;
            }
            @media screen and (max-width: 1200px) {
                padding: 20px 0;
            }
            @media screen and (max-width: 1024px) {
                width: 350px;
                left: -30px !important;
            }
            @media screen and (max-width: 1024px) {
                width: 300px;
            }
            @media screen and (max-width: 768px) {
                width: 250px;
                padding: 10px 25px;
            }
            @media screen and (max-width: 425px) {
                width: 200px;
                padding: 6px 20px;
            }
            & .log_in, .register {
                justify-content: center;
                @media screen and (max-width: 768px) {
                    width: 200px;
                }
                @media screen and (max-width: 425px) {
                    width: 160px;
                }
            }
            a {
                text-decoration: none;
                @media screen and (max-width: 768px) {
                    height: 48px;
                }
            }
        }
        ::v-deep ul {
            right: 0 !important;
            width: 400px;
            padding: 40px 75px;
            @media screen and (max-width: 425px) {
                width: 200px;
            }
        }

        ::v-deep li {
            /*height: 30px;*/
            display: flex;
            a {
                /*padding: 0;*/
                &::after {
                    background: transparent !important;
                    border-color: transparent;
                }
                &:focus-visible {
                    outline: none;
                }
            }
            & .log_in {
                & .dropdown-item {
                    background: $mainGreen;
                    color: $white;
                    @media screen and (max-width: 425px) {
                        height: 44px;
                        font-size: 16px;
                    }
                    &:active {
                        background: $mainGreen;
                    }
                }
            }
            & .register {
                & .dropdown-item {
                    background: $white;
                    color: $mainGreen;
                    @media screen and (max-width: 425px) {
                        height: 44px;
                        font-size: 16px;
                    }
                    &:active {
                        background: $white;
                    }
                }
            }
            .dropdown-item {
                width: 260px;
                height: 54px;
                font-size: 18px;
                font-weight: 600;
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $mainGreen;
                border-radius: 6px;
            }
            &::marker {
                display: none;
            }
        }
    }
}


$burgerWidth: 24px;
$burgerBarHeight: 1px;
$burgerBarColor: $mainGreen;

.burger-menu {
    width: $burgerWidth;
    height: $burgerWidth;
    cursor: pointer;
    margin-left: 30px;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }

    .burger-container {
        position: relative;
        .menu_content {
            position: absolute;
            width: 220px;
            height: 180px;
            background: #fff;
            box-shadow: 0 3px 6px #00000033;
            padding: 20px 0;
            top: 46px;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-50px);
            visibility: hidden;
            opacity: 0;
            transition: .4s ease-in-out;
            & ul {
                list-style: none;
                margin: 0 15px 5px;

                & li {
                    padding: 5px;
                    border-bottom: 1px solid #d1d1d18a;
                }
            }

            & .link_item {
                padding: 0 40px;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .show {
            transform: translateY(0px);
            visibility: visible;
            opacity: 1;
        }

        .burger-inner {
            position: absolute;
            width: $burgerWidth - 2 * $burgerBarHeight;
            left: 0px;
            top: 11px;
            border: $burgerBarHeight solid $burgerBarColor;
            transition: border-color .2s ease-in .2s;

            &::before {
                position: absolute;
                content: '';
                top: -6px;
                left: -$burgerBarHeight;
                width: $burgerWidth - 2 * $burgerBarHeight;
                border: $burgerBarHeight solid $burgerBarColor;
                transition: top .2s ease-in .3s, transform ease-in .2s;
            }

            &::after {
                position: absolute;
                content: '';
                top: 4px;
                left: -$burgerBarHeight;
                width: $burgerWidth - 2 * $burgerBarHeight;
                border: $burgerBarHeight solid $burgerBarColor;
                transition: top .2s ease-in .3s, transform ease-in .2s;
            }
        }
    }

    &.active .burger-container .burger-inner {
        border-color: transparent;
        transition: border-color ease-in .1s;
    }

    &.active .burger-container .burger-inner::before {
        top: -$burgerBarHeight;
        transform: rotate(-45deg);
        transition: top ease-out .2s, transform .2s ease-out .3s;
    }

    &.active .burger-container .burger-inner::after {
        top: -$burgerBarHeight;
        transform: rotate(45deg);
        transition: top ease-out .2s, transform .2s ease-out .3s;
    }
}

</style>