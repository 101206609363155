import { render, staticRenderFns } from "./PatientOverview.vue?vue&type=template&id=155c6408&scoped=true&"
import script from "./PatientOverview.vue?vue&type=script&lang=js&"
export * from "./PatientOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "155c6408",
  null
  
)

export default component.exports