<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_title">
                Patients
            </div>
            <b-button class="create_btn" @click="addPatient('add')" id="new_patient-btn">+ Add new patient</b-button>
        </div>
        <div class="page_content">
            <div class="table_container">
                <b-table responsive hover :items="all_patients" :fields="fields" >
                    <template v-slot:cell(patient)="data">
                        <div class="patient_info">
                            <div class="patient_img-empty">
                                <div class="patient_img-layer"></div>
                            </div>
                            <div class="patient_name-container">
                                <div class="patient_name">
                                    {{data.item.firstName}} {{data.item.lastName}}
                                </div>
                                <div class="patient_location">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.315" height="14.83" viewBox="0 0 12.315 14.83">
                                        <g id="map-pin_13_" data-name="map-pin (13)" transform="translate(0.5 0.5)">
                                            <path id="Path_6985" data-name="Path 6985" d="M14.315,6.658c0,4.4-5.658,8.172-5.658,8.172S3,11.058,3,6.658a5.658,5.658,0,0,1,11.315,0Z" transform="translate(-3 -1)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            <circle id="Ellipse_455" data-name="Ellipse 455" cx="2.458" cy="2.458" r="2.458" transform="translate(3.2 3.228)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                    {{data.item.country}}, {{data.item.city}}
                                </div>
                            </div>

                        </div>
                    </template>
                    <template v-slot:cell(date)="data">
                        <div class="">
                            {{getDate(data.item.created_at)}}
                        </div>
                    </template>
                    <template v-slot:cell(id)="data">
                        <div class="">
                            #{{data.item.id}}
                        </div>
                    </template>
<!--                    <template v-slot:cell(diagnosis)="data">-->
<!--                        <div class="">-->
<!--                            {{data.item.diagnose || '_'}}-->
<!--                        </div>-->
<!--                    </template>-->
                    <template v-slot:cell(phone)="data">
                        <div class="patient_phone">
                            <span>{{data.item.codePhoneNumber1}} &nbsp;{{data.item.phoneNumber1}}</span>
                            <span>{{data.item.codePhoneNumber2}} &nbsp;{{data.item.phoneNumber2}}</span>
                        </div>
                    </template>
                    <template v-slot:cell(age)="data">
                        <div class="">
                            {{getAge(data.item.dateOfBirth)}}
                        </div>
                    </template>
                    <template v-slot:cell(additional_contacts)="data">
                        <div class="patient_additional-info">
                            <span>{{data.item.fullName}}</span>
                            <span>{{data.item.codePhoneNumber}} &nbsp;{{data.item.phoneNumber}}</span>
                        </div>
                    </template>
                    <template v-slot:cell(action)="data">
                        <div class="d-flex justify-content-center">
                            <div class="view_button mx-2" id="patient_view-btn" @click="patientView(data.item)"></div>
                            <div class="edit_button mx-2" @click="patientEdit(data.item, 'edit')"></div>
                            <div class="trash_button mx-2" id="remove_patient-btn" @click="deletePatient(data.item)"></div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
<!--create patient modal start -->
        <b-modal ref="patient-modal" centered hide-header hide-footer hide-header-close>
            <div class="patient_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="patientModal()"></b-icon>
                <div class="d-flex">
                    <div class="patient_title">
                        {{patinetModalTitle}}
                    </div>
                </div>
                <div class="patient_subtitle">Basic information</div>
                <div class="input_content">
                    <div class="input_item">
                        <label>First Name</label>
                        <b-form-input
                                v-model="patientData.firstName"
                                type="text"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['firstName']" class="text_danger mt-2">{{patientErrors['firstName'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Last Name</label>
                        <b-form-input
                                v-model="patientData.lastName"
                                type="text"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['lastName']" class="text_danger mt-2">{{patientErrors['lastName'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Date of birth</label>
                        <b-form-input
                                v-model="patientData.dateOfBirth"
                                type="date"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['dateOfBirth']" class="text_danger mt-2">{{patientErrors['dateOfBirth'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Gender</label>
                        <b-form-radio-group
                                v-model="patientData.gender"
                                :options="gender_options"
                                class="radio_group"
                        ></b-form-radio-group>

                    </div>
                </div>
                <div class="patient_subtitle">Contact information</div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                v-model="patientData.email"
                                type="email"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['email']" class="text_danger mt-2">{{patientErrors['email'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Phone number 1</label>
                        <div class="phone_item">
<!--                            <vue-tel-input v-model="telephone"></vue-tel-input>-->
                            <div class="select_arrow">
                                <b-form-select
                                        v-model="patientData.codePhoneNumber1"
                                        :class="{'pad_r': patientData.codePhoneNumber1 ? patientData.codePhoneNumber1.length <= 2: ''}"
                                >
                                    <option
                                            v-for="(option, code) in countryCodes"
                                            :key="code"
                                            :value="option.code"
                                            :title="option.code || null"
                                    >
                                        {{option.code}}&nbsp; <span class="ml-2">&nbsp;{{option.name}}</span>
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
<!--                            <vue-country-code-->
<!--                                    ref="vcc"-->
<!--                                    @onSelect="onSelectPhone1"-->
<!--                                    disabledFetchingCountry-->
<!--                                    enabledCountryCode-->
<!--                            >-->
<!--                            </vue-country-code>-->
                            <b-form-input
                                    v-model="patientData.phoneNumber1"
                                    type="number"
                                    trim
                            ></b-form-input>
                        </div>

                        <span v-if="patientErrors['phoneNumber1']" class="text_danger mt-2">{{patientErrors['phoneNumber1'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Phone number 2</label>
                        <div class="phone_item">
                            <div class="select_arrow">
                                <b-form-select
                                        v-model="patientData.codePhoneNumber2"
                                        :class="{'pad_r': patientData.codePhoneNumber2 ? patientData.codePhoneNumber2.length <= 2: ''}"
                                >
                                    <option
                                            v-for="(option, code) in countryCodes"
                                            :key="code"
                                            :value="option.code"
                                            :title="option.code || null"
                                    >

                                        {{option.code}}&nbsp; <span>&nbsp;{{option.name}}</span>
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
<!--                            <vue-country-code-->
<!--                                    @onSelect="onSelectPhone2"-->
<!--                                    disabledFetchingCountry-->
<!--                                    enabledCountryCode-->
<!--                            >-->
<!--                            </vue-country-code>-->
                            <b-form-input
                                    v-model="patientData.phoneNumber2"
                                    type="number"
                                    trim
                            ></b-form-input>
                        </div>

                        <span v-if="patientErrors['phoneNumber2']" class="text_danger mt-2">{{patientErrors['phoneNumber2'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Country</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.country">
                                <option
                                        v-for="(option, id) in countries"
                                        :key="id"
                                        :value="option.name"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['country']" class="text_danger mt-2">{{patientErrors['country'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item long_input">
                        <label>City</label>
                        <b-form-input
                                v-model="patientData.city"
                                type="text"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['city']" class="text_danger mt-2">{{patientErrors['city'][0]}}</span>
                    </div>
                </div>
                <div class="patient_subtitle">Additional contacts</div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Full name</label>
                        <b-form-input
                                v-model="patientData.fullName"
                                type="text"
                                trim
                        ></b-form-input>
                        <!-- <span v-if="createDiscussionErrors['title']" class="text_danger mt-2">{{createDiscussionErrors['title'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Phone number</label>
                        <div class="phone_item">
                            <div class="select_arrow">
                                <b-form-select
                                        v-model="patientData.codePhoneNumber"
                                        :class="{'pad_r': patientData.codePhoneNumber ? patientData.codePhoneNumber.length <= 2: ''}"
                                >
                                    <option
                                            v-for="(option, code) in countryCodes"
                                            :key="code"
                                            :value="option.code"
                                            :title="option.code || null"
                                    >

                                        {{option.code}}&nbsp; <span class="ml-2">&nbsp;{{option.name}}</span>
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
<!--                            <vue-country-code-->
<!--                                @onSelect="onSelectPhone"-->
<!--                                disabledFetchingCountry-->
<!--                                enabledCountryCode-->
<!--                            >-->
<!--                            </vue-country-code>-->
                            <b-form-input
                                    v-model="patientData.phoneNumber"
                                    type="number"
                                    trim
                            ></b-form-input>
                            <!-- <span v-if="createDiscussionErrors['title']" class="text_danger mt-2">{{createDiscussionErrors['title'][0]}}</span>-->
                        </div>
                    </div>
                </div>
                <div class="patient_subtitle">Personal information</div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Height(cm)</label>
                        <b-form-input
                                v-model="patientData.height"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['height']" class="text_danger mt-2">{{patientErrors['height'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Weight(kg)</label>
                        <b-form-input
                                v-model="patientData.weight"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['weight']" class="text_danger mt-2">{{patientErrors['weight'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Blood pressure</label>
                        <div class="dual_inputs">
                            <div class="select_arrow">
                                <b-form-select v-model="patientData.bloodPressureMin">
                                    <option
                                            v-for="option in 300"
                                            :value="option"
                                            :title="option || null"
                                            v-if="option >= 50"
                                    >
                                        {{option}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
                            <div class="select_arrow">
                                <b-form-select v-model="patientData.bloodPressureMax">
                                    <option
                                            v-for="option in 300"
                                            :value="option"
                                            :title="option || null"
                                            v-if="option >= 50"
                                    >
                                        {{option}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                        <span v-if="patientErrors['bloodPressure']" class="text_danger mt-2">{{patientErrors['bloodPressure'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>RBS</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.RBS">
                                <option
                                        v-for="option in 40"
                                        :value="option"
                                        :title="option || null"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['RBS']" class="text_danger mt-2">{{patientErrors['RBS'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Temperature</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.temperature">
                                <option
                                        v-for="option in 45"
                                        :value="option"
                                        :title="option || null"
                                        v-if="option >= 20"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['temperature']" class="text_danger mt-2">{{patientErrors['temperature'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Heart rate</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.heartRate">
                                <option
                                        v-for="option in 300"
                                        :value="option"
                                        :title="option || null"
                                        v-if="option >= 20"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['heartRate']" class="text_danger mt-2">{{patientErrors['heartRate'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Waist circumference</label>
                        <b-form-input
                                v-model="patientData.waistCircumference"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['waistCircumference']" class="text_danger mt-2">{{patientErrors['waistCircumference'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Hip Circumference</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.hipCircumference">
                                <option
                                        v-for="option in 200"
                                        :value="option"
                                        :title="option || null"
                                        v-if="option >= 30"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['hipCircumference']" class="text_danger mt-2">{{patientErrors['hipCircumference'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Oxygen level</label>
                        <div class="select_arrow">
                            <b-form-select v-model="patientData.oxygenLevel">
                                <option
                                        v-for="option in 100"
                                        :value="option"
                                        :title="option || null"
                                        v-if="option >= 50"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                        <span v-if="patientErrors['oxygenLevel']" class="text_danger mt-2">{{patientErrors['oxygenLevel'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Physical activity (hours per week)</label>
                        <b-form-input
                                v-model="patientData.physicalActivity"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['physicalActivity']" class="text_danger mt-2">{{patientErrors['physicalActivity'][0]}}</span>
                    </div>
                </div>
                <div class="input_content">
                    <div class="input_item">
                        <label>Sleep (hours / day)</label>
                        <b-form-input
                                v-model="patientData.sleep"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['sleep']" class="text_danger mt-2">{{patientErrors['sleep'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Alcohol Consumption (units per week)</label>
                        <b-form-input
                                v-model="patientData.alcoholConsumption"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="patientErrors['alcoholConsumption']" class="text_danger mt-2">{{patientErrors['alcoholConsumption'][0]}}</span>
                    </div>
                </div>
                <div class="done_btn-container">
                    <b-button v-if="modalType ==='add'" class="done_btn" @click="savePatient()">Save</b-button>
                    <b-button v-if="modalType ==='edit'" class="done_btn" @click="updatePatient()">Update</b-button>
                </div>
            </div>
        </b-modal>
<!--create patient modal  end  -->

<!--Delete patient modal start-->
        <b-modal ref="delete_patient_modal" centered hide-header hide-footer hide-header-close>
            <div class="delete-modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deletePatientModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        You are about to delete this Patient
                    </div>
                    <div class="modal_text">
                        Do you want to proceed?
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="deletePatientModal()">Cancel</div>
                        <b-button class="done_btn ml-4" @click="patientDelete()">Delete</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
<!--Delete card modal end-->

<!--view patient modal start -->
        <b-modal ref="patient_view-modal" centered hide-header hide-footer hide-header-close>
            <div class="patient_view_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="patientViewModal()"></b-icon>
                <div class="d-flex" v-if="patientViewData">
                    <div class="patient_view-main_container">
                        <div class="patient_view-img_data">
                            <div class="patient_view-img"></div>
                            <div class="patient_view-info">
                                <div class="patient_view-title">
                                    {{patientViewData.firstName}} {{patientViewData.lastName}}
                                </div>
                                <div class="patient_view-date">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                        <g id="calendar_16_" data-name="calendar (16)" transform="translate(1 0.685)">
                                            <rect id="Rectangle_4089" data-name="Rectangle 4089" width="18" height="18" rx="2" transform="translate(0 2.315)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <line id="Line_122" data-name="Line 122" y2="4" transform="translate(13 0.315)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <line id="Line_123" data-name="Line 123" y2="4" transform="translate(5 0.315)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <line id="Line_124" data-name="Line 124" x2="18" transform="translate(0 8.315)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                    {{getLongDate(patientViewData.dateOfBirth)}}
                                </div>
                                <div class="patient_view-gender">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.838" height="22.861" viewBox="0 0 22.838 22.861">
                                        <g id="Group_6440" data-name="Group 6440" transform="translate(-798.317 -80.286)">
                                            <path id="Path_7006" data-name="Path 7006" d="M886.721,166.094l4.044-4.057c-.611,0-1.2.007-1.79,0a.769.769,0,0,1-.848-.763.739.739,0,0,1,.759-.751q1.9-.021,3.8,0a.721.721,0,0,1,.755.752q.022,1.9,0,3.8a.744.744,0,0,1-.75.762.757.757,0,0,1-.764-.8c-.013-.6,0-1.195,0-1.751l-4.014,4.022a5.34,5.34,0,1,1-1.195-1.216Zm-3.146.507a3.8,3.8,0,1,0,3.787,3.829A3.807,3.807,0,0,0,883.576,166.6Z" transform="translate(-72.302 -72.602)" fill="#ff7b69"/>
                                            <path id="Path_7007" data-name="Path 7007" d="M803.879,88.746a5.315,5.315,0,1,1,1.172,1.2l-2.843,2.834c.322.3.667.6.991.93a.768.768,0,1,1-1.063,1.088c-.327-.321-.636-.662-1-1.046-.486.5-.931.965-1.387,1.42-.42.42-.872.464-1.206.131s-.293-.786.126-1.206c.448-.448.9-.886,1.427-1.4-.357-.337-.693-.639-1.013-.959a.771.771,0,1,1,1.071-1.079c.315.312.612.641.885.928Zm.532-3.12a3.8,3.8,0,1,0,3.771-3.8A3.807,3.807,0,0,0,804.411,85.626Z" transform="translate(0 0)" fill="#ff7b69"/>
                                        </g>
                                    </svg>
                                    {{patientViewData.gender}}
                                </div>
                                <div class="patient_view-progress">
                                    <b-progress :value="progressValue" max="100" class="mb-3"></b-progress>
                                    <div class="patient_view-progress-text">Storage {{progressValue}}%</div>
                                </div>

                            </div>
                        </div>
                        <div class="patient_view-contact_info">
                            <div class="patient_view-contact_info-item dual_info_item">
                                <div class="info_item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.785" height="18" viewBox="0 0 22.785 18">
                                        <g id="mail_10_" data-name="mail (10)" transform="translate(-0.607 -3)">
                                            <path id="Path_7008" data-name="Path 7008" d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_7009" data-name="Path 7009" d="M22,6,12,13,2,6" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                    {{patientViewData.email}}
                                </div>
                                <div class="info_item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.89" height="21.93" viewBox="0 0 21.89 21.93">
                                        <path id="phone_11_" data-name="phone (11)" d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z" transform="translate(-1.111 -1)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </svg>
                                    +{{patientViewData.phoneNumber1}},&nbsp; +{{patientViewData.phoneNumber2}},
                                </div>
                            </div>
                            <div class="patient_view-contact_info-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 12.315 14.83">
                                    <g id="map-pin_13_" data-name="map-pin (13)" transform="translate(0.5 0.5)">
                                        <path id="Path_6985" data-name="Path 6985" d="M14.315,6.658c0,4.4-5.658,8.172-5.658,8.172S3,11.058,3,6.658a5.658,5.658,0,0,1,11.315,0Z" transform="translate(-3 -1)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        <circle id="Ellipse_455" data-name="Ellipse 455" cx="2.458" cy="2.458" r="2.458" transform="translate(3.2 3.228)" fill="none" stroke="#ff7b69" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </g>
                                </svg>
                                {{patientViewData.country}}, {{patientViewData.city}}
                            </div>
                        </div>
                        <div class="patient_view-data">
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Height</div>
                                    <div class="data_item-value">{{patientViewData.height}}cm</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Blood pressure</div>
                                    <div class="data_item-value">{{patientViewData.bloodPressureMin}}/{{patientViewData.bloodPressureMax}}</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Weight</div>
                                    <div class="data_item-value">{{patientViewData.weight}}kg</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">RBS</div>
                                    <div class="data_item-value">{{patientViewData.RBS}}</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Temperature</div>
                                    <div class="data_item-value">{{patientViewData.temperature}} &#8451;</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Heart rate</div>
                                    <div class="data_item-value">{{patientViewData.heartRate}}</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Waist circumference</div>
                                    <div class="data_item-value">{{patientViewData.waistCircumference}}cm</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Hip Circumference</div>
                                    <div class="data_item-value">{{patientViewData.hipCircumference}}cm</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Oxygen level</div>
                                    <div class="data_item-value">{{patientViewData.oxygenLevel}}</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Physical activity</div>
                                    <div class="data_item-value">{{patientViewData.physicalActivity}}</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">Sleep</div>
                                    <div class="data_item-value">{{patientViewData.sleep}}</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Alcohol Consumption</div>
                                    <div class="data_item-value">{{patientViewData.alcoholConsumption}} units</div>
                                </div>
                            </div>
                            <div class="patient_view-data_item">
                                <div class="data_item">
                                    <div class="data_item-key">BMI</div>
                                    <div class="data_item-value">{{patientViewData.BMI ||  '_'}}</div>
                                </div>
                                <div class="data_item">
                                    <div class="data_item-key">Waist - Hip Ratio</div>
                                    <div class="data_item-value">{{patientViewData.WHR || '_'}}</div>
                                </div>
                            </div>

                        </div>
                        <div class="patient_view-buttons">
                            <b-button class="patient_view-btn appointment" @click="setAppointment()">Set appointment</b-button>
                            <b-button class="patient_view-btn message">Send message</b-button>
                            <router-link :to="'/patientView/' + patientViewData.id">
                                <b-button class="patient_view-btn profile">View Profile</b-button>
                            </router-link>

                        </div>
                    </div>
                </div>
<!--                <div class="done_btn-container">-->
<!--                    <b-button class="done_btn" @click="savePatient()">Save</b-button>-->
<!--                </div>-->
            </div>
        </b-modal>
<!--view patient modal  end  -->

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import VueCountryCode from 'vue-country-code'
    export default {
        name: "Patients",
        data() {
            return {
                telephone: null,
                fields: [
                    {
                        key: 'patient',
                        label: 'Patient',
                    },
                    {
                        key: 'date',
                        label: 'Date',
                    },
                    {
                        key: 'id',
                        label: 'Id',
                    },
                    // {
                    //     key: 'diagnosis',
                    //     label: 'Diagnosis',
                    // },
                    {
                        key: 'phone',
                        label: 'Phone',
                    },
                    {
                        key: 'age',
                        label: 'Age',
                    },
                    {
                        key: 'additional_contacts',
                        label: 'Additional contacts',
                    },
                    {
                        key: 'action',
                        label: 'Action',
                    },

                ],
                all_patients: [],
                patientData: {
                    firstName: null,
                    lastName: null,
                    dateOfBirth: null,
                    gender: 'male',
                    email: null,
                    phoneNumber1: null,
                    phoneNumber2: null,
                    codePhoneNumber1: null,
                    codePhoneNumber2: null,
                    country: null,
                    city: null,
                    fullName: null,
                    phoneNumber: null,
                    codePhoneNumber: null,
                    // diagnose: null,
                    height: null,
                    weight: null,
                    bloodPressureMin: null,
                    bloodPressureMax: null,
                    RBS: null,
                    temperature: null,
                    heartRate: null,
                    waistCircumference: null,
                    hipCircumference: null,
                    oxygenLevel: null,
                    physicalActivity: null,
                    sleep: null,
                    alcoholConsumption: null,
                    id: null
                },
                countries: [
                    {"name":"Afghanistan","code":"AF"},
                    {"name":"Aland Islands","code":"AX"},
                    {"name":"Albania","code":"AL"},
                    {"name":"Algeria","code":"DZ"},
                    {"name":"American Samoa","code":"AS"},
                    {"name":"Andorra","code":"AD"},
                    {"name":"Angola","code":"AO"},
                    {"name":"Anguilla","code":"AI"},
                    {"name":"Antarctica","code":"AQ"},
                    {"name":"Antigua and Barbuda","code":"AG"},
                    {"name":"Argentina","code":"AR"},
                    {"name":"Armenia","code":"AM"},
                    {"name":"Aruba","code":"AW"},
                    {"name":"Australia","code":"AU"},
                    {"name":"Austria","code":"AT"},
                    {"name":"Azerbaijan","code":"AZ"},
                    {"name":"Bahamas","code":"BS"},
                    {"name":"Bahrain","code":"BH"},
                    {"name":"Bangladesh","code":"BD"},
                    {"name":"Barbados","code":"BB"},
                    {"name":"Belarus","code":"BY"},
                    {"name":"Belgium","code":"BE"},
                    {"name":"Belize","code":"BZ"},
                    {"name":"Benin","code":"BJ"},
                    {"name":"Bermuda","code":"BM"},
                    {"name":"Bhutan","code":"BT"},
                    {"name":"Bolivia","code":"BO"},
                    {"name":"Bonaire, Sint Eustatius and Saba","code":"BQ"},
                    {"name":"Bosnia and Herzegovina","code":"BA"},
                    {"name":"Botswana","code":"BW"},
                    {"name":"Bouvet Island","code":"BV"},
                    {"name":"Brazil","code":"BR"},
                    {"name":"British Indian Ocean Territory","code":"IO"},
                    {"name":"Brunei Darussalam","code":"BN"},
                    {"name":"Bulgaria","code":"BG"},
                    {"name":"Burkina Faso","code":"BF"},
                    {"name":"Burundi","code":"BI"},
                    {"name":"Cambodia","code":"KH"},
                    {"name":"Cameroon","code":"CM"},
                    {"name":"Canada","code":"CA"},
                    {"name":"Cape Verde","code":"CV"},
                    {"name":"Cayman Islands","code":"KY"},
                    {"name":"Central African Republic","code":"CF"},
                    {"name":"Chad","code":"TD"},
                    {"name":"Chile","code":"CL"},
                    {"name":"China","code":"CN"},
                    {"name":"Christmas Island","code":"CX"},
                    {"name":"Cocos (Keeling) Islands","code":"CC"},
                    {"name":"Colombia","code":"CO"},
                    {"name":"Comoros","code":"KM"},
                    {"name":"Congo","code":"CG"},
                    {"name":"Congo, Democratic Republic of the Congo","code":"CD"},
                    {"name":"Cook Islands","code":"CK"},
                    {"name":"Costa Rica","code":"CR"},
                    {"name":"Cote D'Ivoire","code":"CI"},
                    {"name":"Croatia","code":"HR"},
                    {"name":"Cuba","code":"CU"},
                    {"name":"Curacao","code":"CW"},
                    {"name":"Cyprus","code":"CY"},
                    {"name":"Czech Republic","code":"CZ"},
                    {"name":"Denmark","code":"DK"},
                    {"name":"Djibouti","code":"DJ"},
                    {"name":"Dominica","code":"DM"},
                    {"name":"Dominican Republic","code":"DO"},
                    {"name":"Ecuador","code":"EC"},
                    {"name":"Egypt","code":"EG"},
                    {"name":"El Salvador","code":"SV"},
                    {"name":"Equatorial Guinea","code":"GQ"},
                    {"name":"Eritrea","code":"ER"},
                    {"name":"Estonia","code":"EE"},
                    {"name":"Ethiopia","code":"ET"},
                    {"name":"Falkland Islands (Malvinas)","code":"FK"},
                    {"name":"Faroe Islands","code":"FO"},
                    {"name":"Fiji","code":"FJ"},
                    {"name":"Finland","code":"FI"},
                    {"name":"France","code":"FR"},
                    {"name":"French Guiana","code":"GF"},
                    {"name":"French Polynesia","code":"PF"},
                    {"name":"French Southern Territories","code":"TF"},
                    {"name":"Gabon","code":"GA"},
                    {"name":"Gambia","code":"GM"},
                    {"name":"Georgia","code":"GE"},
                    {"name":"Germany","code":"DE"},
                    {"name":"Ghana","code":"GH"},
                    {"name":"Gibraltar","code":"GI"},
                    {"name":"Greece","code":"GR"},
                    {"name":"Greenland","code":"GL"},
                    {"name":"Grenada","code":"GD"},
                    {"name":"Guadeloupe","code":"GP"},
                    {"name":"Guam","code":"GU"},
                    {"name":"Guatemala","code":"GT"},
                    {"name":"Guernsey","code":"GG"},
                    {"name":"Guinea","code":"GN"},
                    {"name":"Guinea-Bissau","code":"GW"},
                    {"name":"Guyana","code":"GY"},
                    {"name":"Haiti","code":"HT"},
                    {"name":"Heard Island and Mcdonald Islands","code":"HM"},
                    {"name":"Holy See (Vatican City State)","code":"VA"},
                    {"name":"Honduras","code":"HN"},
                    {"name":"Hong Kong","code":"HK"},
                    {"name":"Hungary","code":"HU"},
                    {"name":"Iceland","code":"IS"},
                    {"name":"India","code":"IN"},
                    {"name":"Indonesia","code":"ID"},
                    {"name":"Iran, Islamic Republic of","code":"IR"},
                    {"name":"Iraq","code":"IQ"},
                    {"name":"Ireland","code":"IE"},
                    {"name":"Isle of Man","code":"IM"},
                    {"name":"Israel","code":"IL"},
                    {"name":"Italy","code":"IT"},
                    {"name":"Jamaica","code":"JM"},
                    {"name":"Japan","code":"JP"},
                    {"name":"Jersey","code":"JE"},
                    {"name":"Jordan","code":"JO"},
                    {"name":"Kazakhstan","code":"KZ"},
                    {"name":"Kenya","code":"KE"},
                    {"name":"Kiribati","code":"KI"},
                    {"name":"Korea, Democratic People's Republic of","code":"KP"},
                    {"name":"Korea, Republic of","code":"KR"},
                    {"name":"Kosovo","code":"XK"},
                    {"name":"Kuwait","code":"KW"},
                    {"name":"Kyrgyzstan","code":"KG"},
                    {"name":"Lao People's Democratic Republic","code":"LA"},
                    {"name":"Latvia","code":"LV"},
                    {"name":"Lebanon","code":"LB"},
                    {"name":"Lesotho","code":"LS"},
                    {"name":"Liberia","code":"LR"},
                    {"name":"Libyan Arab Jamahiriya","code":"LY"},
                    {"name":"Liechtenstein","code":"LI"},
                    {"name":"Lithuania","code":"LT"},
                    {"name":"Luxembourg","code":"LU"},
                    {"name":"Macao","code":"MO"},
                    {"name":"Macedonia, the Former Yugoslav Republic of","code":"MK"},
                    {"name":"Madagascar","code":"MG"},
                    {"name":"Malawi","code":"MW"},
                    {"name":"Malaysia","code":"MY"},
                    {"name":"Maldives","code":"MV"},
                    {"name":"Mali","code":"ML"},
                    {"name":"Malta","code":"MT"},
                    {"name":"Marshall Islands","code":"MH"},
                    {"name":"Martinique","code":"MQ"},
                    {"name":"Mauritania","code":"MR"},
                    {"name":"Mauritius","code":"MU"},
                    {"name":"Mayotte","code":"YT"},
                    {"name":"Mexico","code":"MX"},
                    {"name":"Micronesia, Federated States of","code":"FM"},
                    {"name":"Moldova, Republic of","code":"MD"},
                    {"name":"Monaco","code":"MC"},
                    {"name":"Mongolia","code":"MN"},
                    {"name":"Montenegro","code":"ME"},
                    {"name":"Montserrat","code":"MS"},
                    {"name":"Morocco","code":"MA"},
                    {"name":"Mozambique","code":"MZ"},
                    {"name":"Myanmar","code":"MM"},
                    {"name":"Namibia","code":"NA"},
                    {"name":"Nauru","code":"NR"},
                    {"name":"Nepal","code":"NP"},
                    {"name":"Netherlands","code":"NL"},
                    {"name":"Netherlands Antilles","code":"AN"},
                    {"name":"New Caledonia","code":"NC"},
                    {"name":"New Zealand","code":"NZ"},
                    {"name":"Nicaragua","code":"NI"},
                    {"name":"Niger","code":"NE"},
                    {"name":"Nigeria","code":"NG"},
                    {"name":"Niue","code":"NU"},
                    {"name":"Norfolk Island","code":"NF"},
                    {"name":"Northern Mariana Islands","code":"MP"},
                    {"name":"Norway","code":"NO"},
                    {"name":"Oman","code":"OM"},
                    {"name":"Pakistan","code":"PK"},
                    {"name":"Palau","code":"PW"},
                    {"name":"Palestinian Territory, Occupied","code":"PS"},
                    {"name":"Panama","code":"PA"},
                    {"name":"Papua New Guinea","code":"PG"},
                    {"name":"Paraguay","code":"PY"},
                    {"name":"Peru","code":"PE"},
                    {"name":"Philippines","code":"PH"},
                    {"name":"Pitcairn","code":"PN"},
                    {"name":"Poland","code":"PL"},
                    {"name":"Portugal","code":"PT"},
                    {"name":"Puerto Rico","code":"PR"},
                    {"name":"Qatar","code":"QA"},
                    {"name":"Reunion","code":"RE"},
                    {"name":"Romania","code":"RO"},
                    {"name":"Russian Federation","code":"RU"},
                    {"name":"Rwanda","code":"RW"},
                    {"name":"Saint Barthelemy","code":"BL"},
                    {"name":"Saint Helena","code":"SH"},
                    {"name":"Saint Kitts and Nevis","code":"KN"},
                    {"name":"Saint Lucia","code":"LC"},
                    {"name":"Saint Martin","code":"MF"},
                    {"name":"Saint Pierre and Miquelon","code":"PM"},
                    {"name":"Saint Vincent and the Grenadines","code":"VC"},
                    {"name":"Samoa","code":"WS"},
                    {"name":"San Marino","code":"SM"},
                    {"name":"Sao Tome and Principe","code":"ST"},
                    {"name":"Saudi Arabia","code":"SA"},
                    {"name":"Senegal","code":"SN"},
                    {"name":"Serbia","code":"RS"},
                    {"name":"Serbia and Montenegro","code":"CS"},
                    {"name":"Seychelles","code":"SC"},
                    {"name":"Sierra Leone","code":"SL"},
                    {"name":"Singapore","code":"SG"},
                    {"name":"Sint Maarten","code":"SX"},
                    {"name":"Slovakia","code":"SK"},
                    {"name":"Slovenia","code":"SI"},
                    {"name":"Solomon Islands","code":"SB"},
                    {"name":"Somalia","code":"SO"},
                    {"name":"South Africa","code":"ZA"},
                    {"name":"South Georgia and the South Sandwich Islands","code":"GS"},
                    {"name":"South Sudan","code":"SS"},
                    {"name":"Spain","code":"ES"},
                    {"name":"Sri Lanka","code":"LK"},
                    {"name":"Sudan","code":"SD"},
                    {"name":"Suriname","code":"SR"},
                    {"name":"Svalbard and Jan Mayen","code":"SJ"},
                    {"name":"Swaziland","code":"SZ"},
                    {"name":"Sweden","code":"SE"},
                    {"name":"Switzerland","code":"CH"},
                    {"name":"Syrian Arab Republic","code":"SY"},
                    {"name":"Taiwan, Province of China","code":"TW"},
                    {"name":"Tajikistan","code":"TJ"},
                    {"name":"Tanzania, United Republic of","code":"TZ"},
                    {"name":"Thailand","code":"TH"},
                    {"name":"Timor-Leste","code":"TL"},
                    {"name":"Togo","code":"TG"},
                    {"name":"Tokelau","code":"TK"},
                    {"name":"Tonga","code":"TO"},
                    {"name":"Trinidad and Tobago","code":"TT"},
                    {"name":"Tunisia","code":"TN"},
                    {"name":"Turkey","code":"TR"},
                    {"name":"Turkmenistan","code":"TM"},
                    {"name":"Turks and Caicos Islands","code":"TC"},
                    {"name":"Tuvalu","code":"TV"},
                    {"name":"Uganda","code":"UG"},
                    {"name":"Ukraine","code":"UA"},
                    {"name":"United Arab Emirates","code":"AE"},
                    {"name":"United Kingdom","code":"GB"},
                    {"name":"United States","code":"US"},
                    {"name":"United States Minor Outlying Islands","code":"UM"},
                    {"name":"Uruguay","code":"UY"},
                    {"name":"Uzbekistan","code":"UZ"},
                    {"name":"Vanuatu","code":"VU"},
                    {"name":"Venezuela","code":"VE"},
                    {"name":"Viet Nam","code":"VN"},
                    {"name":"Virgin Islands, British","code":"VG"},
                    {"name":"Virgin Islands, U.s.","code":"VI"},
                    {"name":"Wallis and Futuna","code":"WF"},
                    {"name":"Western Sahara","code":"EH"},
                    {"name":"Yemen","code":"YE"},
                    {"name":"Zambia","code":"ZM"},
                    {"name":"Zimbabwe","code":"ZW"}
                ],
                countryCodes: [
                     {'name': 'ANDORRA','code': '376'} ,
                     {'name': 'UNITED ARAB EMIRATES','code': '971'} ,
                     {'name': 'AFGHANISTAN','code': '93'} ,
                     {'name': 'ANTIGUA AND BARBUDA','code': '1268'} ,
                     {'name': 'ANGUILLA','code': '1264'} ,
                     {'name': 'ALBANIA','code': '355'} ,
                     {'name': 'ARMENIA','code': '374'} ,
                     {'name': 'NETHERLANDS ANTILLES','code': '599'} ,
                     {'name': 'ANGOLA','code': '244'} ,
                     {'name': 'ANTARCTICA','code': '672'} ,
                     {'name': 'ARGENTINA','code': '54'} ,
                     {'name': 'AMERICAN SAMOA','code': '1684'} ,
                     {'name': 'AUSTRIA','code': '43'} ,
                     {'name': 'AUSTRALIA','code': '61'} ,
                     {'name': 'ARUBA','code': '297'} ,
                     {'name': 'AZERBAIJAN','code': '994'} ,
                     {'name': 'BOSNIA AND HERZEGOVINA','code': '387'} ,
                     {'name': 'BARBADOS','code': '1246'} ,
                     {'name': 'BANGLADESH','code': '880'} ,
                     {'name': 'BELGIUM','code': '32'} ,
                     {'name': 'BURKINA FASO','code': '226'} ,
                     {'name': 'BULGARIA','code': '359'} ,
                     {'name': 'BAHRAIN','code': '973'} ,
                     {'name': 'BURUNDI','code': '257'} ,
                     {'name': 'BENIN','code': '229'} ,
                     {'name': 'SAINT BARTHELEMY','code': '590'} ,
                     {'name': 'BERMUDA','code': '1441'} ,
                     {'name': 'BRUNEI DARUSSALAM','code': '673'} ,
                     {'name': 'BOLIVIA','code': '591'} ,
                     {'name': 'BRAZIL','code': '55'} ,
                     {'name': 'BAHAMAS','code': '1242'} ,
                     {'name': 'BHUTAN','code': '975'} ,
                     {'name': 'BOTSWANA','code': '267'} ,
                     {'name': 'BELARUS','code': '375'} ,
                     {'name': 'BELIZE','code': '501'} ,
                     {'name': 'CANADA','code': '1'} ,
                     {'name': 'COCOS (KEELING) ISLANDS','code': '61'} ,
                     {'name': 'CONGO, THE DEMOCRATIC REPUBLIC OF THE','code': '243'} ,
                     {'name': 'CENTRAL AFRICAN REPUBLIC','code': '236'} ,
                     {'name': 'CONGO','code': '242'} ,
                     {'name': 'SWITZERLAND','code': '41'} ,
                     {'name': 'COTE D IVOIRE','code': '225'} ,
                     {'name': 'COOK ISLANDS','code': '682'} ,
                     {'name': 'CHILE','code': '56'} ,
                     {'name': 'CAMEROON','code': '237'} ,
                     {'name': 'CHINA','code': '86'} ,
                     {'name': 'COLOMBIA','code': '57'} ,
                     {'name': 'COSTA RICA','code': '506'} ,
                     {'name': 'CUBA','code': '53'} ,
                     {'name': 'CAPE VERDE','code': '238'} ,
                     {'name': 'CHRISTMAS ISLAND','code': '61'} ,
                     {'name': 'CYPRUS','code': '357'} ,
                     {'name': 'CZECH REPUBLIC','code': '420'} ,
                     {'name': 'GERMANY','code': '49'} ,
                     {'name': 'DJIBOUTI','code': '253'} ,
                     {'name': 'DENMARK','code': '45'} ,
                     {'name': 'DOMINICA','code': '1767'} ,
                     {'name': 'DOMINICAN REPUBLIC','code': '1809'} ,
                     {'name': 'ALGERIA','code': '213'} ,
                     {'name': 'ECUADOR','code': '593'} ,
                     {'name': 'ESTONIA','code': '372'} ,
                     {'name': 'EGYPT','code': '20'} ,
                     {'name': 'ERITREA','code': '291'} ,
                     {'name': 'SPAIN','code': '34'} ,
                     {'name': 'ETHIOPIA','code': '251'} ,
                     {'name': 'FINLAND','code': '358'} ,
                     {'name': 'FIJI','code': '679'} ,
                     {'name': 'FALKLAND ISLANDS (MALVINAS)','code': '500'} ,
                     {'name': 'MICRONESIA, FEDERATED STATES OF','code': '691'} ,
                     {'name': 'FAROE ISLANDS','code': '298'} ,
                     {'name': 'FRANCE','code': '33'} ,
                     {'name': 'GABON','code': '241'} ,
                     {'name': 'UNITED KINGDOM','code': '44'} ,
                     {'name': 'GRENADA','code': '1473'} ,
                     {'name': 'GEORGIA','code': '995'} ,
                     {'name': 'GHANA','code': '233'} ,
                     {'name': 'GIBRALTAR','code': '350'} ,
                     {'name': 'GREENLAND','code': '299'} ,
                     {'name': 'GAMBIA','code': '220'} ,
                     {'name': 'GUINEA','code': '224'} ,
                     {'name': 'EQUATORIAL GUINEA','code': '240'} ,
                     {'name': 'GREECE','code': '30'} ,
                     {'name': 'GUATEMALA','code': '502'} ,
                     {'name': 'GUAM','code': '1671'} ,
                     {'name': 'GUINEA-BISSAU','code': '245'} ,
                     {'name': 'GUYANA','code': '592'} ,
                     {'name': 'HONG KONG','code': '852'} ,
                     {'name': 'HONDURAS','code': '504'} ,
                     {'name': 'CROATIA','code': '385'} ,
                     {'name': 'HAITI','code': '509'} ,
                     {'name': 'HUNGARY','code': '36'} ,
                     {'name': 'INDONESIA','code': '62'} ,
                     {'name': 'IRELAND','code': '353'} ,
                     {'name': 'ISRAEL','code': '972'} ,
                     {'name': 'ISLE OF MAN','code': '44'} ,
                     {'name': 'INDIA','code': '91'} ,
                     {'name': 'IRAQ','code': '964'} ,
                     {'name': 'IRAN, ISLAMIC REPUBLIC OF','code': '98'} ,
                     {'name': 'ICELAND','code': '354'} ,
                     {'name': 'ITALY','code': '39'} ,
                     {'name': 'JAMAICA','code': '1876'} ,
                     {'name': 'JORDAN','code': '962'} ,
                     {'name': 'JAPAN','code': '81'} ,
                     {'name': 'KENYA','code': '254'} ,
                     {'name': 'KYRGYZSTAN','code': '996'} ,
                     {'name': 'CAMBODIA','code': '855'} ,
                     {'name': 'KIRIBATI','code': '686'} ,
                     {'name': 'COMOROS','code': '269'} ,
                     {'name': 'SAINT KITTS AND NEVIS','code': '1869'} ,
                     {'name': 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF','code': '850'} ,
                     {'name': 'KOREA REPUBLIC OF','code': '82'} ,
                     {'name': 'KUWAIT','code': '965'} ,
                     {'name': 'CAYMAN ISLANDS','code': '1345'} ,
                     {'name': 'KAZAKSTAN','code': '7'} ,
                     {'name': 'LAO PEOPLES DEMOCRATIC REPUBLIC','code': '856'} ,
                     {'name': 'LEBANON','code': '961'} ,
                     {'name': 'SAINT LUCIA','code': '1758'} ,
                     {'name': 'LIECHTENSTEIN','code': '423'} ,
                     {'name': 'SRI LANKA','code': '94'} ,
                     {'name': 'LIBERIA','code': '231'} ,
                     {'name': 'LESOTHO','code': '266'} ,
                     {'name': 'LITHUANIA','code': '370'} ,
                     {'name': 'LUXEMBOURG','code': '352'} ,
                     {'name': 'LATVIA','code': '371'} ,
                     {'name': 'LIBYAN ARAB JAMAHIRIYA','code': '218'} ,
                     {'name': 'MOROCCO','code': '212'} ,
                     {'name': 'MONACO','code': '377'} ,
                     {'name': 'MOLDOVA, REPUBLIC OF','code': '373'} ,
                     {'name': 'MONTENEGRO','code': '382'} ,
                     {'name': 'SAINT MARTIN','code': '1599'} ,
                     {'name': 'MADAGASCAR','code': '261'} ,
                     {'name': 'MARSHALL ISLANDS','code': '692'} ,
                     {'name': 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF','code': '389'} ,
                     {'name': 'MALI','code': '223'} ,
                     {'name': 'MYANMAR','code': '95'} ,
                     {'name': 'MONGOLIA','code': '976'} ,
                     {'name': 'MACAU','code': '853'} ,
                     {'name': 'NORTHERN MARIANA ISLANDS','code': '1670'} ,
                     {'name': 'MAURITANIA','code': '222'} ,
                     {'name': 'MONTSERRAT','code': '1664'} ,
                     {'name': 'MALTA','code': '356'} ,
                     {'name': 'MAURITIUS','code': '230'} ,
                     {'name': 'MALDIVES','code': '960'} ,
                     {'name': 'MALAWI','code': '265'} ,
                     {'name': 'MEXICO','code': '52'} ,
                     {'name': 'MALAYSIA','code': '60'} ,
                     {'name': 'MOZAMBIQUE','code': '258'} ,
                     {'name': 'NAMIBIA','code': '264'} ,
                     {'name': 'NEW CALEDONIA','code': '687'} ,
                     {'name': 'NIGER','code': '227'} ,
                     {'name': 'NIGERIA','code': '234'} ,
                     {'name': 'NICARAGUA','code': '505'} ,
                     {'name': 'NETHERLANDS','code': '31'} ,
                     {'name': 'NORWAY','code': '47'} ,
                     {'name': 'NEPAL','code': '977'} ,
                     {'name': 'NAURU','code': '674'} ,
                     {'name': 'NIUE','code': '683'} ,
                     {'name': 'NEW ZEALAND','code': '64'} ,
                     {'name': 'OMAN','code': '968'} ,
                     {'name': 'PANAMA','code': '507'} ,
                     {'name': 'PERU','code': '51'} ,
                     {'name': 'FRENCH POLYNESIA','code': '689'} ,
                     {'name': 'PAPUA NEW GUINEA','code': '675'} ,
                     {'name': 'PHILIPPINES','code': '63'} ,
                     {'name': 'PAKISTAN','code': '92'} ,
                     {'name': 'POLAND','code': '48'} ,
                     {'name': 'SAINT PIERRE AND MIQUELON','code': '508'} ,
                     {'name': 'PITCAIRN','code': '870'} ,
                     {'name': 'PUERTO RICO','code': '1'} ,
                     {'name': 'PORTUGAL','code': '351'} ,
                     {'name': 'PALAU','code': '680'} ,
                     {'name': 'PARAGUAY','code': '595'} ,
                     {'name': 'QATAR','code': '974'} ,
                     {'name': 'ROMANIA','code': '40'} ,
                     {'name': 'SERBIA','code': '381'} ,
                     {'name': 'RUSSIAN FEDERATION','code': '7'} ,
                     {'name': 'RWANDA','code': '250'} ,
                     {'name': 'SAUDI ARABIA','code': '966'} ,
                     {'name': 'SOLOMON ISLANDS','code': '677'} ,
                     {'name': 'SEYCHELLES','code': '248'} ,
                     {'name': 'SUDAN','code': '249'} ,
                     {'name': 'SWEDEN','code': '46'} ,
                     {'name': 'SINGAPORE','code': '65'} ,
                     {'name': 'SAINT HELENA','code': '290'} ,
                     {'name': 'SLOVENIA','code': '386'} ,
                     {'name': 'SLOVAKIA','code': '421'} ,
                     {'name': 'SIERRA LEONE','code': '232'} ,
                     {'name': 'SAN MARINO','code': '378'} ,
                     {'name': 'SENEGAL','code': '221'} ,
                     {'name': 'SOMALIA','code': '252'} ,
                     {'name': 'SURINAME','code': '597'} ,
                     {'name': 'SAO TOME AND PRINCIPE','code': '239'} ,
                     {'name': 'EL SALVADOR','code': '503'} ,
                     {'name': 'SYRIAN ARAB REPUBLIC','code': '963'} ,
                     {'name': 'SWAZILAND','code': '268'} ,
                     {'name': 'TURKS AND CAICOS ISLANDS','code': '1649'} ,
                     {'name': 'CHAD','code': '235'} ,
                     {'name': 'TOGO','code': '228'} ,
                     {'name': 'THAILAND','code': '66'} ,
                     {'name': 'TAJIKISTAN','code': '992'} ,
                     {'name': 'TOKELAU','code': '690'} ,
                     {'name': 'TIMOR-LESTE','code': '670'} ,
                     {'name': 'TURKMENISTAN','code': '993'} ,
                     {'name': 'TUNISIA','code': '216'} ,
                     {'name': 'TONGA','code': '676'} ,
                     {'name': 'TURKEY','code': '90'} ,
                     {'name': 'TRINIDAD AND TOBAGO','code': '1868'} ,
                     {'name': 'TUVALU','code': '688'} ,
                     {'name': 'TAIWAN, PROVINCE OF CHINA','code': '886'} ,
                     {'name': 'TANZANIA, UNITED REPUBLIC OF','code': '255'} ,
                     {'name': 'UKRAINE','code': '380'} ,
                     {'name': 'UGANDA','code': '256'} ,
                     {'name': 'UNITED STATES','code': '1'} ,
                     {'name': 'URUGUAY','code': '598'} ,
                     {'name': 'UZBEKISTAN','code': '998'} ,
                     {'name': 'HOLY SEE (VATICAN CITY STATE)','code': '39'} ,
                     {'name': 'SAINT VINCENT AND THE GRENADINES','code': '1784'} ,
                     {'name': 'VENEZUELA','code': '58'} ,
                     {'name': 'VIRGIN ISLANDS, BRITISH','code': '1284'} ,
                     {'name': 'VIRGIN ISLANDS, U.S.','code': '1340'} ,
                     {'name': 'VIET NAM','code': '84'} ,
                     {'name': 'VANUATU','code': '678'} ,
                     {'name': 'WALLIS AND FUTUNA','code': '681'} ,
                     {'name': 'SAMOA','code': '685'} ,
                     {'name': 'KOSOVO','code': '381'} ,
                     {'name': 'YEMEN','code': '967'} ,
                     {'name': 'MAYOTTE','code': '262'} ,
                     {'name': 'SOUTH AFRICA','code': '27'} ,
                     {'name': 'ZAMBIA','code': '260'} ,
                     {'name': 'ZIMBABWE','code': '263'},
                ],
                gender_options: [
                    { text: 'Male', value: 'male' },
                    { text: 'Female', value: 'female' },
                ],
                removePatientId: null,
                patientErrors: [],
                patinetModalTitle: null,
                modalType: null,
                progressValue: 75,
                patientViewData: null,
            }
        },
        comments: {
            VueCountryCode
        },
        created() {
            this.getAllPatients()
            .then(res => {
                if(res.status === 200) {
                    this.all_patients = res.data
                }
            })
        },
        methods: {
            ...mapActions(['createPatient', 'getAllPatients', 'removePatient', 'patientUpdate']),
            // getDate(item) {
            //     return new Date(item * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            // },
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            },
            getLongDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "long"}).split(' ')[0]
                return d
            },
            getAge(birthday) {
                const millis = Date.now() - Date.parse(birthday);
                return new Date(millis).getFullYear() - 1970;
            },
            // onSelectPhone1({name, iso2, dialCode}) {
            //     console.log(name, 'name,', iso2, 'iso2,', dialCode)
            //     this.patientData.codePhoneNumber1 = +dialCode
            // },
            // onSelectPhone2({name, iso2, dialCode}) {
            //     console.log(name, 'name,', iso2, 'iso2,', dialCode)
            //     console.log(dialCode)
            //     this.patientData.codePhoneNumber2 = +dialCode
            // },
            // onSelectPhone({name, iso2, dialCode}) {
            //     console.log(dialCode)
            //     this.patientData.codePhoneNumber = +dialCode
            // },
            addPatient(type) {
                this.modalType = type
                this.patinetModalTitle = 'Add new patient'
                this.patientData.firstName = null
                this.patientData.lastName = null
                this.patientData.dateOfBirth = null
                this.patientData.gender = 'male'
                this.patientData.email = null
                this.patientData.phoneNumber1 = null
                this.codePhoneNumber1 = null
                this.patientData.phoneNumber2 = null
                this.codePhoneNumber2 = null
                this.patientData.country = null
                this.patientData.city = null
                this.patientData.fullName = null
                this.patientData.phoneNumber = null
                this.codePhoneNumber = null
                // this.patientData.diagnose = null
                this.patientData.height = null
                this.patientData.weight = null
                this.patientData.bloodPressureMin = null
                this.patientData.bloodPressureMax = null
                this.patientData.RBS = null
                this.patientData.temperature = null
                this.patientData.heartRate = null
                this.patientData.waistCircumference = null
                this.patientData.hipCircumference = null
                this.patientData.oxygenLevel = null
                this.patientData.physicalActivity = null
                this.patientData.sleep = null
                this.patientData.alcoholConsumption = null
                this.patientModal()
            },
            patientModal() {
                this.$refs['patient-modal'].toggle('#new_patient-btn')
            },
            savePatient() {
                this.createPatient(this.patientData)
                .then(res => {
                    if(res.status === 200) {
                        this.patientModal()
                        this.getAllPatients()
                            .then(res => {
                                if(res.status === 200) {
                                    this.all_patients = res.data
                                }
                            })
                    } else {
                        this.patientErrors = res.response.data.error
                    }
                })
            },
            updatePatient() {
                this.patientUpdate(this.patientData)
                .then(res => {
                    if(res.status === 200) {
                        this.patientModal()
                        this.getAllPatients()
                            .then(res => {
                                if(res.status === 200) {
                                    this.all_patients = res.data
                                }
                            })
                    }
                })
            },
            deletePatient(item) {
                this.removePatientId = item.id
                this.deletePatientModal()
            },
            deletePatientModal() {
                this.$refs['delete_patient_modal'].toggle('#remove_patient-btn')
            },
            patientDelete() {
                this.removePatient(this.removePatientId)
                    .then(res => {
                        if(res.status === 200) {
                            this.deletePatientModal()
                            this.getAllPatients()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.all_patients = res.data
                                    }
                                })
                        }
                    })
            },
            patientView(item) {
              this.patientViewData = item
              this.patientViewModal()
            },
            patientViewModal() {
                this.$refs['patient_view-modal'].toggle('#patient_view-btn')
            },
            patientEdit(item, type) {
                this.modalType = type
                this.patinetModalTitle = 'Edit patient data'
                this.patientData.firstName = item.firstName
                this.patientData.lastName = item.lastName
                this.patientData.dateOfBirth = item.dateOfBirth.substr(0, 10)
                this.patientData.gender = item.gender
                this.patientData.email = item.email
                this.patientData.phoneNumber1 = item.phoneNumber1
                this.patientData.codePhoneNumber1 = item.codePhoneNumber1
                this.patientData.phoneNumber2 = item.phoneNumber2
                this.patientData.codePhoneNumber2 = item.codePhoneNumber2
                this.patientData.country = item.country
                this.patientData.city = item.city
                this.patientData.fullName = item.fullName
                this.patientData.phoneNumber = item.phoneNumber
                this.patientData.codePhoneNumber = item.codePhoneNumber
                // this.patientData.diagnose = item.diagnose
                this.patientData.height = item.height
                this.patientData.weight = item.weight
                this.patientData.bloodPressureMin = item.bloodPressureMin
                this.patientData.bloodPressureMax = item.bloodPressureMax
                this.patientData.RBS = item.RBS
                this.patientData.temperature = item.temperature
                this.patientData.heartRate = item.heartRate
                this.patientData.waistCircumference = item.waistCircumference
                this.patientData.hipCircumference = item.hipCircumference
                this.patientData.oxygenLevel = item.oxygenLevel
                this.patientData.physicalActivity = item.physicalActivity
                this.patientData.sleep = item.sleep
                this.patientData.alcoholConsumption = item.alcoholConsumption
                this.patientData.id = item.id
                this.patientModal()
            },
            setAppointment() {
                this.$router.push('/schedule')
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .pad_r {
        padding-right: 47px !important;
    }
.page {
    &_container {
        background: #F8F8F8;
        padding: 30px;
        width: 100%;
        height: calc(100vh - 80px);

    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .create_btn {
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background: $mainOrange;
            color: $white;
            font-size: 15px;
            padding:  0 20px;
            border: none;
            @media screen and (max-width: 1024px) {
                height: 44px;
            }
            @media screen and (max-width: 425px) {
                font-size: 14px;
                height: 40px;
                padding: 0 15px;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }
    &_title {
        font-size: 20px;
        font-weight: 600;
        color: $black;
    }
    &_content {
        padding: 30px 0;
        & .table_container {
            font-size: 16px;
            font-weight: 600;
            ::v-deep .table {
                width: 100%;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
            ::v-deep thead {
                height: 62px;
                color: $mainGreen;
                font-weight: 500;
                background: transparent;
                tr {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    & th:first-child {
                        width: 300px;
                    }
                    & th:nth-child(2) {
                        width: 100px;
                    }
                    & th:nth-child(3) {
                        width: 50px;
                    }
                    /*& th:nth-child(4) {*/
                    /*    width: 120px;*/
                    /*}*/
                    & th:nth-child(4) {
                        width: 170px;
                    }
                    & th:nth-child(5) {
                        width: 60px;
                    }
                    & th:nth-child(6) {
                        width: 200px;
                    }
                    & th:last-child{
                        width: 120px;
                    }
                }
                th {
                    text-align: center;
                    border-bottom: none;
                    border-top: none;
                }
            }
            ::v-deep tbody {
                width: 100%;
                tr {
                    display: flex;
                    justify-content: space-between;
                    background: $white;
                    margin: 10px 0 !important;
                    box-shadow: 0 3px 13px #0000000F;
                    border-radius: 20px;
                    width: 100%;
                    height: 120px;
                    @media screen and (max-width: 768px) {
                        height: 100px;
                    }
                    td{
                        border-top: none;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 768px) {
                            padding: 5px 10px;
                        }
                    }
                    & td:nth-child(2) {
                        width: 100px;
                    }
                    & td:nth-child(3) {
                        width: 50px;
                    }
                    /*& td:nth-child(4) {*/
                    /*    width: 120px;*/
                    /*}*/
                    & td:nth-child(4) {
                        width: 170px;
                    }
                    & td:nth-child(5) {
                        width: 60px;
                    }
                    & td:nth-child(6) {
                        width: 200px;
                    }
                }
            }

            & .patient {
                &_info {
                    width: 300px;
                    display: flex;
                    align-items: center;
                }
                &_img {
                    &-empty {
                        background: url("~@/assets/images/user_empty_img.svg") no-repeat center;
                        background-size: cover !important;
                        border-radius: 50%;
                        width: 67px;
                        height: 67px;
                        position: relative;
                        @media screen and (max-width: 768px) {
                            width: 60px;
                            height: 60px;
                        }
                    }
                    &-layer {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 67px;
                        height: 67px;
                        background-size: cover !important;
                        @media screen and (max-width: 768px) {
                            width: 60px;
                            height: 60px;
                        }
                    }
                }
                &_name {
                    color: $black;
                    font-weight: 600;
                    &-container {
                        width: 230px;
                        height: 55px;
                        padding-left: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
                &_location {
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    line-height: 18px;
                    & svg {
                        margin-right: 5px;
                    }
                }
                &_phone {
                    display: flex;
                    flex-direction: column;
                }
                &_additional-info {
                    display: flex;
                    flex-direction: column;
                }
            }
            & .view_button {
                width: 22px;
                height: 16px;
                background: url("~@/assets/images/eye.svg") no-repeat center;
                background-size: contain;
                cursor: pointer;
            }
            & .edit_button {
                width: 20px;
                height: 20px;
                background: url("~@/assets/images/edit.svg") no-repeat center;
                background-size: contain;
                cursor: pointer;
            }
            & .trash_button {
                width: 16px;
                height: 20px;
                background: url("~@/assets/images/trash.svg") no-repeat center;
                background-size: contain;
                cursor: pointer;
            }
        }
    }
}
.patient {
    &_modal_content {
        width: 680px;
        position: relative;
        padding: 0 10px;
        @media screen and (max-width: 768px) {
            width: 340px;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #9E9E9E;
        }

        & .input_content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            & .input_item {
                padding: 5px 0;
                &.long_input {
                    width: 100%;
                    & input {
                        width: 100%;
                    }
                }
                & .phone_item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    & .arrow_down {
                        right: 20px !important;
                    }
                    & .vue-tel-input {
                        width: 314px;
                        height: 44px;
                        border: 1px solid #9E9E9E;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        overflow-y: scroll;
                        ::v-deep .vti__dropdown {
                            padding: 0 10px;
                        }
                        ::v-deep input {
                            border: none;
                            &:focus-visible {
                                outline: none;
                            }
                        }
                        ::v-deep ul {
                            background: #fff;
                            border: 1px solid #ccc;
                            & li {
                                display: flex;
                            }
                        }
                    }
                    & .select_arrow {
                        width: 90px;
                    }
                    & select {
                        width: 80px;
                        padding-right: 30px;
                    }
                    & .vue-country-select {
                        border-radius: 10px;
                        width: 90px;
                        &:focus-within {
                            box-shadow: none;
                            border-color: grey;
                        }
                        ::v-deep .dropdown:hover {
                            border-radius: 10px;
                        }
                    }
                    & input {
                        width: 225px;
                    }
                }
                & .dual_inputs {
                    width: 314px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    & .select_arrow {
                        width: 148px;
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 18px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg")  right no-repeat;
                        }
                    }
                    input[type=number] {
                        -moz-appearance: textfield !important;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none !important;
                        margin: 0 !important;
                    }

                    & select {
                        width: 148px;
                        height: 44px;
                        background: transparent 0 0 no-repeat padding-box;
                        border-radius: 10px;
                        border: 1px solid #9E9E9E;
                        @media screen and (max-width: 768px) {
                            font-size: 13px;
                        }
                        &:focus {
                            box-shadow: none;
                            outline: none;
                            border: 1px solid $mainGreen;
                        }
                    }
                }
                & .select_arrow {
                    position: relative;
                    & .arrow_down {
                        position: absolute;
                        top: 18px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg")  right no-repeat;
                    }
                }

                & label {
                    color: $mainGreen;
                    font-weight: 600;
                    font-size: 14px;
                }

                & input {
                    width: 314px;
                    height: 44px;
                    border: 1px solid #9E9E9E;
                    border-radius: 10px;
                    background: transparent 0 0 no-repeat padding-box;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border: 1px solid $mainGreen;
                    }
                }
                input[type=number] {
                    -moz-appearance: textfield !important;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none !important;
                    margin: 0 !important;
                }

                & select {
                    width: 314px;
                    height: 44px;
                    background: transparent 0 0 no-repeat padding-box;
                    border-radius: 10px;
                    border: 1px solid #9E9E9E;
                    @media screen and (max-width: 768px) {
                        font-size: 13px;
                    }
                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border: 1px solid $mainGreen;
                    }
                }
                & .radio_group {
                    width: 310px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    ::v-deep .custom-control-label {
                        color: $black;
                        font-weight: 500 !important;
                    }
                    ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                        border-color: $mainGreen;
                        background-color: $mainGreen;
                    }
                }
            }
        }

        & .done_btn-container {
            display: flex;
            align-items: center;
            padding: 20px 0 10px;

            & .done_btn {
                background: $mainOrange;
                border-radius: 6px;
                width: 100px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: 15px;
                font-weight: 600;
                border: none;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

    }

    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 600;
        color: $black;
        padding-bottom: 10px;
        border-bottom: 1px solid $lightGrey;
    }
    &_subtitle {
        color: $mainOrange;
        padding: 15px 0 10px;
    }
}
.patient_view {
    &_modal_content {
        position: relative;
        width: 680px;
        padding: 0 10px;
        @media screen and (max-width: 768px) {
            width: 340px;
        }
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #9E9E9E;
        }
    }
    &-main_container {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
    }
    &-img_data {
        padding: 15px 20px;
        display: flex;
        @media screen and (max-width: 768px) {
            padding: 15px 0;
            flex-direction: column;
        }
    }
    &-img {
        width: 147px;
        height: 147px;
        background: url("~@/assets/images/user_empty_img.svg") no-repeat center;
        background-size: contain;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 10px;
    }
    &-info {
        padding: 10px 24px;
    }
    &-title {
        font-size: 25px;
        color: $black;
        font-weight: 600;
    }
    &-date {
        padding: 7px 0;
        & svg {
            margin-right: 15px;
        }
    }
    &-gender {
        padding: 7px 0;
        text-transform: capitalize;
        & svg {
            margin-right: 15px;
        }
    }
    &-progress {
        padding-top: 10px;
        & .progress {
            margin-bottom: 0 !important;
        }
        &-text {
            color: $black;
            font-size: 10px;
            font-weight: 600;
            padding-top: 5px;
        }
    }
    &-contact_info {
        padding: 10px 30px;
        border-top: 1px dashed $mainGreen;
        border-bottom: 1px dashed $mainGreen;
        @media screen and (max-width: 768px) {
           padding: 10px;
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            color: $black;
            font-weight: 500;
            @media screen and (max-width: 425px) {
                padding: 7px 0;
            }
            &.dual_info_item {
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    padding: 0;
                    & .info_item {
                        padding: 10px 0;
                    }
                }
                @media screen and (max-width: 425px) {
                    & .info_item {
                        padding: 7px 0;
                    }
                }
            }
            & svg {
                margin-right: 20px;
            }
            & .info_item {
                width: 300px;
            }
        }
    }
    &-data {
        padding: 10px 30px;
        font-size: 14px;
        &_item {
            display: flex;
            align-items: center;
            padding: 6px 0;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                padding: 0;
            }
            & .data_item {
                display: flex;
                align-items: center;
                width: 300px;
                @media screen and (max-width: 768px) {
                    padding: 3px 0;
                }
                &-key {
                    width: 185px;
                    color: $mainGreen;
                    font-weight: 600;
                }
                &-value {
                    width: 100px;
                }
            }

        }
    }
    &-buttons {
        width: 540px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
        padding: 10px 0;
        & a {
            text-decoration: none;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 320px;
            padding: 0;
        }
    }
    &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: $white;
        border-radius: 6px;
        padding: 0 24px;
        height: 46px;
        border: none;
        @media screen and (max-width: 768px) {
            margin: 5px 0;
            height: 40px;
            padding: 0 20px;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &.appointment {
            background: $mainOrange;
        }
        &.message {
            background: $mainGreen;
        }
        &.profile {
            background: #DEDEDE;
            color: $grey;
        }
    }
}
::v-deep .progress {
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;
    height: 4px;
}
::v-deep .progress-bar {
    background: $mainGreen;
}
::v-deep .modal-content {
    border: none !important;
    width: 710px !important;
    @media screen and (max-width: 768px) {
        width: 370px !important;
    }
    & .modal-body {
        width: 710px !important;
        @media screen and (max-width: 768px) {
            width: 370px !important;
        }
    }
}
::v-deep .modal_content {
    border: none !important;
    width: 710px !important;
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
}
::v-deep .modal-dialog.modal-dialog-centered {
    @media screen and (max-width: 768px) {
        justify-content: center;
    }
}
/*modal start*/
::v-deep .delete-modal_content {
    border: none !important;
    width: 675px;
    padding: 0 10px;
    position: relative;
    @media screen and (max-width: 768px) {
        width: 340px;
    }
    ::v-deep .modal-body {
        width: 600px !important;
        @media screen and (max-width: 768px) {
            width: 370px;
        }
    }
    & .x_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $grey;
    }
    & .modal_title {
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        color: $mainGreen;
        border-bottom: 1px solid $lightBlue;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 600;
        }
        &.attachment {
            padding-left: 10px;
            color: $mainOrange;
        }
    }
    & .modal_text {
        font-size: 16px;
        color: $mainOrange;
        padding: 25px 0;
    }
    & .modal_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 0 30px;
        @media screen and (max-width: 768px) {
            padding: 15px 0;
        }
        & .cancel_btn {
            padding: 10px 0;
            cursor: pointer;
            background: $mainOrange;
            border-radius: 8px;
            width: 140px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 600;
            border: none;
            @media screen and (max-width: 768px) {
                width: 100px;
                height: 40px;
            }

        }
        & .done_btn {
            width: 140px;
            height: 48px;
            background: $mainGreen;
            border-radius: 8px;
            color: $white;
            font-weight: 600;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                width: 100px;
                height: 40px;
            }
        }
    }
    & .description_content {
        padding: 10px 0 5px;
        & .description_subtitle {
            font-weight: 500;
            font-size: 18px;
            padding: 0 15px;
            color: $mainOrange;

        }
        & .description_text {
            padding: 10px 20px 5px;
            border-bottom: 1px solid #e9e9e9;
        }

    }
}
/* modal end*/
</style>