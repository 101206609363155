import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {auth: false},
    component: () => import(/* webpackChunkName: "login" */ '../views/Login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {auth: false},
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/doctorOverview',
    name: 'DoctorOverview',
    component: () => import(/* webpackChunkName: "doctorOverview" */ '../views/Doctor/DoctorOverview'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/patientOverview',
    name: 'PatientOverview',
    component: () => import(/* webpackChunkName: "patientOverview" */ '../views/Patient/PatientOverview'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import(/* webpackChunkName: "patients" */ '../views/Doctor/Patients'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/consultation',
    name: 'Consultation',
    component: () => import(/* webpackChunkName: "consultation" */ '../views/Doctor/Consultation'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Doctor/Schedule'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Doctor/Settings'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/docProfile',
    name: 'DocProfile',
    component: () => import(/* webpackChunkName: "docProfile" */ '../views/Doctor/DocProfile'),
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/patientView/:id',
    name: 'PatientView',
    component: () => import(/* webpackChunkName: "patientView" */ '../views/Doctor/PatientView'),
    meta: {layout: 'dashboard', auth: true},
    props: true
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.auth  && !store.getters['accessToken']) {
    router.push({name: 'Login'})
  } else {
    next()

  }
})

export default router
