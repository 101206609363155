import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Dashboard from "./components/layoutes/Dashboard";
import Default from "./components/layoutes/Default";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuelidate from 'vuelidate'
import interceptorsSetup from '@/helpers/interceptors'
import VueApexCharts from 'vue-apexcharts'
import VueCompositionAPI from '@vue/composition-api'
import VueSlider from 'vue-slider-component'
import VueCountryCode from "vue-country-code";

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import VueTelInput from 'vue-tel-input'

import vuetify from "./plugins/vuetify"
import DaySpanVuetify from 'dayspan-vuetify'
import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'





Vue.use(VueTelInput)
Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#620d56'
  }
});

Vue.component('VueSlider', VueSlider);

Vue.use(VueCompositionAPI)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCountryCode);
Vue.component('dashboard-layout', Dashboard)
Vue.component('default-layout', Default)
window.alert = new Vue()
Vue.config.productionTip = false
interceptorsSetup()

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
