<template>
    <div class="page_container">
        <div class="page_head">
            <div class="page_title" v-if="patientData">
                Consultation > {{patientData.firstName+' '+ patientData.lastName}}
            </div>
            <div class="page_data-content" v-if="patientData">
                <div class="patient_card-container">
                    <div class="patient_card-img">
                    </div>
                    <div class="patient_card-user">
                        <h4 class="user_name">{{patientData.firstName+' '+ patientData.lastName}}</h4>
                        <p class="user_email">{{patientData.email}}</p>
                        <p class="user_id">#{{patientData.id}}</p>
                    </div>
                    <div class="patient_card-points">
                        <div class="patient_card-point_item">
                            <div class="point border_r">15</div>
                            <p>past</p>
                        </div>
                        <div class="patient_card-point_item">
                            <div class="point">2</div>
                            <p>upcoming</p>
                        </div>
                    </div>
                    <b-button class="send_btn">Send message</b-button>
                </div>

                <div class="patient_content">
                    <div class="patient_data">
                        <div class="patient_data-info">
                            <div class="patient_info-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                    <g id="calendar_16_" data-name="calendar (16)" transform="translate(1 0.685)">
                                        <rect id="Rectangle_4089" data-name="Rectangle 4089" width="18" height="18"
                                              rx="2" transform="translate(0 2.315)" fill="none" stroke="#ff7b69"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_122" data-name="Line 122" y2="4" transform="translate(13 0.315)"
                                              fill="none" stroke="#ff7b69" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_123" data-name="Line 123" y2="4" transform="translate(5 0.315)"
                                              fill="none" stroke="#ff7b69" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                        <line id="Line_124" data-name="Line 124" x2="18" transform="translate(0 8.315)"
                                              fill="none" stroke="#ff7b69" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                                <span>{{getBirthDay(patientData.dateOfBirth)}}</span>

                            </div>
                            <div class="patient_info-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.838" height="22.861"
                                     viewBox="0 0 22.838 22.861">
                                    <g id="Group_6440" data-name="Group 6440" transform="translate(-798.317 -80.286)">
                                        <path id="Path_7006" data-name="Path 7006"
                                              d="M886.721,166.094l4.044-4.057c-.611,0-1.2.007-1.79,0a.769.769,0,0,1-.848-.763.739.739,0,0,1,.759-.751q1.9-.021,3.8,0a.721.721,0,0,1,.755.752q.022,1.9,0,3.8a.744.744,0,0,1-.75.762.757.757,0,0,1-.764-.8c-.013-.6,0-1.195,0-1.751l-4.014,4.022a5.34,5.34,0,1,1-1.195-1.216Zm-3.146.507a3.8,3.8,0,1,0,3.787,3.829A3.807,3.807,0,0,0,883.576,166.6Z"
                                              transform="translate(-72.302 -72.602)" fill="#ff7b69"/>
                                        <path id="Path_7007" data-name="Path 7007"
                                              d="M803.879,88.746a5.315,5.315,0,1,1,1.172,1.2l-2.843,2.834c.322.3.667.6.991.93a.768.768,0,1,1-1.063,1.088c-.327-.321-.636-.662-1-1.046-.486.5-.931.965-1.387,1.42-.42.42-.872.464-1.206.131s-.293-.786.126-1.206c.448-.448.9-.886,1.427-1.4-.357-.337-.693-.639-1.013-.959a.771.771,0,1,1,1.071-1.079c.315.312.612.641.885.928Zm.532-3.12a3.8,3.8,0,1,0,3.771-3.8A3.807,3.807,0,0,0,804.411,85.626Z"
                                              transform="translate(0 0)" fill="#ff7b69"/>
                                    </g>
                                </svg>
                                <span style="text-transform: capitalize">{{patientData.gender}}</span>
                            </div>
                            <div class="patient_info-item">
                                Diagnosis
                                <span>{{patientData.diagnose || '_'}}</span>
                            </div>
                        </div>
                        <div class="patient_data-location">
                            <div class="patient_info-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.89" height="21.93"
                                     viewBox="0 0 21.89 21.93">
                                    <path id="phone_11_" data-name="phone (11)"
                                          d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z"
                                          transform="translate(-1.111 -1)" fill="none" stroke="#ff7b69"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </svg>
                                <span>+{{patientData.phoneNumber1}}</span>&nbsp;
                                <span>+{{patientData.phoneNumber2}}</span>
                            </div>

                            <div class="patient_info-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.315" height="21.83"
                                     viewBox="0 0 12.315 14.83">
                                    <g id="map-pin_13_" data-name="map-pin (13)" transform="translate(0.5 0.5)">
                                        <path id="Path_6985" data-name="Path 6985"
                                              d="M14.315,6.658c0,4.4-5.658,8.172-5.658,8.172S3,11.058,3,6.658a5.658,5.658,0,0,1,11.315,0Z"
                                              transform="translate(-3 -1)" fill="none" stroke="#ff7b69"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        <circle id="Ellipse_455" data-name="Ellipse 455" cx="2.458" cy="2.458" r="2.458"
                                                transform="translate(3.2 3.228)" fill="none" stroke="#ff7b69"
                                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                    </g>
                                </svg>
                                <span>{{patientData.country}}, {{patientData.city}}</span>
                            </div>
                            <div class="patient_info-item">
                                Additional contacts
                                <span>{{patientData.fullName || '_'}}</span> <span v-if="patientData.phoneNumber">+{{patientData.phoneNumber || '_'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="patient_data-content">
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Height</div>
                                <div class="data_item-value">{{patientData.height || '_'}}cm</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Blood pressure</div>
                                <div class="data_item-value">
                                    {{patientData.bloodPressureMin || '_'}}/{{patientData.bloodPressureMax || '_'}}
                                </div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Weight</div>
                                <div class="data_item-value">{{patientData.weight || '_'}}kg</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">RBS</div>
                                <div class="data_item-value">{{patientData.RBS || '_'}}</div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Temperature</div>
                                <div class="data_item-value">{{patientData.temperature || '_'}}&#8451;</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Heart rate</div>
                                <div class="data_item-value">{{patientData.heartRate || '_'}}</div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Waist circumference</div>
                                <div class="data_item-value">{{patientData.waistCircumference || '_'}}cm</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Hip Circumference</div>
                                <div class="data_item-value">{{patientData.hipCircumference || '_'}}cm</div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Oxygen level</div>
                                <div class="data_item-value">{{patientData.oxygenLevel || '_'}}</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Physical activity</div>
                                <div class="data_item-value">{{patientData.physicalActivity || '_'}}</div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">Sleep</div>
                                <div class="data_item-value">{{patientData.sleep || '_'}}</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Alcohol Consumption</div>
                                <div class="data_item-value">{{patientData.alcoholConsumption || '_'}}</div>
                            </div>
                        </div>
                        <div class="patient_dual-item">
                            <div class="data_item">
                                <div class="data_item-key">BMI</div>
                                <div class="data_item-value">{{patientData.BMI || '_'}}</div>
                            </div>
                            <div class="data_item">
                                <div class="data_item-key">Waist - Hip Ratio</div>
                                <div class="data_item-value">{{patientData.WHR || '_'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="patient_tabs-container">
                <div>
                    <b-card no-body>
                        <b-tabs pills>
                            <b-tab title="CONSULTATION NOTES" active>
                                <b-card-text>
                                    <div class="consultation_notes">
                                        <div class="consultation_notes-data" v-if="!consultation_tab">
                                            <div class="consultation_edit-btn"
                                                 @click="consultationTabEdit()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.061" height="21.828"
                                                     viewBox="0 0 22.061 21.828">
                                                    <path id="edit-2_8_" data-name="edit-2 (8)"
                                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                                          transform="translate(-1 -1.172)" fill="none" stroke="#9d9c9c"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="consultation_notes-head">
                                                <div class="head_item">
                                                    <div class="head_item-key">Date & time</div>
                                                    <div class="head_item-value" v-if="consultation_notes">
                                                        {{getDate(consultation_notes.date) || '_'}} at
                                                        {{consultation_notes.time || '_'}}
                                                    </div>
                                                </div>
                                                <div class="head_item">
                                                    <div class="head_item-key">Diagnosis</div>
                                                    <div class="head_item-value" v-if="consultation_notes">
                                                        {{consultation_notes.diagnosis}}
                                                    </div>
                                                </div>
                                                <div class="head_item">
                                                    <div class="head_item-key">Diagnosis Cod</div>
                                                    <div class="head_item-value" v-if="consultation_notes">
                                                        {{consultation_notes.diagnosisCode}}
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="consultation_notes-description" v-if="consultation_notes">
                                                {{consultation_notes.description}}</p>
                                        </div>
                                        <div class="consultation_notes-edit" v-if="consultation_tab">
                                            <div class="consultation_update-content">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>Date</label>
                                                        <b-form-input
                                                                v-model="consultationData.startDate"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>Diagnosis</label>
                                                        <b-form-input
                                                                v-model="consultationData.diagnose"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>

                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>Time</label>
                                                        <b-form-timepicker
                                                                v-model="consultationData.startHour"
                                                                locale="en"
                                                        ></b-form-timepicker>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>Diagnosis Cod</label>
                                                        <b-form-input
                                                                v-model="consultationData.diagnoseCode"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                                <b-form-textarea
                                                        v-model="consultationData.description"
                                                        rows="6"
                                                        max-rows="8"
                                                ></b-form-textarea>

                                            </div>
                                            <div class="consultation_update-btn">
                                                <b-button class="update_btn" @click="updateConsultation()">Update
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="INVOICE SUMMARY">
                                <b-card-text>
                                    <div class="invoice_content">
                                        <div class="invoice_data-content" v-if="!invoice_tab">
                                            <div class="invoice_edit-btn" @click="invoiceTabEdit()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.061" height="21.828"
                                                     viewBox="0 0 22.061 21.828">
                                                    <path id="edit-2_8_" data-name="edit-2 (8)"
                                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                                          transform="translate(-1 -1.172)" fill="none" stroke="#9d9c9c"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="invoice_data-head_title">
                                                CLIENT INVOICE GENERATION FORM - FOR CLIENTS ALREADY REGISTERED ON THE
                                                SYSTEM
                                            </div>
                                            <div class="invoice_data-head">
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">NAMES:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.names}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEMBERSHIP NO.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.membershipNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEDICAL AID:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.medicalAid}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">D.O.B.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.dob}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">SCHEME:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.scheme}}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="invoice_summary-content">
                                                <div class="invoice_summary-title">INVOICE SUMMARY</div>
                                                <div class="invoice_summary-container">
                                                    <div class="invoice_summary-item">
                                                        <div class="invoice_summary-item_title">SERVICES</div>
                                                        <div class="invoice_summary-item_text"
                                                             v-for="item in invoiceSummaryAddData.services">{{item}}
                                                        </div>
                                                    </div>
                                                    <div class="invoice_summary-item">
                                                        <div class="invoice_summary-item_title">MEDICAL LIST</div>
                                                        <div class="invoice_summary-item_text"
                                                             v-for="item in invoiceSummaryAddData.medicalList">{{item}}
                                                        </div>

                                                    </div>
                                                    <div class="invoice_summary-item">
                                                        <div class="invoice_summary-item_title">DIAGNOSTIC LIST</div>
                                                        <div class="invoice_summary-item_text"
                                                             v-for="item in invoiceSummaryAddData.diagnosticList">
                                                            {{item}}
                                                        </div>
                                                    </div>
                                                    <div class="invoice_summary-item">
                                                        <div class="invoice_summary-item_title">INVESTIGATION LIST</div>
                                                        <div class="invoice_summary-item_text"
                                                             v-for="item in invoiceSummaryAddData.investigationList">
                                                            {{item}}
                                                        </div>
                                                    </div>
                                                    <div class="invoice_summary-item">
                                                        <div class="invoice_summary-item_title">PROCEDURE LIST</div>
                                                        <div class="invoice_summary-item_text"
                                                             v-for="item in invoiceSummaryAddData.procedureList">
                                                            {{item}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice_signature-content">
                                                <div class="invoice_signature-item">
                                                    <div class="signature_item">
                                                        <div class="signature_item-title">DOCTOR'S SIGNATURE:</div>
                                                        <div
                                                                v-if="invoiceSummary"
                                                                class="signature_item-sign doctor"
                                                                :style="{ backgroundImage: 'url(' + invoiceSummary.doctorSignature + ')' }"
                                                        ></div>
                                                    </div>
                                                    <div class="signature_item">
                                                        <div class="signature_item-date">Date:</div>
                                                        <div class="date_value" v-if="invoiceSummary">
                                                            {{invoiceSummary.doctorSignatureDate}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="invoice_signature-item">
                                                    <div class="signature_item">
                                                        <div class="signature_item-title">CLIENT'S SIGNATURE:</div>
                                                        <div
                                                                v-if="invoiceSummary"
                                                                class="signature_item-sign doctor"
                                                                :style="{ backgroundImage: 'url(' + invoiceSummary.clientSignature + ')' }"
                                                        ></div>
                                                    </div>
                                                    <div class="signature_item">
                                                        <div class="signature_item-date">Date:</div>
                                                        <div class="date_value" v-if="invoiceSummary">
                                                            {{invoiceSummary.clientSignatureDate}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="invoice_edit-content" v-if="invoice_tab">
                                            <div class="invoice_edit-head_title">
                                                CLIENT INVOICE GENERATION FORM - FOR CLIENTS ALREADY REGISTERED ON THE
                                                SYSTEM
                                            </div>
                                            <div class="invoice_update">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>Names</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryAddData.name"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>MEMBERSHIP NO.:</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryAddData.membership"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>MEDICAL AID:</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryAddData.medicalAid"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                    <div class="input_item">
                                                        <label>D.O.B.:</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryAddData.dob"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>SCHEME:</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryAddData.scheme"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice_edit-title">
                                                INVOICE SUMMARY
                                            </div>
                                            <div class="invoice_update summary">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>SERVICES</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryData.services"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                        <b-button class="add_btn" @click="addInvoiceData('services')">
                                                            Add
                                                        </b-button>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>INVESTIGATION LIST</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryData.investigationList"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                        <b-button class="add_btn"
                                                                  @click="addInvoiceData('investigationList')">Add
                                                        </b-button>
                                                    </div>
                                                </div>
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>MEDICAL LIST</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryData.medicalList"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                        <b-button class="add_btn"
                                                                  @click="addInvoiceData('medicalList')">Add
                                                        </b-button>
                                                    </div>
                                                    <div class="input_item">
                                                        <label>PROCEDURE LIST</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryData.procedureList"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                        <b-button class="add_btn"
                                                                  @click="addInvoiceData('procedureList')">Add
                                                        </b-button>
                                                    </div>
                                                </div>
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>DIAGNOSTIC LIST</label>
                                                        <b-form-input
                                                                v-model="invoiceSummaryData.diagnosticList"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                        <b-button class="add_btn"
                                                                  @click="addInvoiceData('diagnosticList')">Add
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice_signature-content">
                                                <div class="invoice_signature-item">
                                                    <div class="signature_item">
                                                        <div class="signature_item-title">DOCTOR'S SIGNATURE:</div>
                                                        <div class="signature_item-sign">
                                                            <div class="item_signature">
                                                                <VueSignaturePad width="200px" height="120px"
                                                                                 ref="invoice_doctor"/>
                                                                <div class="signature_btn-content">
                                                                    <b-button class="signature_btn"
                                                                              @click="save('invoice_doctor')">Save
                                                                    </b-button>
                                                                    <b-button class="signature_btn"
                                                                              @click="undo('invoice_doctor')">Undo
                                                                    </b-button>
                                                                    <b-button class="signature_btn"
                                                                              @click="clear('invoice_doctor')">Clear
                                                                    </b-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="signature_item">
                                                        <div class="signature_item-date">Date:</div>
                                                        <div class="input_item">
                                                            <b-form-input
                                                                    v-model="invoiceSummaryAddData.doctorSignDate"
                                                                    type="date"
                                                                    trim
                                                            ></b-form-input>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="invoice_signature-item">
                                                    <div class="signature_item">
                                                        <div class="signature_item-title">CLIENT'S SIGNATURE:</div>
                                                        <div class="signature_item-sign">

                                                            <div class="item_signature">
                                                                <VueSignaturePad width="200px" height="120px"
                                                                                 ref="invoice_client"/>
                                                                <div class="signature_btn-content">
                                                                    <b-button class="signature_btn"
                                                                              @click="save('invoice_client')">Save
                                                                    </b-button>
                                                                    <b-button class="signature_btn"
                                                                              @click="undo('invoice_client')">Undo
                                                                    </b-button>
                                                                    <b-button class="signature_btn"
                                                                              @click="clear('invoice_client')">Clear
                                                                    </b-button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="signature_item">
                                                        <div class="signature_item-date">Date:</div>
                                                        <div class="input_item">
                                                            <b-form-input
                                                                    v-model="invoiceSummaryAddData.clientSignDate"
                                                                    type="date"
                                                                    trim
                                                            ></b-form-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="consultation_update-btn">
                                                <b-button class="update_btn" @click="updateInvoice()">Update
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="PRESCRIPTION FORM">
                                <b-card-text>
                                    <div class="prescription_content">
                                        <div class="prescription_data-content" v-if="!prescription_tab">
                                            <div class="prescription_edit-btn"
                                                 @click="prescriptionTabEdit()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.061" height="21.828"
                                                     viewBox="0 0 22.061 21.828">
                                                    <path id="edit-2_8_" data-name="edit-2 (8)"
                                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                                          transform="translate(-1 -1.172)" fill="none" stroke="#9d9c9c"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="prescription_data-head">
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">NAMES:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.names}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEMBERSHIP NO.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.membershipNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEDICAL AID:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.medicalAid}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">D.O.B.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.dob}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">SCHEME:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.scheme}}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="prescription_form-container">
                                                <div class="prescription_form-title">PRESCRIPTION</div>
                                                <p class="prescription_form-description" v-if="prescription_forms">
                                                    {{prescription_forms.description}}</p>

                                                <div class="prescription_form-sign">
                                                    <div class="invoice_signature-item">
                                                        <div class="signature_item">
                                                            <div class="signature_item-title">DOCTOR'S SIGNATURE:</div>
                                                            <div
                                                                    v-if="prescription_forms"
                                                                    class="signature_item-sign doctor"
                                                                    :style="{ backgroundImage: 'url(' + prescription_forms.doctorSignature + ')' }"
                                                            ></div>
                                                        </div>
                                                        <div class="signature_item">
                                                            <div class="signature_item-date">Date:</div>
                                                            <div class="date_value" v-if="prescription_forms">
                                                                {{prescription_forms.date}}
                                                            </div>
                                                        </div>
                                                        <div class="signature_item">
                                                            <div class="signature_item-date">OFFICIAL STAMP:</div>
                                                            <div
                                                                    v-if="prescription_forms"
                                                                    class="date_value stamp"
                                                                    :style="{ backgroundImage: 'url(' + prescription_forms.officialStamp + ')' }"
                                                            ></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div v-if="prescription_tab">
                                            <div class="prescription_update-content">
                                                <div class="prescription_update-title">
                                                    PRESCRIPTION
                                                </div>
                                                <div class="prescription_update-textarea">
                                                    <b-form-textarea
                                                            v-model="prescription.text"
                                                            placeholder="Enter something..."
                                                            rows="4"
                                                            max-rows="6"
                                                    ></b-form-textarea>
                                                </div>
                                                <div class="prescription_update-sign">
                                                    <div class="prescription_update-item">
                                                        <div class="prescription_update-title">DOCTOR'S SIGNATURE:</div>
                                                        <div class="item_signature">
                                                            <VueSignaturePad width="200px" height="120px"
                                                                             ref="prescription_doctor"/>
                                                            <div class="signature_btn-content">
                                                                <b-button class="signature_btn"
                                                                          @click="save('prescription_doctor')">Save
                                                                </b-button>
                                                                <b-button class="signature_btn"
                                                                          @click="undo('prescription_doctor')">Undo
                                                                </b-button>
                                                                <b-button class="signature_btn"
                                                                          @click="clear('prescription_doctor')">Clear
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="prescription_update-item">
                                                        <div class="prescription_update-title">Date:</div>
                                                        <div class="input_item">
                                                            <b-form-input
                                                                    v-model="prescription.date"
                                                                    type="date"
                                                                    trim
                                                            ></b-form-input>
                                                        </div>
                                                    </div>
                                                    <div class="prescription_update-item">
                                                        <div class="prescription_update-title">OFFICIAL STAMP:</div>
                                                        <div class="input_item">
                                                            <div
                                                                    class="stamp_img"
                                                                    :style="{ backgroundImage: 'url(' + prescription.stamp + ')' }"
                                                            ></div>
                                                            <b-form-file
                                                                    class="stamp_upload"
                                                                    @change="addStamp"
                                                            ></b-form-file>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="prescription_btn-container">
                                                    <b-button class="update_btn" @click="updatePrescription">Update
                                                    </b-button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="REFERRAL FORM">
                                <b-card-text>
                                    <div class="referral_content">
                                        <div class="referral_data-content" v-if="!referral_tab">
                                            <div class="referral_edit-btn"
                                                 @click="referralTabEdit()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.061" height="21.828"
                                                     viewBox="0 0 22.061 21.828">
                                                    <path id="edit-2_8_" data-name="edit-2 (8)"
                                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                                          transform="translate(-1 -1.172)" fill="none" stroke="#9d9c9c"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="referral_data-head">
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">NAMES:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.names}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEMBERSHIP NO.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.membershipNo}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">MEDICAL AID:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.medicalAid}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">D.O.B.:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.dob}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">SCHEME:</div>
                                                        <div class="head_item-value" v-if="invoiceSummary">
                                                            {{invoiceSummary.scheme}}
                                                        </div>
                                                    </div>
                                                    <div class="head_item">
                                                        <div class="head_item-key">REFFERED TO:</div>
                                                        <div class="head_item-value" v-if="referral_form">{{referral_form.refferedTo}}</div>
                                                    </div>
                                                </div>
                                                <div class="head_row">
                                                    <div class="head_item">
                                                        <div class="head_item-key">SERVICE:</div>
                                                        <div class="head_item-value" v-if="referral_form">{{referral_form.service}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="referral_cards">
                                                <div class="card_item">
                                                    <div class="card_item-title">REASON FOR REFFERAL</div>
                                                    <p v-if="referral_form">{{referral_form.reasenForRefferal}}</p>
                                                </div>
                                                <div class="card_item">
                                                    <div class="card_item-title">DIAGNOSIS</div>
                                                    <p v-if="referral_form">{{referral_form.diagnosis}}</p>
                                                </div>
                                                <div class="card_item">
                                                    <div class="card_item-title">TREATMENT GIVEN</div>
                                                    <p v-if="referral_form">{{referral_form.treatmentGiven}}</p>
                                                </div>
                                            </div>
                                            <div class="referral_form-container">
                                                <div class="referral_form-sign">
                                                    <div class="invoice_signature-item">
                                                        <div class="signature_item">
                                                            <div class="signature_item-title">DOCTOR'S SIGNATURE:</div>
                                                            <div
                                                                    v-if="referral_form"
                                                                    class="signature_item-sign doctor"
                                                                    :style="{ backgroundImage: 'url(' + referral_form.doctorSignature + ')' }"
                                                            ></div>
                                                        </div>
                                                        <div class="signature_item">
                                                            <div class="signature_item-date">Date:</div>
                                                            <div class="date_value" v-if="referral_form">
                                                                {{referral_form.date}}
                                                            </div>
                                                        </div>
                                                        <div class="signature_item">
                                                            <div class="signature_item-date">OFFICIAL STAMP:</div>
                                                            <div
                                                                    v-if="referral_form"
                                                                    class="date_value stamp"
                                                                    :style="{ backgroundImage: 'url(' + referral_form.officialStamp + ')' }"
                                                            ></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="referral_tab">
                                            <div class="referral_update">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>REFERRED TO:</label>
                                                        <b-form-input
                                                                v-model="referral.referred_to"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>SERVICE:</label>
                                                        <b-form-input
                                                                v-model="referral.service"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="referral_description-container">
                                                <div class="update-textarea">
                                                    <label>REASON FOR REFFERAL</label>
                                                    <b-form-textarea
                                                            v-model="referral.reason_referral"
                                                            placeholder="Enter something..."
                                                            rows="4"
                                                            max-rows="6"
                                                    ></b-form-textarea>
                                                </div>
                                                <div class="update-textarea">
                                                    <label>DIAGNOSIS</label>
                                                    <b-form-textarea
                                                            v-model="referral.diagnosis"
                                                            placeholder="Enter something..."
                                                            rows="4"
                                                            max-rows="6"
                                                    ></b-form-textarea>
                                                </div>
                                                <div class="update-textarea">
                                                    <label>TREATMENT GIVEN</label>
                                                    <b-form-textarea
                                                            v-model="referral.treatment_given"
                                                            placeholder="Enter something..."
                                                            rows="4"
                                                            max-rows="6"
                                                    ></b-form-textarea>
                                                </div>
                                            </div>
                                            <div class="prescription_update-sign">
                                                <div class="prescription_update-item">
                                                    <div class="prescription_update-title">DOCTOR'S SIGNATURE:</div>
                                                    <div class="item_signature">
                                                        <VueSignaturePad width="200px" height="120px"
                                                                         ref="referral_doctor"/>
                                                        <div class="signature_btn-content">
                                                            <b-button class="signature_btn"
                                                                      @click="save('referral_doctor')">Save
                                                            </b-button>
                                                            <b-button class="signature_btn"
                                                                      @click="undo('referral_doctor')">Undo
                                                            </b-button>
                                                            <b-button class="signature_btn"
                                                                      @click="clear('referral_doctor')">Clear
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="prescription_update-item">
                                                    <div class="prescription_update-title">Date:</div>
                                                    <div class="input_item">
                                                        <b-form-input
                                                                v-model="referral.date"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                                <div class="prescription_update-item">
                                                    <div class="prescription_update-title">OFFICIAL STAMP:</div>
                                                    <div class="input_item">
                                                        <div
                                                                class="stamp_img"
                                                                :style="{ backgroundImage: 'url(' + referral.stamp + ')' }"
                                                        ></div>
                                                        <b-form-file
                                                                class="stamp_upload"
                                                                @change="addReferralStamp"
                                                        ></b-form-file>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="prescription_btn-container">
                                                <b-button class="update_btn" @click="updateReferral">Update</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="FITNESS FORM">
                                <b-card-text>
                                    <div class="fitness_content">
                                        <div class="fitness_data-content" v-if="!fitness_tab">
                                            <div class="referral_edit-btn"
                                                 @click="fitnessTabEdit()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.061" height="21.828"
                                                     viewBox="0 0 22.061 21.828">
                                                    <path id="edit-2_8_" data-name="edit-2 (8)"
                                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                                          transform="translate(-1 -1.172)" fill="none" stroke="#9d9c9c"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="fitness_data-head">
                                                    <div class="head_row">
                                                        <div class="head_item">
                                                            <div class="head_item-key">NAMES:</div>
                                                            <div class="head_item-value" v-if="invoiceSummary">
                                                                {{invoiceSummary.names}}
                                                            </div>
                                                        </div>
                                                        <div class="head_item">
                                                            <div class="head_item-key">MEMBERSHIP NO.:</div>
                                                            <div class="head_item-value" v-if="invoiceSummary">
                                                                {{invoiceSummary.membershipNo}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="head_row">
                                                        <div class="head_item">
                                                            <div class="head_item-key">MEDICAL AID:</div>
                                                            <div class="head_item-value" v-if="invoiceSummary">
                                                                {{invoiceSummary.medicalAid}}
                                                            </div>
                                                        </div>
                                                        <div class="head_item">
                                                            <div class="head_item-key">D.O.B.:</div>
                                                            <div class="head_item-value" v-if="invoiceSummary">
                                                                {{invoiceSummary.dob}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="head_row">
                                                        <div class="head_item">
                                                            <div class="head_item-key">SCHEME:</div>
                                                            <div class="head_item-value" v-if="invoiceSummary">
                                                                {{invoiceSummary.scheme}}
                                                            </div>
                                                        </div>
                                                        <div class="head_item">
                                                            <div class="head_item-key">EMPLOYER:</div>
                                                            <div class="head_item-value" v-if="fitness_form">{{fitness_form.employer}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="head_row">
                                                        <div class="head_item">
                                                            <div class="head_item-key">OCCUPATION:</div>
                                                            <div class="head_item-value" v-if="fitness_form">{{fitness_form.occupation}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="fitness_content-subtitle">
                                                <div class="subtitle">Fitness Options</div>
                                                <div class="subtitle_value" v-if="fitness_form">{{getFitnessOption(fitness_form.fitnessOption)}}</div>
                                                <div v-else>_</div>
                                            </div>
                                            <div class="fitness_date-container">
                                                <div class="fitness_date-dual">
                                                    <div class="date_item">
                                                        <div class="date_subtitle">Work return date:</div>
                                                        <div class="date_subtitle-value" v-if="fitness_form">{{fitness_form.workReturnDate}}</div>
                                                    </div>
                                                    <div class="date_item">
                                                        <div class="date_subtitle">Number of days off:</div>
                                                        <div class="date_subtitle-value" v-if="fitness_form">{{fitness_form.numberOfDaysOff}}</div>
                                                    </div>
                                                </div>
                                                <div class="fitness_date-dual">
                                                    <div class="date_item">
                                                        <div class="date_subtitle">Review date:</div>
                                                        <div class="date_subtitle-value" v-if="fitness_form">{{fitness_form.reviewDate}}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="fitness_description" v-if="fitness_form">
                                                {{ fitness_form.description}}
                                            </div>
                                            <div class="fitness_form-container">
                                                <div class="fitness_form-sign">
                                                    <div class="invoice_signature-item">
                                                        <div class="signature_item">
                                                            <div class="signature_item-title">DOCTOR'S SIGNATURE:</div>
                                                            <div
                                                                    v-if="fitness_form"
                                                                    class="signature_item-sign doctor"
                                                                    :style="{ backgroundImage: 'url(' + fitness_form.doctorSignature + ')' }"
                                                            ></div>
                                                        </div>

                                                        <div class="signature_item">
                                                            <div class="signature_item-date">OFFICIAL STAMP:</div>
                                                            <div
                                                                    v-if="fitness_form"
                                                                    class="date_value stamp"
                                                                    :style="{ backgroundImage: 'url(' + fitness_form.officialStamp + ')' }"
                                                            ></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="fitness_tab">
                                            <div class="fitness_update">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>EMPLOYER:</label>
                                                        <b-form-input
                                                                v-model="fitness.employer"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>OCCUPATION:</label>
                                                        <b-form-input
                                                                v-model="fitness.occupation"
                                                                type="text"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="fitness_update-options">
                                                <b-form-radio-group
                                                        id="radio-group-1"
                                                        v-model="fitness.fitnessOption"
                                                        :options="fitnessOptions"
                                                        name="radio-options"
                                                ></b-form-radio-group>
                                            </div>
                                            <div class="fitness_update">
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>Work return date:</label>
                                                        <b-form-input
                                                                v-model="fitness.workReturnDate"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>

                                                    </div>
                                                    <div class="input_item">
                                                        <label>Number of days off:</label>
                                                        <b-form-input
                                                                v-model="fitness.numberOfDaysOff"
                                                                type="number"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                                <div class="dual_inputs">
                                                    <div class="input_item">
                                                        <label>Review date:</label>
                                                        <b-form-input
                                                                v-model="fitness.reviewDate"
                                                                type="date"
                                                                trim
                                                        ></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="fitness_update-textarea">
                                                <b-form-textarea
                                                        v-model="fitness.description"
                                                        placeholder="Enter something..."
                                                        rows="4"
                                                        max-rows="6"
                                                ></b-form-textarea>
                                            </div>
                                            <div class="prescription_update-sign">
                                                <div class="prescription_update-item">
                                                    <div class="prescription_update-title">DOCTOR'S SIGNATURE:</div>
                                                    <div class="item_signature">
                                                        <VueSignaturePad width="200px" height="120px"
                                                                         ref="fitness_doctor"/>
                                                        <div class="signature_btn-content">
                                                            <b-button class="signature_btn"
                                                                      @click="save('fitness_doctor')">Save
                                                            </b-button>
                                                            <b-button class="signature_btn"
                                                                      @click="undo('fitness_doctor')">Undo
                                                            </b-button>
                                                            <b-button class="signature_btn"
                                                                      @click="clear('fitness_doctor')">Clear
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="prescription_update-item">
                                                    <div class="prescription_update-title">OFFICIAL STAMP:</div>
                                                    <div class="input_item">
                                                        <div
                                                            class="stamp_img"
                                                            :style="{ backgroundImage: 'url(' + fitness.officialStamp + ')' }"
                                                        ></div>
                                                        <b-form-file
                                                                class="stamp_upload"
                                                                @change="addFitnessStamp"
                                                        ></b-form-file>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="prescription_btn-container">
                                                <b-button class="update_btn" @click="updateFitness()">Update</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "PatientView",
        data() {
            return {
                itemId: null,
                patientData: null,
                consultationData: {
                    startDate: null,
                    diagnose: null,
                    startHour: null,
                    diagnoseCode: null,
                    description: null,
                    patientId: null,
                    id: null
                },
                invoiceSummaryData: {
                    services: null,
                    investigationList: null,
                    medicalList: null,
                    procedureList: null,
                    diagnosticList: null
                },
                invoiceSummaryAddData: {
                    id: null,
                    patientId: null,
                    name: null,
                    membership: null,
                    medicalAid: null,
                    dob: null,
                    scheme: null,
                    services: [],
                    investigationList: [],
                    medicalList: [],
                    procedureList: [],
                    diagnosticList: [],
                    doctorSignDate: null,
                    clientSignDate: null,
                    invoiceDoctorSign: null,
                    invoiceClientSign: null
                },
                prescription: {
                    text: null,
                    signature: null,
                    date: null,
                    stamp: null,
                    patientId: null,
                    id: null
                },
                referral: {
                    patientId: null,
                    referred_to: null,
                    service: null,
                    reason_referral: null,
                    diagnosis: null,
                    treatment_given: null,
                    signature: null,
                    date: null,
                    stamp: null,
                    id: null
                },
                fitness: {
                    patientId: null,
                    occupation: null,
                    employer: null,
                    fitnessOption: null,
                    workReturnDate: null,
                    reviewDate: null,
                    numberOfDaysOff: null,
                    description: null,
                    doctorSignature: null,
                    officialStamp: null,
                    id: null
                },
                reader: new FileReader(),
                stampImg: null,
                referralStampImg: null,
                fitnessStampImg: null,
                consultation_tab: false,
                invoice_tab: false,
                prescription_tab: false,
                referral_tab: false,
                fitness_tab: false,
                consultation_notes: null,
                invoiceSummary: null,
                prescription_forms: null,
                referral_form: null,
                fitness_form: null,
                fitnessOptions: [
                    { text: 'FIt for duty', value: 0 },
                    { text: 'FIt for light duty', value: 1 },
                    { text: 'Unfit for duty', value: 2 }
                ]
            }
        },
        created() {
            this.itemId = this.$route.params.id
            this.getPatientDataById(this.itemId)
                .then(res => {
                    if (res.status === 200) {
                        this.patientData = res.data[0]
                    }
                })
            this.patientProfileGet(this.itemId)
                .then(res => {
                    if (res.status === 200) {
                        this.consultation_notes = res.data.response[0].consultation_notes
                        this.invoiceSummary = res.data.response[0].invoice_summary
                        this.prescription_forms = res.data.response[0].prescription_forms
                        this.referral_form = res.data.response[0].refferal_forms
                        this.fitness_form = res.data.response[0].fitness_forms
                    }
                })
        },
        mounted() {
            setTimeout(() => {
                if (this.invoiceSummary !== null && this.invoiceSummary.services !== '') {
                    this.invoiceSummaryAddData.services = this.invoiceSummary.services.split(',')
                } else {
                    this.invoiceSummaryAddData.services = []
                }
                if (this.invoiceSummary !== null && this.invoiceSummary.investigationList !== '') {
                    this.invoiceSummaryAddData.investigationList = this.invoiceSummary.investigationList.split(',')
                } else {
                    this.invoiceSummaryAddData.investigationList = []
                }
                if (this.invoiceSummary !== null && this.invoiceSummary.medicalList !== '') {
                    this.invoiceSummaryAddData.medicalList = this.invoiceSummary.medicalList.split(',')
                } else {
                    this.invoiceSummaryAddData.medicalList = []
                }
                if (this.invoiceSummary !== null && this.invoiceSummary.diagnosticList !== '') {
                    this.invoiceSummaryAddData.diagnosticList = this.invoiceSummary.diagnosticList.split(',')
                } else {
                    this.invoiceSummaryAddData.diagnosticList = []
                }
                if (this.invoiceSummary !== null && this.invoiceSummary.procedureList !== '') {
                    this.invoiceSummaryAddData.procedureList = this.invoiceSummary.procedureList.split(',')
                } else {
                    this.invoiceSummaryAddData.procedureList = []
                }
            }, 1000)
        },
        methods: {
            ...mapActions([
                'getPatientDataById',
                'patientProfileGet',
                'consultationEdit',
                'invoiceSummaryUpdate',
                'prescriptionUpdate',
                'referralUpdate',
                'fitnessUpdate'
            ]),
            getBirthDay(b_date) {
                return new Date(b_date).toDateString()
            },
            getDate(item) {
                if (item) {
                    let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                    return d
                }
            },
            consultationTabEdit() {
                this.consultation_tab = !this.consultation_tab
                this.consultationData.patientId = this.itemId
                if (this.consultation_notes) {
                    let date = new Date(this.consultation_notes.date)
                    let year = date.getFullYear()
                    let month = date.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month
                    }
                    let day = date.getDate()
                    let editDate = year + '-' + month + '-' + day
                    this.consultationData.startDate = editDate
                    this.consultationData.diagnose = this.consultation_notes.diagnosis
                    this.consultationData.startHour = this.consultation_notes.time
                    this.consultationData.diagnoseCode = this.consultation_notes.diagnosisCode
                    this.consultationData.description = this.consultation_notes.description
                    this.consultationData.id = this.consultation_notes.id
                }
            },
            updateConsultation() {
                if (this.consultationData.startDate) {
                    let date = new Date(this.consultationData.startDate).toISOString().substr(0, 10)
                    this.consultationData.startDate = date
                }
                this.consultationEdit(this.consultationData)
                    .then(res => {
                        if (res.status === 200) {
                            this.patientProfileGet(this.itemId)
                                .then(res => {
                                    if (res.status === 200) {
                                        this.consultation_notes = res.data.response[0].consultation_notes
                                    }
                                })
                        }
                    })
                this.consultation_tab = !this.consultation_tab
            },
            invoiceTabEdit() {
                this.invoice_tab = !this.invoice_tab
                this.invoiceSummaryAddData.patientId = this.itemId
                if (this.invoiceSummary) {
                    this.invoiceSummaryAddData.id = this.invoiceSummary.id
                    this.invoiceSummaryAddData.name = this.invoiceSummary.names
                    this.invoiceSummaryAddData.membership = this.invoiceSummary.membershipNo
                    this.invoiceSummaryAddData.medicalAid = this.invoiceSummary.medicalAid
                    this.invoiceSummaryAddData.scheme = this.invoiceSummary.scheme
                    this.invoiceSummaryAddData.dob = this.invoiceSummary.dob
                    if (this.invoiceSummary.services !== '') {
                        this.invoiceSummaryAddData.services = this.invoiceSummary.services.split(',')
                    } else {
                        this.invoiceSummaryAddData.services = []
                    }
                    if (this.invoiceSummary.investigationList !== '') {
                        this.invoiceSummaryAddData.investigationList = this.invoiceSummary.investigationList.split(',')
                    } else {
                        this.invoiceSummaryAddData.investigationList = []
                    }
                    if (this.invoiceSummary.medicalList !== '') {
                        this.invoiceSummaryAddData.medicalList = this.invoiceSummary.medicalList.split(',')
                    } else {
                        this.invoiceSummaryAddData.medicalList = []
                    }
                    if (this.invoiceSummary.diagnosticList !== '') {
                        this.invoiceSummaryAddData.diagnosticList = this.invoiceSummary.diagnosticList.split(',')
                    } else {
                        this.invoiceSummaryAddData.diagnosticList = []
                    }
                    if (this.invoiceSummary.procedureList !== '') {
                        this.invoiceSummaryAddData.procedureList = this.invoiceSummary.procedureList.split(',')
                    } else {
                        this.invoiceSummaryAddData.procedureList = []
                    }
                    this.invoiceSummaryAddData.invoiceDoctorSign = this.invoiceSummary.doctorSignature
                    this.invoiceSummaryAddData.invoiceClientSign = this.invoiceSummary.clientSignature
                    this.invoiceSummaryAddData.doctorSignDate = this.invoiceSummary.doctorSignatureDate
                    this.invoiceSummaryAddData.clientSignDate = this.invoiceSummary.clientSignatureDate
                }
            },
            updateInvoice() {
                this.invoiceSummaryUpdate(this.invoiceSummaryAddData)
                    .then(res => {
                        if (res.status === 200) {
                            this.invoice_tab = !this.invoice_tab
                            this.patientProfileGet(this.itemId)
                                .then(res => {
                                    if (res.status === 200) {
                                        this.invoiceSummary = res.data.response[0].invoice_summary
                                    }
                                })
                        }
                    })
            },
            prescriptionTabEdit() {
                this.prescription_tab = !this.prescription_tab
                this.prescription.patientId = this.itemId
                if (this.prescription_forms) {
                    this.prescription.text = this.prescription_forms.description
                    this.prescription.signature = this.prescription_forms.doctorSignature
                    this.prescription.date = this.prescription_forms.date
                    this.prescription.stamp = this.prescription_forms.officialStamp
                    this.prescription.id = this.prescription_forms.id
                }
                // setTimeout(() => {
                //     this.$refs.signaturePad.resizeCanvas();
                // }, 500)
            },
            updatePrescription() {
                this.prescriptionUpdate(this.prescription)
                    .then(res => {
                        if (res.status === 200) {
                            this.patientProfileGet(this.itemId)
                                .then(res => {
                                    if (res.status === 200) {
                                        this.prescription_forms = res.data.response[0].prescription_forms
                                    }
                                })
                        }
                    })
                this.prescription_tab = !this.prescription_tab
            },
            referralTabEdit() {
                this.referral_tab = !this.referral_tab
                this.referral.patientId = this.itemId
                if (this.referral_form) {
                    this.referral.referred_to = this.referral_form.refferedTo
                    this.referral.service = this.referral_form.service
                    this.referral.reason_referral = this.referral_form.reasenForRefferal
                    this.referral.diagnosis = this.referral_form.diagnosis
                    this.referral.treatment_given = this.referral_form.treatmentGiven
                    this.referral.signature = this.referral_form.doctorSignature
                    this.referral.date = this.referral_form.date
                    this.referral.stamp = this.referral_form.officialStamp
                    this.referral.id = this.referral_form.id
                }
            },
            updateReferral() {
                this.referralUpdate(this.referral)
                    .then(res => {
                        if (res.status === 200) {
                            this.patientProfileGet(this.itemId)
                                .then(res => {
                                    if (res.status === 200) {
                                        this.referral_form = res.data.response[0].refferal_forms
                                    }
                                })
                        }
                    })
                this.referral_tab = !this.referral_tab

            },
            fitnessTabEdit() {
                this.fitness_tab = !this.fitness_tab
                this.fitness.patientId = this.itemId
                if(this.fitness_form) {
                    this.fitness.occupation = this.fitness_form.occupation
                    this.fitness.employer = this.fitness_form.employer
                    this.fitness.fitnessOption = this.fitness_form.fitnessOption
                    this.fitness.workReturnDate = this.fitness_form.workReturnDate
                    this.fitness.reviewDate = this.fitness_form.reviewDate
                    this.fitness.numberOfDaysOff = this.fitness_form.numberOfDaysOff
                    this.fitness.description = this.fitness_form.description
                    this.fitness.doctorSignature = this.fitness_form.doctorSignature
                    this.fitness.officialStamp = this.fitness_form.officialStamp
                    this.fitness.id = this.fitness_form.id
                }
            },
            updateFitness() {
                this.fitnessUpdate(this.fitness)
                .then(res => {
                    if(res.status === 200) {
                        this.patientProfileGet(this.itemId)
                            .then(res => {
                                if (res.status === 200) {
                                    this.fitness_form = res.data.response[0].fitness_forms
                                }
                            })
                    }
                })
                this.fitness_tab = !this.fitness_tab
            },

            addStamp(event) {
                this.stampImg = event.target.files[0]
                this.createImage(this.stampImg)
            },
            addReferralStamp(event) {
                this.referralStampImg = event.target.files[0]
                this.createRefImage(this.referralStampImg)
            },
            addFitnessStamp(event) {
                this.fitnessStampImg = event.target.files[0]
                this.createFitImage(this.fitnessStampImg)
            },
            createImage(file) {
                this.reader.onload = event => {
                    this.prescription.stamp = event.target.result
                }
                this.reader.readAsDataURL(file)
            },
            createRefImage(file) {
                this.reader.onload = event => {
                    this.referral.stamp = event.target.result
                }
                this.reader.readAsDataURL(file)
            },
            createFitImage(file) {
                this.reader.onload = event => {
                    this.fitness.officialStamp = event.target.result
                }
                this.reader.readAsDataURL(file)
            },
            addInvoiceData(type) {
                switch (type) {
                    case 'services':
                        this.invoiceSummaryAddData.services.push(this.invoiceSummaryData.services)
                        this.invoiceSummaryData.services = null
                        break;
                    case 'investigationList':
                        this.invoiceSummaryAddData.investigationList.push(this.invoiceSummaryData.investigationList)
                        this.invoiceSummaryData.investigationList = null
                        break;
                    case 'medicalList':
                        this.invoiceSummaryAddData.medicalList.push(this.invoiceSummaryData.medicalList)
                        this.invoiceSummaryData.medicalList = null
                        break;
                    case 'procedureList':
                        this.invoiceSummaryAddData.procedureList.push(this.invoiceSummaryData.procedureList)
                        this.invoiceSummaryData.procedureList = null
                        break;
                    case 'diagnosticList':
                        this.invoiceSummaryAddData.diagnosticList.push(this.invoiceSummaryData.diagnosticList)
                        this.invoiceSummaryData.diagnosticList = null
                        break;
                    default:
                        console.log(`add`);
                }
            },
            undo(item) {
                if (item === 'invoice_doctor') {
                    this.$refs.invoice_doctor.undoSignature();
                } else if (item === 'invoice_client') {
                    this.$refs.invoice_client.undoSignature();
                } else if (item === 'prescription_doctor') {
                    this.$refs.prescription_doctor.undoSignature();
                } else if(item === 'referral_doctor') {
                    this.$refs.referral_doctor.undoSignature();
                } else if (item === 'fitness_doctor') {
                    this.$refs.fitness_doctor.undoSignature();
                }

            },
            clear(item) {
                if (item === 'invoice_doctor') {
                    this.$refs.invoice_doctor.clearSignature();
                } else if (item === 'invoice_client') {
                    this.$refs.invoice_client.clearSignature();
                } else if (item === 'prescription_doctor') {
                    this.$refs.prescription_doctor.clearSignature();
                } else if (item === 'referral_doctor') {
                    this.$refs.referral_doctor.clearSignature();
                } else if (item === 'fitness_doctor') {
                    this.$refs.fitness_doctor.clearSignature();
                }
            },
            save(item) {
                if (item === 'invoice_doctor') {
                    let invoice_doc_sign = this.$refs.invoice_doctor.saveSignature();
                    this.invoiceSummaryAddData.invoiceDoctorSign = invoice_doc_sign.data
                    this.$refs.invoice_doctor.$el.style.background = '#69f19a'
                } else if (item === 'invoice_client') {
                    let invoice_client_sign = this.$refs.invoice_client.saveSignature();
                    this.invoiceSummaryAddData.invoiceClientSign = invoice_client_sign.data
                    this.$refs.invoice_client.$el.style.background = '#69f19a'
                } else if (item === 'prescription_doctor') {
                    let prescription_doc_sign = this.$refs.prescription_doctor.saveSignature();
                    this.prescription.signature = prescription_doc_sign.data
                    this.$refs.prescription_doctor.$el.style.background = '#69f19a'
                } else if (item === 'referral_doctor') {
                    let referral_doc_sign = this.$refs.referral_doctor.saveSignature();
                    this.referral.signature = referral_doc_sign.data
                    this.$refs.referral_doctor.$el.style.background = '#69f19a'
                } else if (item === 'fitness_doctor') {
                    let fitness_doc_sign = this.$refs.fitness_doctor.saveSignature();
                    this.fitness.doctorSignature = fitness_doc_sign.data
                    this.$refs.fitness_doctor.$el.style.background = '#69f19a'
                }
            },
            getFitnessOption(item) {
                if(item == 0) {
                    return "FIt for duty"
                } else if (item == 1) {
                    return 'FIt for light duty'
                } else if(item == 2) {
                    return 'Unfit for duty'
                }
            }

        }
    }
</script>

<style scoped lang="scss">
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
}
.page {
    &_container {
        background: #F8F8F8;
        padding: 30px;
        width: 100%;
        .patient {
            &_tabs {
                &-container {
                    display: flex;
                    width: 100%;
                    background: $white;
                    border-radius: 12px;
                    padding: 30px 40px;
                    ::v-deep .tabs {
                        & .tab-content {
                            width: calc(100vw - 460px);
                            padding: 28px 0 10px;
                        }
                    }
                    & .card {
                        border: none;
                        background: transparent;

                        ::v-deep ul {
                            border-radius: 22px;
                            @media screen and (max-width: 1024px) {
                                width: 500px;
                            }
                            @media screen and (max-width: 425px) {
                                max-width: 390px;
                                width: 100%;
                                justify-content: center;
                                flex-direction: column;
                            }

                            li:first-child {
                                box-shadow: 0 3px 6px #00000017;
                                border-radius: 22px 0 0 22px;

                                a {
                                    border-radius: 22px 0 0 22px;
                                    @media screen and (max-width: 1024px) {
                                        border-radius: 22px;
                                    }
                                }
                            }

                            li:last-child {
                                box-shadow: 0 3px 6px #00000017;
                                border-radius: 0 22px 22px 0;

                                a {
                                    border-radius: 0 22px 22px 0;
                                    @media screen and (max-width: 1024px) {
                                        border-radius: 22px;
                                    }
                                }
                            }

                            li {
                                box-shadow: 0 3px 6px #00000017;

                                a {
                                    @media screen and (max-width: 1024px) {
                                        margin: 5px;
                                        border-radius: 22px;
                                    }
                                    @media screen and (max-width: 425px) {
                                        max-width: 380px;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    ::v-deep .nav-link {
                        width: 220px;
                        height: 46px;
                        background: #D1D1D1 0 0 no-repeat padding-box;
                        box-shadow: 0 3px 6px #00000017;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $black;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        padding: 0;
                        border-radius: 0;
                        text-transform: uppercase;
                        @media screen and (max-width: 1200px) {
                            width: 200px;
                            height: 42px;
                        }

                        &.active {
                            background: $mainGreen;
                            color: $white !important;
                        }
                    }
                    & .item_signature {
                        width: 200px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        ::v-deep canvas {
                            width: 200px !important;
                            height: 120px !important;
                            border: 1px solid $lightBlue !important;
                        }

                        & .signature_btn {
                            width: 60px;
                            height: 20px;
                            color: $white;
                            font-size: 12px;
                            font-weight: 600;
                            background: $mainGreen;
                            border-radius: 6px;
                            padding: 0 5px;
                            border: none;
                            margin: 10px 5px;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                                border: none;
                            }

                            &-content {
                                display: flex;
                                justify-content: space-between;
                                /*padding: 5px 10px;*/
                            }
                        }

                    }
                    & .consultation {
                        &_edit-btn {
                            position: absolute;
                            top: -70px;
                            right: 0;
                            cursor: pointer;
                        }

                        &_update {
                            &-btn {
                                display: flex;
                                width: 100%;
                                justify-content: flex-end;
                                padding: 30px 0 0;

                                & .update_btn {
                                    background: $mainOrange;
                                    color: $white;
                                    border-radius: 6px;
                                    height: 46px;
                                    width: 100px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: none;
                                }
                            }

                            &-content {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                & textarea {
                                    margin-top: 20px;
                                    padding: 20px;
                                    overflow: auto;
                                    border: 1px solid #9E9E9E;
                                    border-radius: 10px;

                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                                & .dual_inputs {
                                    width: 100%;
                                    display: flex;

                                    & .input_item {
                                        display: flex;
                                        align-items: center;
                                        max-width: 680px;
                                        width: 100%;
                                        padding: 15px 40px 15px 0;

                                        .b-form-timepicker {
                                            width: 420px;
                                            height: 44px;
                                            border: 1px solid #9E9E9E;
                                            border-radius: 10px;
                                            display: flex;
                                            align-items: center;
                                        }

                                        & label {
                                            width: 170px;
                                            margin-bottom: 0;
                                            font-weight: 600;
                                            color: $mainGreen;
                                        }

                                        & input {
                                            width: 420px;
                                            height: 44px;
                                            border: 1px solid #9E9E9E;
                                            border-radius: 10px;
                                        }
                                    }
                                }
                            }

                        }
                    }
                    & .consultation_notes {
                        width: 100%;

                        &-data {
                            position: relative;
                        }

                        &-head {
                            width: 100%;
                            display: flex;
                            padding-top: 10px;

                            & .head_item {
                                max-width: 360px;
                                width: 100%;
                                display: flex;
                                font-weight: 500;

                                &-key {
                                    color: $mainGreen;
                                    font-weight: 600;
                                    margin-right: 25px;
                                }
                            }
                        }

                        &-description {
                            padding: 40px 90px 40px 0;
                        }
                    }
                    & .invoice {
                        &_content {
                            width: 100%;
                            padding-top: 10px;
                        }

                        &_data {
                            &-content {
                                position: relative;

                                & .invoice_edit-btn {
                                    position: absolute;
                                    top: -70px;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }

                            &-head {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 20px 0 40px;

                                & .head_row {
                                    display: flex;
                                    padding: 5px 0;
                                }

                                &_title {
                                    color: $black;
                                    font-weight: 600;
                                }

                                & .head_item {
                                    width: 100%;
                                    display: flex;
                                    font-weight: 500;

                                    &-key {
                                        color: $mainGreen;
                                        font-weight: 600;
                                        width: 180px;
                                    }
                                }
                            }
                        }

                        &_summary {
                            &-title {
                                color: $mainOrange;
                                font-weight: 600;
                            }

                            &-container {
                                display: flex;
                            }

                            &-content {
                                display: flex;
                                flex-direction: column;
                            }

                            &-item {
                                width: 295px;
                                padding: 20px 0 10px;

                                &_title {
                                    padding-bottom: 10px;
                                    color: $mainGreen;
                                    font-weight: 600;
                                }

                                &_text {
                                    padding: 3px 0;
                                    color: $black;
                                    font-weight: 500;
                                }
                            }
                        }

                        &_signature {
                            &-content {
                                padding: 20px 0 10px;
                                display: flex;
                                flex-direction: column;
                            }

                            &-item {
                                display: flex;
                                align-items: center;
                                margin-top: 10px;
                                min-height: 50px;

                                & .signature {
                                    &_item {
                                        display: flex;
                                        align-items: center;
                                        max-width: 450px;
                                        width: 100%;
                                        justify-content: space-between;
                                        margin-right: 40px;

                                        &-date {
                                            width: 150px !important;
                                        }

                                        & .input_item {
                                            input {
                                                width: 280px;
                                                height: 44px;
                                                border: 1px solid #9E9E9E;
                                                border-radius: 10px;
                                            }
                                        }

                                        &-title {
                                            color: $mainOrange;
                                            font-weight: 600;
                                            max-width: 200px;
                                            width: 100%;
                                        }

                                        &-sign {
                                            width: 200px;
                                            height: 150px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            &.doctor {
                                                background-repeat: no-repeat;
                                                background-position: center;
                                            }
                                        }

                                        &-date {
                                            width: 100px;
                                            color: $mainOrange;
                                            font-weight: 600;
                                        }

                                        & .date_value {
                                            width: 230px;
                                            text-align: start;

                                            &.stamp {
                                                width: 170px;
                                                height: 140px;
                                                margin: 0 30px;
                                                background-repeat: no-repeat;
                                                background-position: center;
                                                background-size: cover;
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        &_edit {
                            &-title {
                                padding-top: 10px;
                                color: $mainOrange;
                                font-weight: 600;
                            }

                            &-content {
                                display: flex;
                                flex-direction: column;
                            }

                            &-head {
                                &_title {
                                    color: $black;
                                    font-weight: 600;
                                }
                            }
                        }

                        &_update {
                            padding: 20px 0;
                            display: flex;
                            flex-direction: column;

                            & .dual_inputs {
                                width: 100%;
                                display: flex;

                                & .input_item {
                                    display: flex;
                                    align-items: center;
                                    max-width: 680px;
                                    width: 100%;
                                    padding: 15px 40px 15px 0;

                                    & label {
                                        width: 180px;
                                        margin-bottom: 0;
                                        font-weight: 600;
                                        color: $mainGreen;
                                    }

                                    & input {
                                        width: 420px;
                                        height: 44px;
                                        border: 1px solid #9E9E9E;
                                        border-radius: 10px;
                                    }
                                }
                            }

                            &.summary {
                                & .dual_inputs {
                                    & .input_item {
                                        input {
                                            width: 340px !important;
                                        }

                                        & .add_btn {
                                            width: 62px;
                                            height: 44px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 10px;
                                            background: $mainGreen;
                                            color: $white;
                                            border: none;
                                            margin-left: 15px;
                                        }
                                    }

                                }

                            }
                        }
                    }
                    & .prescription {
                        &_content {
                            width: 100%;
                            padding-top: 10px;
                        }

                        &_data {
                            &-content {
                                position: relative;

                                & .prescription_edit-btn {
                                    position: absolute;
                                    top: -70px;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }

                            &-head {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 20px 0 40px;

                                & .head_row {
                                    display: flex;
                                    padding: 5px 0;
                                }

                                &_title {
                                    color: $black;
                                    font-weight: 600;
                                }

                                & .head_item {
                                    width: 100%;
                                    display: flex;
                                    font-weight: 500;

                                    &-key {
                                        color: $mainGreen;
                                        font-weight: 600;
                                        width: 180px;
                                    }
                                }
                            }
                        }

                        &_form {
                            &-title {
                                padding: 10px 0 20px;
                                color: $mainOrange;
                                font-weight: 600;
                            }

                            &-description {
                                color: #5A5A5A;
                            }
                        }

                        &_update {
                            &-title {
                                color: $mainOrange;
                                font-weight: 600;
                                padding-right: 30px;
                            }

                            &-textarea {
                                padding: 15px 0;

                                & textarea {
                                    padding: 30px 20px;
                                    overflow: auto;
                                    border: 1px solid #9E9E9E;
                                    border-radius: 10px;

                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    &:focus {
                                        outline: none;
                                        box-shadow: none;
                                    }
                                }
                            }

                            &-sign {
                                padding: 20px 0;
                                width: 100%;
                                display: flex;
                                align-items: center;
                            }

                            &-item {
                                margin-right: 30px;
                                display: flex;
                                align-items: center;

                                & .input_item {
                                    margin: 0 20px;

                                    & input {
                                        width: 400px;
                                        height: 44px;
                                        border: 1px solid #9E9E9E;
                                        border-radius: 10px;
                                    }

                                    ::v-deep .stamp_upload {
                                        & input {
                                            width: 210px;
                                            height: 44px;
                                            border-radius: 10px;
                                        }

                                        & label {
                                            margin-bottom: 0;
                                            height: 44px;

                                            &:after {
                                                width: 210px;
                                                height: 44px;
                                                content: 'Upload Stamp' !important;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                background: $mainGreen;
                                                color: $white;
                                                border: none;
                                            }
                                        }
                                    }
                                }

                                & .stamp_img {
                                    width: 120px;
                                    height: 70px;
                                    margin: 10px auto;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                        }

                        &_btn {
                            &-container {
                                display: flex;
                                justify-content: flex-end;
                                padding-top: 20px;

                                & .update_btn {
                                    width: 90px;
                                    height: 46px;
                                    border: none;
                                    color: $white;
                                    background: $mainOrange;
                                    border-radius: 6px;
                                }
                            }
                        }
                    }
                    & .referral {
                        &_content {
                            width: 100%;
                            padding-top: 10px;
                        }
                        &_data {
                            &-content {
                                position: relative;

                                & .referral_edit-btn {
                                    position: absolute;
                                    top: -70px;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }

                            &-head {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 20px 0 40px;

                                & .head_row {
                                    display: flex;
                                    padding: 5px 0;
                                }

                                &_title {
                                    color: $black;
                                    font-weight: 600;
                                }

                                & .head_item {
                                    width: 100%;
                                    display: flex;
                                    font-weight: 500;

                                    &-key {
                                        color: $mainGreen;
                                        font-weight: 600;
                                        width: 180px;
                                    }
                                }
                            }
                        }
                        &_cards {
                            display: flex;
                            padding: 10px 0;
                            width: 100%;
                            justify-content: space-between;
                            & .card_item {
                                max-width: 400px;
                                width: 100%;
                                &-title {
                                    color: $mainOrange;
                                    font-weight: 600;
                                    margin-bottom: 20px;
                                }
                                &-description {
                                    color: $grey;
                                }
                            }
                        }
                        &_update {
                            padding: 20px 0;
                            display: flex;
                            flex-direction: column;

                            & .dual_inputs {
                                width: 100%;
                                display: flex;

                                & .input_item {
                                    display: flex;
                                    align-items: center;
                                    max-width: 680px;
                                    width: 100%;
                                    padding: 15px 40px 15px 0;

                                    & label {
                                        width: 180px;
                                        margin-bottom: 0;
                                        font-weight: 600;
                                        color: $mainGreen;
                                    }

                                    & input {
                                        width: 420px;
                                        height: 44px;
                                        border: 1px solid #9E9E9E;
                                        border-radius: 10px;
                                    }
                                }
                            }

                        }
                        &_description-container {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            & .update-textarea {
                                max-width: 400px;
                                width: 100%;
                                & label {
                                    color: $mainOrange;
                                    font-weight: 600;
                                }
                                & textarea {
                                    margin-top: 20px;
                                    padding: 20px;
                                    overflow: auto;
                                    border: 1px solid #9E9E9E;
                                    border-radius: 10px;

                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                            }
                        }
                    }
                    & .fitness {
                        &_content {
                            width: 100%;
                            padding-top: 10px;
                            &-subtitle {
                                display: flex;
                                flex-direction: column;
                                & .subtitle {
                                    color: $mainOrange;
                                    margin-bottom: 10px;
                                    font-weight: 600;
                                    &_value {
                                        color: #032D23;
                                    }
                                }
                            }
                        }
                        &_data {
                            &-content {
                                position: relative;

                                & .referral_edit-btn {
                                    position: absolute;
                                    top: -70px;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }

                            &-head {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 20px 0 40px;

                                & .head_row {
                                    display: flex;
                                    padding: 5px 0;
                                }

                                &_title {
                                    color: $black;
                                    font-weight: 600;
                                }

                                & .head_item {
                                    width: 100%;
                                    display: flex;
                                    font-weight: 500;

                                    &-key {
                                        color: $mainGreen;
                                        font-weight: 600;
                                        width: 180px;
                                    }
                                }
                            }
                        }
                        &_date {
                            &-container {
                                display: flex;
                                flex-direction: column;
                                padding: 10px 0;

                            }
                            &-dual {
                                display: flex;
                                padding: 10px 0;
                                & .date {
                                    &_item {
                                        max-width: 400px;
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                    }
                                    &_subtitle {
                                        width: 165px;
                                        font-weight: 600;
                                        color: $mainGreen;
                                        &-value {
                                            width: 150px;

                                        }
                                    }
                                }
                            }
                        }
                        &_description {
                            padding: 5px 0;
                            width: 100%;
                        }
                        &_form {
                            &-sign {
                                & .invoice_signature-item {
                                    justify-content: space-between;
                                }
                            }
                        }
                        &_update {
                        padding: 20px 0;
                        display: flex;
                        flex-direction: column;
                        & .dual_inputs {
                            width: 100%;
                            display: flex;

                            & .input_item {
                                display: flex;
                                align-items: center;
                                max-width: 680px;
                                width: 100%;
                                padding: 15px 40px 15px 0;

                                & label {
                                    width: 180px;
                                    margin-bottom: 0;
                                    font-weight: 600;
                                    color: $mainGreen;
                                }

                                & input {
                                    width: 420px;
                                    height: 44px;
                                    border: 1px solid #9E9E9E;
                                    border-radius: 10px;
                                }
                            }
                            input[type=number] {
                                -moz-appearance: textfield !important;
                            }
                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                                -webkit-appearance: none !important;
                                margin: 0 !important;
                            }
                        }
                            &-options {
                                ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                                    border-color: $mainGreen;
                                    background-color: $mainGreen;
                                }
                            }
                            &-textarea {
                                padding: 15px 0;

                                & textarea {
                                    padding: 30px 20px;
                                    overflow: auto;
                                    border: 1px solid #9E9E9E;
                                    border-radius: 10px;

                                    &::-webkit-scrollbar {
                                        display: none;
                                    }

                                    &:focus {
                                        outline: none;
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &_title {
        color: $grey;
    }
    &_data-content {
        width: 100%;
        padding: 20px 0;
        display: flex;
        & .patient_card {
            &-container {
                width: 440px;
                background: $white;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 28px;
                & .send_btn {
                    width: 306px;
                    height: 54px;
                    border: none;
                    border-radius: 6px;
                    background: $mainOrange;
                    color: $white;
                    margin: 30px 0 10px;
                }
            }
            &-img {
                width: 117px;
                height: 117px;
                border-radius: 50%;
                background: url("~@/assets/images/user_empty_img.svg") no-repeat center;
                background-size: contain;
            }
            &-user {
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                & .user_name {
                    color: $black;
                    font-weight: 600;
                    font-size: 25px;
                }
                & .user_email {
                    margin-bottom: 10px;
                    color: $black;
                }
                & .user_id {
                    margin-bottom: 10px;
                    color: $black;
                    font-weight: 500;
                }
            }
            &-points {
                display: flex;
            }
            &-point_item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 153px;
                justify-content: center;
                color: $mainGreen;
                & .point {
                    font-weight: 600;
                    font-size: 45px;
                    line-height: 38px;
                    padding: 0 54px;
                    &.border_r {
                        border-right: 1px solid #707070;
                    }
                }
                & p {
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
        & .patient {
            &_content {
                width: 100%;
                margin-left: 20px;
                background: $white;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                padding: 28px;
                position: relative;
            }
            &_data {
                display: flex;
                padding-bottom: 20px;
                border-bottom: 1px dashed $mainGreen;
                &-info {
                    max-width: 340px;
                    width: 100%;
                }
                &-content {
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0 10px;
                }
            }
            &_info-item {
                padding: 10px 0;
                color: $mainOrange;
                font-weight: 600;
                & span {
                    margin: 0 20px;
                    color: $black;
                    font-weight: 500;
                }
            }
            &_dual-item {
                display: flex;
                width: 100%;
                & .data_item {
                    display: flex;
                    max-width: 460px;
                    width: 100%;
                    padding: 3px 0;
                    &-key {
                        width: 230px;
                        color: $mainGreen;
                        font-weight: 600;
                    }
                    &-value {
                        width: 230px;
                        color: $black;
                    }
                }
            }
        }
    }
}
</style>